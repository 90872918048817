import React, {useState} from 'react';


//icon
import { TiTick } from "react-icons/ti";

import styled from 'styled-components';

function CategoriaCash({close, categorie, ricarica}) {

    //correct
  const [tick, setTick] = useState(false);

    //categoria
  const [categoriaDati, setCategoriaDati] = useState({
    categoria: 'seleziona',
    nome: ''
  })

  function handleChangeCategoria(e){
    const name = e.target.name;
    const value = e.target.value;

    setCategoriaDati(prevCategoriaDati => ({
      ...prevCategoriaDati,
      [name]: value,
    }));
  }

  function handleCategoriaClick(){

    setTick(!tick);

    setTimeout(() => {
      close();
    },1000);

    fetch('https://www.app.nauticsm.it/categoria-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(categoriaDati),
    })
    .then(response => response.json())
    .then(data => {
        ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }

  //chiudi
  function handlePopup(){
    close();
  }

  //tipo
  const [tipo, setTipo] = useState(null);

  function handleTypeChange(e){
    setTipo(e.target.value);
  }

  //elimina
  const [elimina, setElimina] = useState({
    categoria: 'seleziona'
  })

  function handleElimina(e){
    const {value} = e.target;

    setElimina(prevElimina => ({
        ...prevElimina,
        categoria: value
    }))
  }

  function handleEliminaClick(){
    setTick(!tick);

    setTimeout(() => {
      close();
    },1000);

    fetch('https://www.app.nauticsm.it/elimina-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(elimina),
    })
    .then(response => response.json())
    .then(data => {
        ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }


  //modifica
  const [modifica, setModifica] = useState({
    categoria: 'seleziona',
    nuovoNome: ''
  })

  function handleModifica(e){
    const {name, value} = e.target;
    

    setModifica(prevModifica => ({
        ...prevModifica,
        [name]: value
    }))
  }

  function handleModificaClick(){
    setTick(!tick);

    setTimeout(() => {
      close();
    },1000);

    fetch('https://www.app.nauticsm.it/modifica-cashflow', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modifica),
    })
    .then(response => response.json())
    .then(data => {
        ricarica();
    })
    .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }

  return (
    <>
        <Choice>
            <select name="tipo" onChange={handleTypeChange}>
                <option disabled selected value="seleziona">Seleziona</option>
                <option value="Aggiungi">Aggiungi</option>
                <option value="Elimina">Elimina</option>
                <option value="Modifica">Modifica</option>
            </select>
        </Choice>
        {tick ? (<TickContainer>
            <InnerContainer><Tick /></InnerContainer>            
        </TickContainer>) : (
            <>
                {tipo === null && (<Initial>Seleziona una voce dal menu in alto a sx</Initial>)}
                {tipo === 'Aggiungi' && (
                    <React.Fragment>
                    <div className='popup-title'>
                      <h2>Aggiungi Categoria</h2>
                    
                    </div>
                    <div className='popup-form'>
                      <div>
                        <label for="categories">Seleziona una macrocategoria:</label>
          
                        <select name="categoria" id="cat" onChange={handleChangeCategoria} value={categoriaDati.categoria}>
                          <option disabled selected value="seleziona">Seleziona una categoria</option>
                          <optgroup label="Depositi Bancari">
                            <option value="Saldo Banca">Conti Bancari</option>
                            <option value="Altre Disponibilità">Altre Disponibilità</option>
                          </optgroup>

                          <optgroup label="Entrate di Gestione">
                            <option value="Erogazione Mutui/Finanziamenti">Erogazione Mutui / Finanziamenti</option>
                            <option value="Incassi da Finanziamenti Propri">Incassi da Finanziamenti Propri</option>
                            <option value="Incassi dalla Gestione">Incassi dalla Gestione</option>
                            <option value="Altre Entrate">Altre Entrate</option>
                          </optgroup>

                          <optgroup label="Uscite di Gestione">
                            <option value="Salari/Stipendi/Emolumenti">Salari / Stipendi / Emolumenti</option>
                            <option value="Fornitori e Lavorazioni di Terzi">Fornitori e Lavorazioni di Terzi</option>
                            <option value="F24">F24</option>
                            <option value="Altre Spese">Altre Spese</option>
                            <option value="Rate Mutui/Finanziamenti/Rientri">Rate Mutui / Finanziamenti / Rientri</option>
                          </optgroup>
                                          
                        </select>
                      </div>
          
                      <br />
          
                      <label for="name">Inserisci un nome:</label>
                      <input name="nome" onChange={handleChangeCategoria} value={categoriaDati.nome} type="text" placeholder="Stipendio"/>
        
                    </div>
          
                    
          
                    <div className='send-button'>
                      <button id="aggiungi" onClick={handleCategoriaClick}>Aggiungi</button>
                    </div>
                  </React.Fragment>
                  
                )}
                {tipo === 'Elimina' && (
                    <>
                        <div className='popup-title'>
                            <h2>Aggiungi Categoria</h2>
                        
                        </div>
                        <div className='popup-form'>
                            <label for="categories">Seleziona una categoria da eliminare:</label>
                            <select name="categories" value={elimina.categoria} onChange={handleElimina}>
                                <option disabled selected value="seleziona">Seleziona una categoria</option>
                                {categorie
                                  .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                  .map((item) => (
                                      <option value={item} key={item}>{item}</option>
                                  ))
                                }
                            </select>
                        </div>
                        <div className='send-button'>
                            <button id="elimina" onClick={handleEliminaClick}>Elimina</button>
                        </div>
                    </>
                )}
                {tipo === 'Modifica' && (
                    <>
                        <div className='popup-title'>
                            <h2>Aggiungi Categoria</h2>
                        
                        </div>
                        <div className='popup-form'>
                            <label for="categories">Seleziona una categoria da eliminare:</label>
                            <select name="categoria" value={modifica.categoria} onChange={handleModifica}>
                                <option disabled selected value="seleziona">Seleziona una categoria</option>
                                {categorie
                                  .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                  .map((item) => (
                                      <option value={item} key={item}>{item}</option>
                                  ))
                                }
                            </select>
                            <br/><br/>
                            <label>Inserisci nuovo nome:</label>
                            <input type="text" name="nuovoNome" onChange={handleModifica} placeholder='Stipendi' />
                        </div>
                        <div className='send-button'>
                            <button id="modifica" onClick={handleModificaClick}>Modifica</button>
                        </div>
                    </>
                )}
            </>
        )}
    </>
    
  )
}

export default CategoriaCash;

const Choice = styled.div`
  padding-left: 2%;
  padding-top: 20px;
  margin-bottom: 10px;
  select{
    border: 1px solid black;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
  }
`

const Initial = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
  text-align: center;
`

const Tick = styled(TiTick)`
    font-size: 100px;
    color: white;
`

const TickContainer = styled.div`
    
    width: 100%;
    min-height: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled.div`
    
    background-color: green;
    border-radius: 50%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    
`