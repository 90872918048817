import React from 'react'

import styled from "styled-components";

//icon
import { TiTick } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";

function Correct({close}) {

    function handleClose(){
        close();
    }
  return (
    <Main>
        

        <div className='tick'>
            <Tick />
            
        </div>
    </Main>
  )
}

export default Correct;

const Tick = styled(TiTick)`
    color: white;
    font-size: 100px;
`

const Main = styled.div`
    width: fit-content;
    height: fit-content;
    min-height: 40vh;
    min-width: 30%;
    padding: 0px 50px;
    
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    background-color: white;
    

    .tick{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: green;
        border-radius: 50%;
        width: fit-content;
        height: fit-content;
        padding: 30px;
        
    }

    
`

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 0; right: 0`