import React,{useState, useEffect} from 'react';
import axios from "axios";

//style
import "./innerStyles/Table.css";

import styled from "styled-components"


const anno = 2020; //anno partenza

function Table({ricarica, reset, oggetto, anniSelezionati, completo}) {

  const totaliFinali = [23, 24, 25, 26, 27, 6, 29, 30, 31, 32]

  const generaliId = [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000];


  
  return (
    <div>      
      {oggetto && anniSelezionati ? (
        
      
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div className='scroll'>
        <table className="scroll-table">
          <thead >
            <tr className='intestazione'>
              <th className='block first-column'>Categorie</th>
              {anniSelezionati.map((anno) => (
                <React.Fragment key={anno}>
                  <th className='columnNew'>{anno}</th>
                  <th className='columnNew'>% </th>
                  <th className='columnTipo'>Tipo </th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          {(oggetto.ricavi && oggetto.totali) ? (
            <>
              {completo === 1 ? (
            <tbody>
            {oggetto.ricavi && (
              <React.Fragment>
                
                  
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Ricavi</td>
                  </tr>
                  
                {oggetto.ricavi.map((item, index) => (
                  <tr key={index}>
                    <td className={item.id === 1002 ? 'block first-column last' : 'block first-column'}>{item.sottocategoria}</td>
                    {anniSelezionati.map((anno, annoIndex) => (
                      <React.Fragment key={annoIndex}>
                        <td className={item.id === 1002 ? 'last column' : 'column'}>
                          {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'EUR'
                          })}
                        </td>
                        <td className={item.id === 1002 ? 'last column' : 'column'}>
                          {item[`percentuale_${anno}`].toFixed(2)} %
                        </td>
                        <td className={item.id === 1002 ? 'last columnTipoInner' : 'columnTipoInner'}>
                          {item[`tipo_${anno}`]}
                        </td>
                      </React.Fragment>
                    ))}

                  </tr>
                ))}
              </React.Fragment>
            )}

            {oggetto.totali && (
              <React.Fragment>
                {oggetto.totali.map((item) => (
                  <React.Fragment>
                    {item.id === 2 && (
                      <tr key={item.id}>
                        <td className='block first-column totali'>{item.sottocategoria}</td>
                        {anniSelezionati.map((anno, annoIndex) => (
                          <React.Fragment key={annoIndex}>
                            
                            <td className='column totali'>{Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                              style: 'currency',
                              currency: 'EUR'
                            })}</td>
                            {item[`percentuale_${anno}`] ? (<><td className="column totali">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                            <td className="columnTipoInner totali">/</td></>): (<><td className="column totali">/</td>
                            <td className="columnTipoInner totali">/</td></>)}
                              
                          
                          </React.Fragment>
                        ))}
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}



                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Rimanenze Iniziali</td>
                  </tr>

            

            {oggetto.acquisti && (
              <React.Fragment>
                {oggetto.acquisti.map((item, index) => (
                  <>
                    
                    <tr key={index}>
                      <td className={(item.id === 1000 || item.id === 2000 || item.id === 3000) ? 'block first-column last' : 'block first-column'}>{item.sottocategoria}</td>
                      {anniSelezionati.map((anno, annoIndex) => (
                        <React.Fragment key={annoIndex}>
                          <td className={(item.id === 1000 || item.id === 2000 || item.id === 3000) ? 'last column' : 'column'}>
                            {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                              style: 'currency',
                              currency: 'EUR'
                            })}
                          </td>
                          <td className={(item.id === 1000 || item.id === 2000 || item.id === 3000) ? 'last column' : 'column'}>
                            {item[`percentuale_${anno}`].toFixed(2)} %
                          </td>
                          <td className={(item.id === 1000 || item.id === 2000 || item.id === 3000) ? 'last columnTipoInner' : 'columnTipoInner'}>
                            {item[`tipo_${anno}`]}
                          </td>
                        </React.Fragment>
                      ))}

                    </tr>
                    {item.id === 1000 && (
                     <>
                     <tr>
                         <td className='spazio-ridotto'></td>
                       </tr>
                       <tr>
                       <td style={{fontStyle: 'italic'}}className='block first-column'>Acquisti</td>
                     </tr>
                     </>
                    )}
                    {item.id === 2000 && (
                      <>
                      <tr>
                          <td className='spazio-ridotto'></td>
                        </tr>
                        <tr>
                        <td style={{fontStyle: 'italic'}}className='block first-column'>Rimanenze Finali</td>
                      </tr>
                      </>
                    )}
                  </>
                ))}
              </React.Fragment>
            )}


            {oggetto.totali && (
              <React.Fragment>
                {oggetto.totali.map((item) => (
                  <React.Fragment>
                    {item.id === 6 && (
                      <tr key={item.id}>
                        <td className='block first-column totali'>{item.sottocategoria}</td>
                        {anniSelezionati.map((anno, annoIndex) => (
                          <React.Fragment key={annoIndex}>
                            
                            <td className='column totali'>{Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                              style: 'currency',
                              currency: 'EUR'
                            })}</td>
                            {item[`percentuale_${anno}`] ? (<><td className="column totali">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                            <td className="columnTipoInner totali">/</td></>): (<><td className="column totali">/</td>
                            <td className="columnTipoInner totali">/</td></>)}
                              
                          
                          </React.Fragment>
                        ))}
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}



            <tr>
                <td className='spazio-ridotto'></td>
              </tr>
              <tr>
              <td style={{fontStyle: 'italic'}}className='block first-column'>Costi Produttivi</td>
            </tr>
                  
                    


            {oggetto.generali.map((item, index) => (
              <>
                <tr key={index}>
                  <td className={(generaliId.includes(item.id)) ? 'block first-column last' : 'block first-column'}>{item.sottocategoria}</td>
                  {anniSelezionati.map((anno, annoIndex) => (
                    <React.Fragment key={annoIndex}>
                      <td className={(generaliId.includes(item.id)) ? 'last column' : 'column'}>
                        {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                          style: 'currency',
                          currency: 'EUR'
                        })}
                      </td>
                      <td className={(generaliId.includes(item.id)) ? 'last column' : 'column'}>
                        {item[`percentuale_${anno}`].toFixed(2)} %
                      </td>
                      <td className={(generaliId.includes(item.id)) ? 'last columnTipoInner' : 'columnTipoInner'}>
                        {item[`tipo_${anno}`]}
                      </td>
                    </React.Fragment>
                  ))}

                </tr>
                
                {item.id === 1000 && (
                  <>
                    {oggetto.totali && (
                      <>
                        {oggetto.totali.map((item) => (
                          <>
                            {(item.id === 8 || item.id === 9) && (
                              <tr key={item.id}>
                                <td className='block first-column totali'>{item.sottocategoria}</td>
                                {anniSelezionati.map((anno, annoIndex) => (
                                  <React.Fragment key={annoIndex}>
                                    
                                    <td className='column totali'>{Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    })}</td>
                                    {item[`percentuale_${anno}`] ? (<><td className="column totali">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                            <td className="columnTipoInner totali">/</td></>): (<><td className="column totali">/</td>
                            <td className="columnTipoInner totali">/</td></>)}
                                      
                                  
                                  </React.Fragment>
                                ))}
                              </tr>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
                {item.id === 6000 && (
                  <>
                    {oggetto.totali && (
                      <>
                        {oggetto.totali.map((item) => (
                          <>
                            {(item.id === 15 || item.id === 16 || item.id === 17) && (
                              <tr key={item.id}>
                                <td className='block first-column totali'>{item.sottocategoria}</td>
                                {anniSelezionati.map((anno, annoIndex) => (
                                  <React.Fragment key={annoIndex}>
                                    
                                    <td className='column totali'>{Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    })}</td>
                                    {item[`percentuale_${anno}`] ? (<><td className="column totali">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                            <td className="columnTipoInner totali">/</td></>): (<><td className="column totali">/</td>
                            <td className="columnTipoInner totali">/</td></>)}
                                      
                                  
                                  </React.Fragment>
                                ))}
                              </tr>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
                {item.id === 8000 && (
                  <>
                    {oggetto.totali && (
                      <>
                        {oggetto.totali.map((item) => (
                          <>
                            {(item.id === 20) && (
                              <tr key={item.id}>
                                <td className='block first-column totali'>{item.sottocategoria}</td>
                                {anniSelezionati.map((anno, annoIndex) => (
                                  <React.Fragment key={annoIndex}>
                                    
                                    <td className='column totali'>{Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    })}</td>
                                    {item[`percentuale_${anno}`] ? (<><td className="column totali">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                                    <td className="columnTipoInner totali">/</td></>): (<><td className="column totali">/</td>
                                    <td className="columnTipoInner totali">/</td></>)}
                                      
                                  
                                  </React.Fragment>
                                ))}
                              </tr>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </>
                )}
                {item.id === 1000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Costo del Personale</td>
                  </tr>
                  </>
                )}

                {item.id === 2000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Costi Commerciali</td>
                  </tr>
                  </>
                )}
                {item.id === 3000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Costi Amministrativi</td>
                  </tr>
                  </>
                )}
                {item.id === 4000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Ammortamenti</td>
                  </tr>
                  </>
                )}
                {item.id === 5000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Oneri Tributari</td>
                  </tr>
                  </>
                )}
                {item.id === 6000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Oneri Finanziari</td>
                  </tr>
                  </>
                )}
                {item.id === 7000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Proventi Finanziari</td>
                  </tr>
                  </>
                )}

                {item.id === 8000 && (
                  <>
                  <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                    <td style={{fontStyle: 'italic'}}className='block first-column'>Ricavi Extra Gestionali</td>
                  </tr>
                  </>
                )}
                {item.id === 9000 && (
                  <>
                    <tr>
                      <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                      <td style={{fontStyle: 'italic'}}className='block first-column'>Costi Extra Gestionali</td>
                    </tr>
                  </>
                )}





              </>
            ))}

            <tr>
              <td className='spazio-ridotto'></td>
            </tr>

            {oggetto.totali && (
              <>
                {totaliFinali.map((id) => {
                  const item = oggetto.totali.find((element) => element.id === id);
                  if (item) {
                    return (
                      <tr key={item.id}>
                        <td className='block first-column totali'>{item.sottocategoria}</td>
                        {anniSelezionati.map((anno, annoIndex) => (
                          <React.Fragment key={annoIndex}>
                            {(item.id !== 29 && item.id !== 31) ? (
                              <td className="column totali">
                                {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}
                              </td>
                            ) : (
                              <td className="column totali">{item[`valore_${anno}`] != null ? item[`valore_${anno}`].toFixed(2) : 'N/A'}</td>
                            )}
                            {item[`percentuale_${anno}`] ? (
                              <>
                                <td className="column totali">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                                <td className="columnTipoInner totali">/</td>
                              </>
                            ) : (
                              <>
                                <td className="column totali">/</td>
                                <td className="columnTipoInner totali">/</td>
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </>
            )}

            


            {oggetto.dataBep && (
              <tr>
              <td className='block first-column totali'>Data BEP Aziendale</td>
              
                
                {oggetto.dataBep.map((item, index) => (
                  <React.Fragment key={index}>
                    {Object.entries(item).map(([anno, dataFormattata]) => (
                      <React.Fragment key={anno}>
                        
                        {anniSelezionati.includes(parseInt(anno, 10)) && (
                          <> 
                          
                            <td className='column totali' key={`td-${anno}`}>{dataFormattata}</td>
                            <td className='column totali' key={`separator-${anno}`}>/</td>
                            <td className='columnTipoInner totali' key={`another-separator-${anno}`}>/</td>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
               
            </tr>
            )}



          </tbody>
          ) : (
            <tbody>
              {oggetto.totali && (
                <>
                  {oggetto.totali.map((item) => (
                    <tr >
                      <td className='block first-column'>{item.sottocategoria}</td>
                      {anniSelezionati.map((anno, annoIndex) => (
                        <React.Fragment key={annoIndex}>
                          {(item.id !== 29 && item.id !== 31) ? (
                            <td className="column">
                              {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}
                            </td>
                          ) : (
                            <React.Fragment>
                              {item.id === 29 ? (
                                <td className="column ">{item[`valore_${anno}`] != null ? item[`valore_${anno}`].toFixed(2) : 'N/A'}%</td>

                              ) : (
                                <td className="column ">{item[`valore_${anno}`] != null ? item[`valore_${anno}`].toFixed(2) : 'N/A'}</td>
                              )}
                            </React.Fragment>
                          )}
                          {item[`percentuale_${anno}`] ? (<><td className="column ">{item[`percentuale_${anno}`].toFixed(2)}%</td>
                              <td className="columnTipoInner ">/</td></>): (<><td className="column ">/</td>
                              <td className="columnTipoInner ">/</td></>)}
                        </React.Fragment>
                      ))}
                      

                      </tr>
                    ))}
                </>
              )}

              {oggetto.dataBep && (
                <tr>
                <td className='block first-column'>Data BEP Aziendale</td>
                {oggetto.dataBep.map((item, index) => (
                  <React.Fragment key={index}>
                    {Object.entries(item).map(([anno, dataFormattata]) => (
                      <React.Fragment key={anno}>
                        
                        {anniSelezionati.includes(parseInt(anno, 10)) && (
                          <> 
                          
                            <td className='column ' key={`td-${anno}`}>{dataFormattata}</td>
                            <td className='column ' key={`separator-${anno}`}>/</td>
                            <td className='columnTipoInner ' key={`another-separator-${anno}`}>/</td>
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </tr>
              )}
            </tbody>
          )}
            </>
          ) : (
            <>
              {oggetto.generali.map((item, index) => (
              <>
                <tr key={index}>
                  <td className='block first-column'>{item.categoria}</td>
                  {anniSelezionati.map((anno, annoIndex) => (
                    <React.Fragment key={annoIndex}>
                      {item[`valore_${anno}`] ? (
                        <>
                          <td className='column'>
                            {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                              style: 'currency',
                              currency: 'EUR'
                            })}
                          </td>
                          <td className='column'>
                            {item[`percentuale_${anno}`].toFixed(2)}
                          </td>
                          <td className='columnTipoInner'>
                            {item.tipo}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className='column'>
                            
                          </td>
                          <td className='column'>
                            
                          </td>
                          <td className='columnTipoInner'>
                            {item.tipo}
                          </td>
                        </>
                      )}
                    </React.Fragment>
                  ))}

                </tr>                
              </>
            ))}
            </>
          )}
        </table>
      </div>
      </div>
  
     
        
      ) : (
        <p>Caricamento...</p>
      )}
    </div>
  )
}

export default Table;





const Choice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  
  .switch-view{
    width: 80px;
    background-color: rgba(0,0,0,.2);
    border-radius: 20px;
    height: 25px;
    margin: 0px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${({completo}) => ((completo === 1) ? 'flex-start' : 'flex-end')};
}

.switch-btn{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #213940;
    cursor: pointer;
    margin: 0px 3px;
}

`