import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

function Grafico({ dati }) {
  const data = [];
  const formatEuro = (importo) => `${importo.toFixed(2)} €`;

  dati.forEach((dato, index) => {
    Object.keys(dato).forEach(anno => {
      data.push({
        name: `Page ${String.fromCharCode(65 + index)}`, 
        anno: +anno.split('_')[1], 
        importo: dato[anno]
      });
    });
  });

  data.sort((a, b) => a.anno - b.anno);

  // Calcola la media degli importi
  const mediaImporti = data.reduce((acc, curr) => acc + curr.importo, 0) / data.length;

  console.log(data);
  

  return (
    <ResponsiveContainer width={600} height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="anno" />
        <YAxis />
        <Tooltip formatter={formatEuro} />
        <Legend />
        <Bar dataKey="importo" fill="#213940" /> 
        
        <ReferenceLine y={mediaImporti} stroke="#1eafd6" />
        
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Grafico;
