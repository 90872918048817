import React, {useState} from 'react'

import styled from "styled-components"

//icon
import { IoMdClose } from "react-icons/io";

function IndicatoriPopup({close, dati, indice}) {
    //close popup
    function handleClose(){
        close();
    }

    //choice
    const [choice, setChoice] = useState(0);
    function handleChoice(value){
        setChoice(value);
    }
  return (
    <Popup>
        <InnerPopup>
            <CloseIcon onClick={handleClose}/>
            {indice === 0 && (
                <div className='desc-choice'>
                    <button className={choice === 0 ? 'active' : ''} onClick={() => handleChoice(0)}>MOL</button>
                    <button className={choice === 1 ? 'active' : ''} onClick={() => handleChoice(1)}>EBITDA</button>
                    <button className={choice === 2 ? 'active' : ''} onClick={() => handleChoice(2)}>EBIT</button>
                </div>
            )}
            {indice !== 0 ? (
                <>
                    <div className='popup-title'>
                        <h2>{dati.titolo}</h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: dati.descrizione }} />
                    <br/>
                    <h4 dangerouslySetInnerHTML={{ __html: dati.formula }} />
                    <br/>
                    <p dangerouslySetInnerHTML={{ __html: dati.descrizione_2 }} />
                </>
            ) : (
                <>
                    <div className='popup-title'>
                        <h2>{dati[choice].titolo}</h2>
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: dati[choice].descrizione }} />
                    <br/>
                    <h4 dangerouslySetInnerHTML={{ __html: dati[choice].formula }} />
                    <br/>
                    <p dangerouslySetInnerHTML={{ __html: dati[choice].descrizione_2 }} />
                </>
            )}

        </InnerPopup>
    </Popup>
  )
}

export default IndicatoriPopup;

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`

const Popup = styled.div`
  position: fixed;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`

const InnerPopup = styled.div`
  position: fixed;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  min-height: 30vh;
  padding: 0 50px 0px 25px;
  min-width: 30%;
  padding-bottom: 2vh;
  overflow-y: auto;
  max-height: 80vh;
  border-radius: 10px;
  

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
    margin-bottom: 30px;
  }

  p{
    font-size: 18px;
  }
   h4{
    font-size: 20px;
    border-bottom: 2px solid #213940;
    padding-bottom: 5px;
    width: fit-content;
    
   }

   

   .desc-choice{
        margin-top: 2vh;
        
   }

   .desc-choice button{
        background-color: white;
        outline: none;
        border: none;
        font-size: 18px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0,0,0,.3);
        padding: 5px 8px;
        cursor: pointer;

        &:hover{
            background-color: #213940;
            color: white;
            transition: all .3s linear;
        }
   }

   .desc-choice button:nth-child(2){
    margin: 0px 10px;
   }  

   .desc-choice .active{
        background-color: #213940;
        color: white;
   }


  
`