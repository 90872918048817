import React, {useEffect, useState} from 'react'
import axios from "axios";
import styled from "styled-components";

//icon
import { IoMdClose } from "react-icons/io";

function BudgetPopup({close, categorie, ricarica, tipo}) {
     //chiudi popup
    function handlePopup(){
        close();
    }

    const [dati, setDati] = useState({
        categoria: 'seleziona',
        variazione: 0
    });

    function handleNewData(e){
        const {name, value} = e.target;

        setDati(prevDati => ({
            ...prevDati,
            [name]: value
        }));
    }


    async function sendData(){
       
        try{
            const response = await axios.post("https://www.app.nauticsm.it/variazioni", dati);
            close();
            ricarica();
        }catch(error){  
            console.log(error);
        }
    }


    //azzerravalori
    async function azzerraValori(){
      console.log("elimina");
      try{
        const response = await axios.get("https://www.app.nauticsm.it/azzerra-manuali");
        close();
        ricarica();
      }catch(error){  
          console.log(error);
      }


    }

  return (
    <Popup>
        <InnerPopup tipo={tipo}>
            <CloseIcon onClick={handlePopup}/>
            
              {tipo === 'variazioni' ? (
                <>
                  <div className='popup-title'>
                    <h2>Inserire variazione manuale</h2>

                  </div>
                  <br/>
                  <div className='popup-form'>
                      <label for="categoria">Seleziona categoria:</label>
                      <select name="categoria" onChange={handleNewData}>
                          <option selected disabled value="Seleziona">- Seleziona -</option>
                          {categorie
                            .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                            .map((item) => (
                                <option value={item} key={item}>{item}</option>
                            ))
                          }
                      </select>
                      <br/><br/>
                      <label for="variazione">Variazione:</label>
                      <input type="number" name="variazione" placeholder='1.15' onChange={handleNewData}/>
                  </div>
                  
                
                <div className='send-button'>
                  <button id="aggiungi" onClick={sendData}>Aggiungi</button>
                </div>
                </>
              ) : (
                <>
                  <div className='popup-question'>
                    <p>Sei sicuro di voler azzerare i valori manuali?</p>
                    <button id='elimina' style={{marginTop: '0'}} onClick={azzerraValori}>Si</button>
                    <button id='neutro' style={{marginTop: '0'}} onClick={handlePopup}>No</button>
                  </div>
                </>
              )}
            
        </InnerPopup>
    </Popup>
  )
}

export default BudgetPopup;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`

const InnerPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  border-radius: 10px;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 80px 0px 25px;
  display: ${({tipo}) => (tipo !== 'variazioni' && 'flex')};
  align-items: ${({tipo}) => (tipo !== 'variazioni' && 'center')};
  justify-content: ${({tipo}) => (tipo !== 'variazioni' && 'center')};

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
    margin-bottom: 20px;
  }



  .popup-form input{
    border: 1px solid black;
    font-size: 20px;
    padding: 3px 8px;
    border: 1px solid black;
    outline: none;

    &:focus{
      box-shadow: 0px 0px 8px rgba(0,0,0,.7);
    }
  }

  .popup-form label{
    margin-right: 20px;
    font-size: 20px;
  }

  .popup-form select{
    font-size: 20px;
    border: 1px solid black;
    cursor: pointer;
  }
  
  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

  .popup-question{
    display: flex;
    align-items: center;
    font-size: 26px;
   
    button{
      font-size: 26px;
      width: 100px;
      padding: 6px 0px;
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 5px;
    }

    #elimina{
      margin-left: 20px;
    }

  }
`

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`