import React, {useEffect, useState} from 'react'
import axios from "axios";

import styled from "styled-components"

import IndicatoriPopup from './IndicatoriPopup';

//icon 
import { FaQuestionCircle } from "react-icons/fa";
import { FaPlus as FaPlus2} from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { MdFilterAltOff } from "react-icons/md";

function IndicatoriTable() {
    const [dati, setDati] = useState(null);
    const [anniSelezionati, setAnniSelezionati] = useState([]);
    const [ebitda, setEbitda] = useState([]);
    const [checkedDivisioni, setCheckedDivisioni] = useState(["Tutto"]);


    async function ricaricaDati(){
        const data = {
            divisioni: checkedDivisioni
        }

        const response = await axios.post('https://www.app.nauticsm.it/divisioni-indicatori', data)

        setDati(response.data);
        setAnniSelezionati(response.data.anni);
        setEbitda(response.data.ebitda);
    }


    //popup
    const [seePopup, setSeePopup] = useState(false);
    const [popupIndice, setPopupIndice] = useState(null);
    function handlePopup(index){
        setSeePopup(!seePopup);
        setPopupIndice(index);
    }

    const info = [
        [
            {
                titolo: 'MOL (Margine Operativo Lordo)',
                descrizione: "Il MOL è un indicatore di redditività che misura la capacità di un'azienda di generare profitti dalla sua attività operativa, prima di considerare gli interessi, le tasse e gli ammortamenti",
                formula: "MOL = VENDITE - COSTI TIPICI DI ESERCIZIO (escluse tasse, oneri finanziari e ammortamenti)",
                descrizione_2: "MOL elevato = l'azienda è in grado di generare profitti elevati dalla sua attività operativa. MOL basso = l'azienda sta spendendo troppo per la sua attività operativa o i suoi prezzi di vendita non sono sufficienti a coprire i costi."
            },
            {
                titolo: 'EBITDA (EARNING BEFORE INTEREST, TAXES, DEPRECIATION AND AMORTIZATION)',
                descrizione: "L'EBITDA è un indicatore di redditività utile a valutare la capacità di un'azienda di generare profitti, indipendentemente dal suo livello di indebitamento o dal suo patrimonio netto. <br/> <br/> In poche parole, dice se i ricavi delle vendite sono sufficienti per coprire i costi legati al core business",
                formula: "EBITDA = VENDITE - COSTI TIPICI DI ESERCIZIO <br/>(escluse tasse, oneri finanziari e ammortamenti) - ACCANTONAMENTI",
                descrizione_2: "EBITDA positivo = l'azienda è in grado di generare margine dalle sue attività operative.<br/> EBITDA negativo = l’azienda ha una perdita operativa.<br/><br/> <b>Questo indicatore è sempre più spesso utilizzato per una rapida valutazione della capacità di un’azienda di remunerare un debito.</b><br/><br/> <b>Per l’imprenditore, invece, è uno strumento utilissimo per capire se per la sua azienda è meglio usare il proprio denaro o quello della banca.</b>"
            },
            {
                titolo: 'EBIT (EARNING BEFORE INTEREST AND TAXES)',
                descrizione: "L'EBIT misura l'abilità dell'azienda di generare profitti dalle operazioni svolte nel corso dell'esercizio, ignorando sia l'aspetto fiscale sia la struttura del capitale",
                formula: "EBIT = VENDITE - COSTI TIPICI DI ESERCIZIO <br/>(esclusi oneri finanziari e tasse)",
                descrizione_2: "EBIT positivo = l'azienda è in grado di generare profitti dalle sue attività operative. <br/> <br/> EBIT negativo = l'azienda sta subendo perdite dalle sue attività operative."
            }
        ],
        {
            titolo: "ROE (RETURN ON EQUITY)",
            descrizione: "Il ROE (Return on Equity, ovvero Ritorno sul Capitale) è l'indicatore utilizzato per verificare la resa del capitale conferito dai soci (capitale di rischio). <br/><br/> In parole semplici, il ROE misura quanti soldi l'imprenditore guadagna per ogni euro che ha investito nella sua azienda",
            formula: "ROE = (REDDITO NETTO D'ESERCIZIO / MEZZI PROPRI) %",
            descrizione_2: ""
        },
        {
            titolo: "ROI (RETURN ON INVESTMENT)",
            descrizione: "Il ROI (Return on Investment, ovvero Ritorno sull'Investimento) è un indicatore di redditività che misura la resa del capitale conferito dai soci e il capitale a debito (investitori in generale). <br/> <br/>In particolare permette di valutare l'efficienza dell'investimento e anche di confrontare tra loro diversi investimenti.",
            formula: "ROI = (EBIT / CAPITALE INVESTITO ) %",
            descrizione_2: "Dove: <br/> <i>CAPITALE INVESTITO = IMMOBILIZZAZIONI NETTE + CAPITALE CIRCOLANTE NETTO </i><br/><br/> <i>CAPITALE  CIRCOLANTE NETTO = ATTIVO CIRCOLANTE - PASSIVO CORRENTE </i> <br/><br/> ROI alto (sopra il tasso medio d'interesse sui debiti) = l'investimento è redditizio. <br/> ROI basso = l'azienda non riesce a creare profitti dall'investimento."

        }, 
        {
            titolo: "ROS (RETURN ON SALES)",
            descrizione: "Il ROS (Return on Sales, ovvero ritorno sulle vendite) è un indicatore di redditività che misura il margine di reddito prodotto per unità di valori di fatturato. <br/> <br/> Può essere utilizzato per valutare le performance di un'azienda nel tempo e per confrontare le performance di aziende che operano nello stesso settore.", 
            formula: "ROS = (EBIT / RICAVI DI VENDITA) %",
            descrizione_2: ""
        }, 
        {
            titolo: "MARGINE DI TESORERIA",
            descrizione: "Il margine di tesoreria è un indicatore di liquidità che misura la capacità di un'azienda di estinguere i debiti entro i 12 mesi tramite le liquidità immediate e la conversione dei crediti entro i 12 mesi. <br/> <br/> In parole più semplici, indica se l'azienda ha abbastanza liquidità per sostenere tutte le spese a breve termine e medio termine necessarie a mantenere in funzione l'azienda stessa",
            formula: "MARGINE DI TESORERIA = LIQUIDITÀ IMMEDIATE + LIQUIDITÀ DIFFERITE - PASSIVITÀ CORRENTI",
            descrizione_2: "<b>Margine di tesoreria positivo</b> = l'azienda genera abbastanza flussi di cassa da sostenere le sue attività e sarebbe in grado di gestire un'eventuale crisi di liquidità. <br/> <b>Margine di tesoreria negativo </b> = i flussi di cassa generati dall'azienda non sono sufficienti a sostenere le spese dell'azienda."
        },
        {
            titolo: "CURRENT RATIO",
            descrizione: "Il current ratio (altrimenti conosciuto come indice di liquidità corrente) è un indicatore finanzierio che misura la capacità di un'azienda di far fronte ai suoi impegni finanziari a breve termine. <br/> <br/> In poche parole misura la capacitò dell'azienda d far fronte alle uscite correnti attraverso le proprie entrate correnti",
            formula: "CURRENT RATIO = ATTIVO CIRCOLANTE / PASSIVO CIRCOLANTE",
            descrizione_2: "<b> Current Ratio ≥ 1</b> = le attività correnti sono maggiori o uguali delle passività correnti. In questo caso l'azienda è in buona salute finanziaria e in grado di far fronte alle uscite future.<br/> <br/><b>Current Ratio = 1</b> = attività correnti e passività correnti si equivalgono. In questo caso la situazione finanziaria dell'azienda risulta precaria visto che le entrate correnti sono appena sufficienti a far fronte alle uscite <br/><br/> <b>Current ratio ≤ 1</b> = le attività correnti sono inferiori alle passività correnti. In questo caso la situazione è critica dal momento che le entrate correnti non sono sufficienti a coprire le uscite <br/> <br/> In generale, come indicatore creditizio favorevole le banche vorrebbero un valore minimo di current ratio dell'1,20 (i crediti a fine anno dovrebbero avere un valore del 20% superiore rispetto ai debiti)."
        }, 
        {
            titolo: "ROTAZIONE DEL MAGAZZINO",
            descrizione: "L'indice di rotazione di magazzino misura il numero di volte in cui un'azienda vende le proprie rimanenze di magazzino in un dato periodo di tempo",
            formula: "ROTAZIONE DEL MAGAZZINO = COSTO DEL VENDUTO / MAGAZZINO MEDIO",
            descrizione_2: "L'indice di rotazione ideale si deve avvicinare al tempo minimo necessario per rinnovare i prodotti venduti, limitando così la permanenza in giacenza. <br/> <br/> Per capire se l'indice di rotazione di magazzino è ottimale, basta confrontarlo con quello del proprio settore di appartenenza."
        }
    ]


    //anni selezionati
    function handleAnni(e) {
        const valoreCheckbox = parseInt(e.target.value, 10);
        const isChecked = e.target.checked;


        if(!isChecked){
            const index = anniSelezionati.indexOf(valoreCheckbox);
            setAnniSelezionati(anniSelezionati.filter((anno) => anno !== valoreCheckbox));
            setEbitda(ebitda.filter((_, i) => i !== index));
        } else {
            const nuoviAnni = [...anniSelezionati, valoreCheckbox];
            nuoviAnni.sort((a, b) => b - a); 
            setAnniSelezionati(nuoviAnni);

            const indice = nuoviAnni.indexOf(valoreCheckbox);
            const nuoviValori = [...ebitda];
            nuoviValori.splice(indice, 0, dati.ebitda[indice]);
            setEbitda(nuoviValori);

        }
        

    }
    
    //see legend divisioni
    const [seeDivisioni, setSeeDivisioni] = useState(false);
    function handleLegend(){
        setSeeDivisioni(!seeDivisioni);
    }

  function handleChangeDivisioni(event){
    const value = event.target.value;
    
    if(value !== 'Tutto' && checkedDivisioni.includes("Tutto")){
      const initialValue = "Tutto";
      const filteredDivisioni = checkedDivisioni.filter(item => item !== initialValue);
      setCheckedDivisioni([...filteredDivisioni, value]);

    } else if(value === "Tutto"){
      setCheckedDivisioni([value]);
    } else {
      if (checkedDivisioni.includes(value)) {
        setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      } else {
        setCheckedDivisioni([...checkedDivisioni, value]);
      }
    }
  };

  function handleRemoveDivisioni(item){
    const value = item;
    const initialValue = "Tutto";
    console.log(checkedDivisioni.length);
    if(checkedDivisioni.length === 1){
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      setCheckedDivisioni([initialValue]);
    }else {
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
    }

  };

  useEffect(() => {
    ricaricaDati();
  }, [checkedDivisioni])

  //see year
  const [seeYear, setSeeYear] = useState(false);
  function handleSeeYear(){
    setSeeYear(!seeYear);
  }

  //see filter
  const [seeFilter, setSeeFilter] = useState(false);

  function handleFilter(){
    setSeeDivisioni(false);
    setSeeFilter(!seeFilter);
  }

  return (
    <div>
        {dati && (
            <UpperMenu seeFilter={seeFilter}>
                <div className='icon-container tooltip' onClick={handleFilter}>
                    <span class="tooltiptext">Filtri</span>
                    {seeFilter ? (
                        <FilterOffIcon onClick={handleFilter}/>
                    ) : (
                        <FilterIcon onClick={handleFilter}/>
                    )}
                </div>
                <div className='filter-container'>
                    {dati.unit && (
                        <Divisioni seeDivisioni={seeDivisioni}>
                            <div>
                                <button className="divisioni-title" onClick={handleLegend}>Divisioni Aziendali <DownArrow /></button>
                                <div className='divisioni-aziendali'>
                                    <label><input style={{marginTop: "15px"}} onChange={handleChangeDivisioni} checked={checkedDivisioni.includes("Tutto")}  value="Tutto" type="checkbox"/> Tutto</label><br/>
                                    {dati.unit.map((item, index) => (
                                        <><label ><input checked={checkedDivisioni.includes(item)} onChange={handleChangeDivisioni} style={{ marginBottom: (index === dati.unit.length - 1) ? '15px' : '0' }} value={item} type="checkbox" /> {item}</label><br/></>
                                    ))}
                                    <div className='spuntate'>
                                        {checkedDivisioni.map((item) => (
                                            <p className='check'>{item} <Delete onClick={() => handleRemoveDivisioni(item)}/></p>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            
                        </Divisioni>
                    )}
                    <div className='select-anni'>
                        <div className='year-selection' onClick={handleSeeYear}>
                            <p>Seleziona anni <DownArrow /></p>
                        </div>

                        <DropDown seeYear={seeYear}>
                            {dati && (
                                <div className='dropdown-content'>
                                    {dati.anni.map((item) => (
                                        <><label><input type="checkbox" value={item} checked={anniSelezionati.includes(item)} onChange={handleAnni} style={{marginRight: '5px'}}/>{item}</label></>
                                    ))}
                                </div>
                            )}
                        </DropDown>
                        
                        
                    </div>
                </div>
            </UpperMenu>
        )}
        <Container>
            
            {dati ? (<div className='scroll'>
                <table className='scroll-table'>
                    <thead>
                        <tr className='intestazione'>
                            <th className='block first-column'>Categorie</th>
                        {anniSelezionati.map((item) => (
                            <th className='columnNew'>{item}</th>
                        ))}
                        </tr>
                    </thead>
                    <tbody>
                        {dati.indicatori.map((item, index) => (
                            <tr>
                                <td className='block first-column'><p>{item.categoria} <QuestionIcon onClick={() => handlePopup(index)}/></p></td>
                                {anniSelezionati.map((anno, index1) => (
                                    <>
                                        {(item.id !== 5 && item.id !== 6 && item.id !== 1 && item.id !== 7)  ? (
                                            <td className='column'>{Number(item[`percentuale_${anno}`]).toFixed(2)}%</td>
                                        ) : (
                                            <>
                                                {(item.id === 5 || item.id === 1) ? (
                                                    <td className='column'>{Number(item[`percentuale_${anno}`]).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })} {item.id === 1 && (
                                                        <>{ebitda && ( <React.Fragment> / {ebitda[index1].toFixed(2)}% </React.Fragment>)}

                                                        </>
                                                    )}</td>
                                                ) : (
                                                    <td className='column'>{Number(item[`percentuale_${anno}`]).toFixed(2)}</td>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>) : (<p>Caricamento...</p>)}
            {seePopup && (<IndicatoriPopup close={handlePopup} dati={info[popupIndice]} indice={popupIndice}/>)}
        </Container>
    </div>
  )
}

export default IndicatoriTable;

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;

  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;

  max-height: ${({ seeYear }) => (seeYear ? '500px' : '0px')};
  overflow: hidden;
  padding: 0px 10px;
  border: none;
  
  .dropdown-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px;

    label{
      padding: 5px 0px;
      font-size: 20px;
    }
  }

  transition: all .5s linear;
`;


const DownArrow = styled(IoIosArrowDown)`
  font-size: 18px;
  margin-left: 5px;
`

const Delete = styled(IoMdClose)`
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 2px;
`

const IconPlus = styled(FaPlus2)`
  font-size: 18px;
  margin-left: 5px;
`

const IconMinus = styled(FaMinus)`
  font-size: 18px;
  margin-left: 5px;
`

const Divisioni = styled.div`
  margin-left: 2%;


  .spuntate{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: ${({seeDivisioni}) => (seeDivisioni ? '10px' : '0')};
  }

  .spuntate .check{
    background-color: lightgrey;
    margin: 3px 0px;
    margin-right: 8px;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  
  .divisioni-title{
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0,0,0,.5);
    padding: 5px 20px;
    color: rgba(0,0,0,.5);
    background-color: white;
    transition: all .3s linear;

  }

  .divisioni-title:hover{
    color: black;
    border: 1px solid black;
  }

  .divisioni-aziendali{
    position: absolute;
    background-color: white;
    z-index: 9998;
    height: fit-content;
    max-height: ${({seeDivisioni}) => (seeDivisioni ? '400px' : '0')};
    box-shadow: 0px 0px 3px rgba(0,0,0,.4);
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    max-width: 350px;
    margin-top: 3px;
    font-size: 18px;
    padding: 0px 50px 0px 10px;
  }

  label, input{
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }


  
`

const Container = styled.div`
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;

    table th, table td{
        font-size: 18px;
        
    }

    table tbody tr td{
        padding-left: 20px;
        padding-right: 20px;
    }

    td p{
        display: flex;
        align-items: center;
    }
`

const QuestionIcon = styled(FaQuestionCircle)`
    cursor: pointer;
    color: rgba(37, 25, 214, .9);
    font-size: 18px;
    margin-left: 10px;

    &:hover{
        color: rgba(37, 25, 214, .6);
        transition: all .3s linear;
    }
`

const UpperMenu = styled.div`
    .filter-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-height: ${props => (props.seeFilter ? '400px' : '0px')};
        padding:  ${props => (props.seeFilter ? '20px 0px' : '0px')};
        padding-left: 2%;
        padding-right: 2%;
        height: fit-content;
        border: ${props => (props.seeFilter ? '1px solid rgba(0,0,0,.5)' : 'none')};
        overflow: ${props => (props.seeFilter ? 'visible' : 'hidden')};;
        box-shadow: 0px 0px 10px rgba(0,0,0,.2);
        border-radius: 0px 10px 10px 10px;

        transition: max-height 0.3s ease, padding 0.3s ease;
    }
    padding-left: 2%;
    padding-right: 2%;
    .icon-container{
        border: 1px solid rgba(0,0,0,.5);
        border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
        width: fit-content;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
        cursor: pointer;
        color: rgba(0,0,0,.5);
        transition: all .3s linear;
    }

    .icon-container:hover{
        border: 1px solid black;
        color: black;
    }
    margin-top: 30px;  


    .anni-indicatori{
        display: flex;
        align-items: center;
        margin-right: 2%;
    }

    .anni-indicatori p{
        margin-right: 10px;
        font-size: 18px;
    }

    .anni-indicatori label{
        margin: 0px 5px;
        cursor: pointer;
        font-size: 18px;
    }

    .anni-indicatori input{
        margin-right: 3px;
        cursor: pointer;
    }
`

const FilterIcon = styled(FaFilter)`
  font-size: 20px;
  cursor: pointer;  
`
const FilterOffIcon = styled(MdFilterAltOff)`
  font-size: 24px;
  cursor: pointer;  
`
