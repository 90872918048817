import React from 'react'

import "./RatingStyle.css";

function RatingTable({dati, anni, scoring, radio, anniCompleti}) {

    const colori = ['white', 'red', 'orange', 'lightgreen'];

    let filteredScoring;
    if(scoring){
        filteredScoring = scoring.filter((item, index) => anni.includes(anniCompleti[index]));
    }

    
  return (
    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        {(dati && anni) ? (
        <div className='scroll'>
            <table className='scroll-table'>
                {radio === 1 && (
                    <>
                        <thead>
                            <tr className='intestazione'>
                                <th className='block first-column'>Categoria</th>
                                {anni.map((item) => (
                                    <React.Fragment>
                                        <th className='columnNew'>{item}</th>
                                        <th className='columnNew'>% <br/><span className='small-int'>({item})</span></th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={1} className='block first-column italic'>Sintesi di Stato Patrimoniale</td>
                            </tr>
                            {dati.map((item) => (
                                <React.Fragment>
                                    {item.id < 13 && (
                                        <>
                                            <tr>
                                                <td className='block first-column' style={{fontWeight: (item.id === 4 || item.id === 8) ? '800' : ''}}>{item.categoria}</td>
                                                {anni.map((anno) => (
                                                    <>
                                                        <td className='column' style={{fontWeight: (item.id === 4 || item.id === 8) ? '800' : ''}}>{Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                        })}</td>
                                                        {item.id < 9 ? (<td className='column' style={{fontWeight: (item.id === 4 || item.id === 8) ? '800' : ''}}>{item[`percentuale_${anno}`] != null ? item[`percentuale_${anno}`].toFixed(2)+'%' : ''}</td>) : (<td className='column'></td>)}
                                                    </>
                                                ))}
                                            </tr>
                                            {item.id === 8 && (<><tr>
                                            <td className='spazio-ridotto'></td>
                                            </tr><tr>
                                                <td colSpan={1} className='block first-column italic'>Dati di Conto Economico</td>
                                            </tr></>)}
                                        </>
                                    )}
                                </React.Fragment>
                            ))}
                            
                        </tbody>
                    </>
                )}
                <thead>
                    <tr className='intestazione'>
                        <th className='block first-column'>Categoria</th>
                        {anni.map((item) => (
                            <React.Fragment>
                                <th className='columnNew'>{item}</th>
                                <th className='columnNew'>Scoring <br/><span className='small-int'>({item})</span></th>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dati.map((item) => (
                        <React.Fragment>
                            {item.id > 12 && (
                                <>
                                    <tr>
                                        <td className='block first-column'><b>{item.categoria}</b></td>
                                        {anni.map((anno) => (
                                            <>
                                                {item.id === 13 ? (<td className='column'><b>{item[`valore_${anno}`] != null ? item[`valore_${anno}`].toFixed(2) : 'N/A'}</b></td>) : (<td className='column'><b>{item[`valore_${anno}`] != null ? item[`valore_${anno}`].toFixed(2) : 'N/A'}%</b></td>)}
                                                
                                                <td className='column' style={{backgroundColor: colori[item[`percentuale_${anno}`]]}}>{item[`percentuale_${anno}`]}</td>
                                            </>
                                        ))}
                                    </tr>
                                    
                                </>
                            )}
                            
                        </React.Fragment>
                    ))}
                    <tr>
                        <td className='spazio-ridotto'></td>
                    </tr>
                    <tr>
                        <td className='block first-column'><b>Scoring Complessivo</b></td>
                        
                        {filteredScoring.map((item) => (
                            <>
                                {(item === 'AAA' || item === 'AA' || item === 'A') && (<>
                                    <td className='column' ></td>
                            
                                    <td className='column' style={{backgroundColor: 'lightgreen'}}>{item}</td>
                                </>)}

                                {(item === 'BBB' || item === 'BB') && (<>
                                    <td className='column' ></td>
                            
                                    <td className='column' style={{backgroundColor: 'orange'}}>{item}</td>
                                </>)}

                                {(item === 'B' || item === 'CCC') && (<>
                                    <td className='column' ></td>
                            
                                    <td className='column' style={{backgroundColor: 'red'}}>{item}</td>
                                </>)}

                                {(item === '/') && (<>
                                    <td className='column' ></td>
                            
                                    <td className='column'>{item}</td>
                                </>)}
                            </>
                        ))}
                        
                    </tr>
                </tbody>
                
            </table>
        </div>
        ) : (<p>Caricamento...</p>)}
    </div>
  )
}

export default RatingTable