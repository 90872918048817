import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ReferenceLine } from 'recharts';
import axios from "axios";


function CashFlowGraph({previsioneGrafico}) {

    const [data, setData] = useState(null);
    const [dataMax, setDataMax] = useState(null);
    const [dataMin, setDataMin] = useState(null);
    const [previsione, setPrevisione] = useState(null);

    async function richiestaDati(){

        const data = {
            completo: previsione
        }

        console.log(data.completo);
        const response = await axios.post("https://www.app.nauticsm.it/grafico-cashflow", data);

        setData([...response.data.data]);
        setDataMax(response.data.dataMax);
        setDataMin(response.data.dataMin);
    }


    // Dati di esempio
    // const data = [
    //     { name: 'Gen', entrate: 3000, uscite: -1500, cashFlow: 0 },
    //     { name: 'Feb', entrate: 4000, uscite: -1000, cashFlow: 1000 },
    //     { name: 'Mar', entrate: 3500, uscite: -2000, cashFlow: 800 },
    //     { name: 'Apr', entrate: 5000, uscite: -2500, cashFlow: 1300 },
    //     { name: 'Gen', entrate: 3000, uscite: -1500, cashFlow: 500 },
    //     { name: 'Feb', entrate: 4000, uscite: -1000, cashFlow: 1000 },
    //     { name: 'Mar', entrate: 3500, uscite: -2000, cashFlow: 800 },
    //     { name: 'Apr', entrate: 5000, uscite: -2500, cashFlow: 1300 },
    //     { name: 'Gen (2025)', entrate: 7500, uscite: -4200, cashFlow: 3300 },
    //     { name: 'Feb (2025)', entrate: 7800, uscite: -4400, cashFlow: 3400 },
        
    // ];

    const CustomLegend = (props) => {
        const { payload } = props;
    
        // Mappatura dei nomi desiderati
        const legendNames = {
            entrate: 'Scadenze Attive',  
            uscite: 'Scadenze Passive', 
            cashFlow: 'CashFlow Teorico', 
        };
    
        return (
            <ul style={{ listStyleType: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{ margin: '0 10px' }}>
                        <span style={{ color: entry.color }}>●</span> {/* Simbolo di colore */}
                        {legendNames[entry.dataKey] || entry.dataKey} {/* Nome personalizzato */}
                    </li>
                ))}
            </ul>
        );
    };


    useEffect(() => {
        console.log(previsioneGrafico);
        setPrevisione(previsioneGrafico);
    }, [previsioneGrafico]);

    useEffect(() => {
        richiestaDati();
    }, [previsione]);

    
  return (
    <>
        {data && (
            <Container>
                
                <ComposedChart width={900} height={500} data={data} isAnimationActive={true}>
                    
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis 
                        domain={dataMin !== null && dataMax !== null ? [dataMin - 1000, dataMax + 1000] : [0, 1000]}
                        ticks={dataMin !== null && dataMax !== null ? [dataMin - 1000, dataMin/2, 0, dataMax/2, dataMax + 1000] : []}
                    />
                    <Tooltip />
                    <Legend content={<CustomLegend />} />
                    <Bar dataKey="entrate" barSize={40} fill="#5ea73e" />
                    <Bar dataKey="uscite" barSize={40} fill="#cc1c09" />
                    
                    <Line type="monotone" dataKey="cashFlow" stroke="#616161" strokeWidth={3} />
                    
                    
                    {/* Aggiungi la linea di riferimento sullo zero */}
                    <ReferenceLine y={0} stroke="grey" strokeDasharray="3 3" />
                </ComposedChart>
                
            </Container>
        )}
    </>
  )
}

export default CashFlowGraph;

const Container = styled.div`
    height: fit-content;

    padding: 0 2%;
    margin-top: 50px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
`