import React, {useState} from 'react'

import styled from "styled-components";
import axios from "axios";

//icon
import { MdEdit } from "react-icons/md";
import { ImBin } from "react-icons/im";
import { MdDone } from "react-icons/md";

function RegistroStato({ricaricaDati, registro}) {

  const [modifica, setModifica] = useState(false);
  const [idModifica, setIdModifica] = useState(null);
  const [nuovoImporto, setNuovoImporto] = useState(null);

  //elimina importo
  function eliminaImporto(id){

    const data = {
      tipo: 'elimina',
      id: id
    }

    axios.post('https://www.app.nauticsm.it/gestisci-importo', data)
    .then(response => {
      ricaricaDati();
    })
    .catch(error => {
      console.error('Si è verificato un errore durante la richiesta POST:', error);
    });
  }

  //modifica importo
  function modificaImporto(id){
    setIdModifica(null);
    const data = {
      tipo: 'modifica',
      id: id,
      importo: nuovoImporto 
    }

    axios.post('https://www.app.nauticsm.it/gestisci-importo', data)
    .then(response => {
      ricaricaDati();
    })
    .catch(error => {
      console.error('Si è verificato un errore durante la richiesta POST:', error);
    });
  }

  //modifica
  function handleNuovoImporto(e){
    const {value} = e.target;

    setNuovoImporto(value);
  }

  function handleEditClick(id, importo) {
    setIdModifica(id);
    setNuovoImporto(importo);
  }

  return (
    <Container>
        {registro && (
          <div className='popup-title'>
            <h1>Registro Importi</h1>
            {registro.length === 0 && (
              <p>Nessun importo da visualizzare!</p>
            )}
              
          </div>
        )}
        {registro ? (
            <>
                {registro.length > 0 && (
                    <table>
                        <thead>
                            <tr>
                                <th>Categoria</th>
                                <th>Anno</th>
                                <th>Importo</th>
        
                            </tr>
                        </thead>
                        <tbody>
                            {registro.map((item) => (
                                <tr>
                                    <td>{item.categoria}</td>
                                    <td>{item.anno}</td>
                                    {idModifica !== item.id ? (
                                      <>
                                        <td>{Number(item.importo).toLocaleString('it-IT', {
                                        style: 'currency',
                                        currency: 'EUR'
                                        })}</td>
                                        <td><Pen onClick={() => handleEditClick(item.id, item.importo)} /><Bin onClick={() => eliminaImporto(item.id)}/></td>
                                      </>
                                    ) : (
                                      <>
                                        <td><input type="number" placeholder={item.importo} onChange={handleNuovoImporto} /></td>
                                        <td><Tick onClick={() => modificaImporto(item.id)}/></td>
                                      </>
                                    )}
                                            
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </>
        ) : (<p>Caricamento...</p>)}
    </Container>
  )
}

export default RegistroStato;

const Pen = styled(MdEdit)`cursor: pointer; font-size: 20px;`


const Bin = styled(ImBin)`cursor: pointer; font-size: 20px; color: rgba(255,0,0,.8); margin-left: 20px;`

const Tick = styled(MdDone)`cursor: pointer; font-size: 30px; color: rgba(52, 178, 51, .8);`

const Container = styled.div`
  color: white;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;
  max-height: 100%;
  margin-right: 5px;
  padding-bottom: 80px;

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2vh;
    align-items: flex-start;
  }

  .popup-title h1, .popup-title p{
    margin-left: 5%;
  }

  .popup-title p{
    margin-top: 30px;
  }

  table{
    margin-top: 30px;
    width: 90%;
    font-size: 18px;
  }

  table th{
    border-bottom: 1px solid white; 
    text-align: start;
    padding-bottom: 7px;
  }

  input{
    border: 1px solid white;
    outline: none;
    padding: 5px 10px;
    font-size: 18px;
  }
  
  `