import React, {useState, useEffect} from 'react';
import styled from "styled-components";
import axios from "axios";

//icon
import { IoMdClose } from "react-icons/io";

function ImportiPopup({mese, categoria, close, tipo}) {
    function handleClose(){
        close();
    }

    const [sendData, setSendData] = useState({
        mese: mese,
        categoria: categoria,
        tipo: tipo
    });
    const [responseData, setResponseData] = useState(null);
    const inviaDatiAlServer = async () => {
        try {
          const response = await axios.post('https://www.app.nauticsm.it/visualizza-importi', { dati: sendData });
          setResponseData(response.data.dati);
          console.log(response.data);
        } catch (error) {
          console.error('Errore durante l\'invio dei dati:', error);
        }
    };

    useEffect(() => {
    
    inviaDatiAlServer();
    }, []); 

  return (
    <Popup>
        <InnerPopup>
            <CloseIcon onClick={handleClose}/>
            <div className='importi-title'>
                <h2>Importi "{categoria}" mese di {mese}:</h2>
            </div>
            {responseData ? (
                <table className='importi-table'>
                <thead>
                    <tr>
                        <th>Nome categoria</th>
                        
                        <th>Macrocategoria</th>
                        <th>Mese</th>
                        <th>Importo</th>
                    </tr>
                </thead>
                <tbody>
                    {responseData.map((item) => (
                        <tr>
                            <td>{item.nome}</td>
                            
                            {tipo === 'succ' ? (
                              <td>{item.categoria}</td>
                            ) : (
                              <td>{item.macrocategoria}</td>
                            )}
                            <td>{item.mese}</td>
                            {tipo === 'succ' ? (
                              <td>{Number(item.importo).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}</td>
                            ) : (
                              <td>{Number(item.importi).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR'
                              })}</td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            ) : (<p>Caricamento...</p>)}

        </InnerPopup>
    </Popup>
  )
}

export default ImportiPopup;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`
const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`
const InnerPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
    margin-bottom: 20px;
  }

  padding: 0 70px 0px 15px;

  .popup-form input{
    border: 1px solid black;
    font-size: 20px;
    padding: 3px 8px;
  }

  .popup-form label{
    margin-right: 20px;
    font-size: 20px;
  }

  .popup-form select{
    font-size: 20px;
    border: 1px solid black;
    cursor: pointer;
  }
  
  table thead tr th{
    min-width: 200px;
  }

  table tbody td, table thead th{
    padding: 10px 0px;
  }
`