import React, {useState, useEffect} from 'react'

import styled from "styled-components";
//icon
import { IoMdClose } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { MdDone } from "react-icons/md";
import { ImBin } from "react-icons/im";

function Registro({ricaricaDati, businessUnit, registro}) {

  
    const [edit, setEdit] = useState(false);
    const [editCategoria, setEditCategoria] = useState(null);
    const [editAnno, setEditAnno] = useState(null);
    const [percentualiDivisioni, setPercentualiDivisioni] = useState({});
    const [checked, setChecked] = useState([]);
    const [addData, setAddData] = useState({
      category: 'seleziona',
      anno: 0,
      divisioni: checked,
      percentuali: percentualiDivisioni
    });

    function handleEdit(categoria, anno){
      setEdit(!edit)
      setEditCategoria(categoria)
      setEditAnno(anno);
      setAddData({
        ...addData, 
        category: categoria, // Modifichiamo solo la categoria
        anno: anno
      });
    }

    

  function handleChecked(e){
    const name = e.target.name;

    if (checked.includes(name)) {
      
      setChecked(checked.filter(item => item !== name));
    } else {
      
      setChecked([...checked, name]);
    }
  }

  
  function handlePercentuali(item, e){
    const value = e.target.value;
    setPercentualiDivisioni(prevState => {
      // Controlla se la chiave item esiste già nell'oggetto
      if (prevState[item]) {
        // Se la chiave esiste già, aggiungi il valore al suo valore attuale
        return {
          ...prevState,
          [item]: value
        };
      } else {
        // Se la chiave non esiste, aggiungi una nuova chiave con il valore
        return {
          ...prevState,
          [item]: value
        };
      }
    });
    
  }
  

  useEffect(() => {
    setAddData(prevAddData => ({
      ...prevAddData,
      divisioni: checked
    }));
  }, [checked]);

  useEffect(() => {
    setAddData(prevAddData => ({
      ...prevAddData,
      percentuali: percentualiDivisioni
    }));
  }, [percentualiDivisioni]);

  function handleSend(){

    fetch('https://www.app.nauticsm.it/modifica-importo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(addData),
    })
    .then(() => {
      
        
      setChecked([]);
      setPercentualiDivisioni({});
      ricaricaDati();
      
    })
    .catch(error => {
      // Gestione degli errori durante la richiesta
      console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }


  //eliminazione
  function handleDelete(categoria, anno){
    const dati = {
      categoria: categoria,
      anno: anno
    }

    fetch('https://www.app.nauticsm.it/elimina-importo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dati),
    })
    .then(() => {
      ricaricaDati();
      
    })
    .catch(error => {
      // Gestione degli errori durante la richiesta
      console.error('Errore durante l\'invio dei dati al server:', error);
    });
  }

  return (
    <Container>
        
            <div className='popup-title'>
                <h1>Registro Importi</h1>
            </div>
            {registro ? (
              <table>
                  <thead>
                      <tr>
                        <th>Categoria</th>
                        <th>Anno</th>
                        <th>Importo</th>
                        <th>Divisioni Aziendali</th>
                        <th>% Divisioni</th>
                      </tr>
                  </thead>
                  <tbody>
                    {!edit ? (
                      <>
                        {registro.map((item) => (
                          <tr>
                            <td>{item.categoria}</td>
                            <td>{item.anno}</td>
                            <td>{Number(item.importo).toLocaleString('it-IT', {
                              style: 'currency',
                              currency: 'EUR'
                            })}</td>
                            <td>{item.divisione}</td>
                            <td>{item.percentuale_divisione}</td>
                            <td><Pen onClick={() => handleEdit(item.categoria, item.anno)}/></td>
                            <td><Bin onClick={() => handleDelete(item.categoria, item.anno)}/></td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        {registro.map((item) => (
                          <React.Fragment>
                            {(item.categoria === editCategoria) && (item.anno === editAnno) && (
                              <tr>
                                <td>{item.categoria}</td>
                                <td>{item.anno}</td>
                                <td>{Number(item.importo).toLocaleString('it-IT', {
                                  style: 'currency',
                                  currency: 'EUR'
                                })}</td>
                                <td>{item.divisione}</td>
                                <td>{item.percentuale_divisione}</td>
                                <td><Done onClick={() => { handleEdit(); handleSend(); }} /></td>     
                              </tr>
                            )}
                            
                          </React.Fragment>
                        ))}
                        <h3 style={{ marginTop: '30px' }}>Modifica</h3>
                        <BusinessUnit>
                          
                          {businessUnit && (<div className='business-options'>
                              {businessUnit.map((item) => (
                              <div  className='business-option'>

                                <label class="container">
                                  <input type="checkbox" name={item} onChange={handleChecked}/>
                                  <span class="checkmark"></span>
                                  <p>{item}</p>
                                  
                                </label>
                              </div>
                              ))}
                          </div>)}

                          <div className='percent-container'>

                            {checked.map((item) => (
                              <div className='input-percent-container'>
                                <label>
                                  <input  type="number" step="5" min="0" max="100" onChange={(e) => handlePercentuali(item, e)}/>
                                  <p>% {item}</p>
                                </label><br/>
                              </div>
                            ))}           

                          </div>
                        </BusinessUnit>
                      </>
                    )}
                    
                  </tbody>
              </table>
            ) : (<p>Caricamento...</p>)}
        
    </Container>
  )
}

export default Registro;

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 0; right: 0`

const Pen = styled(MdEdit)`cursor: pointer; font-size: 20px;`

const Done = styled(MdDone)`cursor: pointer; font-size: 30px; font-weight: 900; color: lightgreen`

const Bin = styled(ImBin)`cursor: pointer; font-size: 20px; color: rgba(255,0,0,.8);`

const BusinessUnit = styled.div`
margin-top: 10px;
display: flex;
justify-content: space-between;
align-items: center;
.container input:checked ~ .checkmark {
  background-color: #1eafd6;
  }
  
  p{
    padding-left: 20px;
  }

  .input-percent-container label{
    display: flex;
    align-items: center;
  }

  .input-percent-container input{
    padding: 3px 8px;
    outline: none;
    border: 2px solid white;
    
    &:focus{
      border: 2px solid black;
    }
  }

  input[type="number"]{
    font-size: 20px;
  }

  .percent-container p{
    font-size: 20px;
  }
`

const Container = styled.div`
  color: white;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 100%;
  margin-right: 5px;
  padding-bottom: 80px;
  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
  }

  .popup-title h1{
    margin-left: 5%;
  }

  table{
    margin-top: 30px;
    width: 90%;
    font-size: 18px;
  }

  table th{
    border-bottom: 1px solid white; 
    text-align: start;
    padding-bottom: 7px;
  }
  
  `