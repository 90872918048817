//style
import './App.css';

//other
import {useState, useEffect} from "react";

//components
import Login from "./components/Login.js";
import Home from "./components/Home.js";
import Profilo from './Profilo.js';

//route
import {Route, Routes, Navigate} from "react-router-dom";

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function handleLogin(isLogged){
    
    if(isLogged){
      setIsAuthenticated(true);
    }

  }

  useEffect(() => {
    
  }, [isAuthenticated]);


  return (
    <>
      <Routes>
        <Route path="/" exact element={<Login onSelect={handleLogin}/>} />
        {isAuthenticated ? (<Route path="/home" element={<Home />} />) : (<Route path="*" element={<Navigate to="/" replace />} />)}
        {isAuthenticated ? (<Route path="/profilo" element={<Profilo />} />) : (<Route path="*" element={<Navigate to="/" replace />} />)}
      </Routes>
    </>
  );
}

export default App;


