import React,{useState, useEffect, createContext, useContext} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//style
import "./styles/Home.css";

//component
import ContoEconomico from "./InnerComponents/ContoEconomico.js";
import CopyrightComp from "./CopyrightComp.js";
import FattureSDI from './InnerComponents/FattureSDI.js';
import CashFlow from './Cash Flow/CashFlow.js';
import Indicatori from './Indicatori/Indicatori.js';
import Rating from './Rating/Rating.js';
import Vendite from './Vendite/Vendite.js';
import Budget from './Budget/Budget.js';

//icon
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { BsFileBarGraph } from "react-icons/bs";
import { IoPieChart } from "react-icons/io5";
import { BsGraphUpArrow } from "react-icons/bs";
import { LuAlignHorizontalJustifyStart } from "react-icons/lu";
import { FaWallet } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { BsCashCoin } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { LuFileSpreadsheet } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaPercent } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";

//other
import styled from "styled-components";

//images
import logo from "../images/logo_bianco.png";
import StatoPatrimoniale from './Stato Patrimoniale/StatoPatrimoniale.js';
import Iva from './Cash Flow/Iva.js';
import AndamentoBudget from './Budget/AndamentoBudget.js';
import Supporto from './Supporto.js';


const DataContext = createContext();

function Home(props) {
  const location = useLocation();
  const { username, password } = location.state || {};


  
  //states
  const [isOpen, setIsOpen] = useState(false);
  const [render, setRender] = useState(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  //submenu
  const [seeMenu, setSeeMenu] = useState(true);

  function handleMenu() {
    setSeeMenu(!seeMenu);
  }

  //render 
  function handleClick(item){
    setRender(item);
  }

  //subcat
  const [seeSubCat, setSeeSubCat] = useState(false);
  function subCat(){
    setSeeSubCat(!seeSubCat);
  }

  useEffect(() => {
    
  }, [isOpen]);


  //sottocategoria cashflow
  const [seeSotto, setSeeSotto] = useState(false);
  function sottoCat(){
    setSeeSotto(!seeSotto);
  }

  const [subBudget, setSubBudget] = useState(false);
  function handleSubBudget(){
    setSubBudget(!subBudget);
  }

  return (
    
    <main className='body-main' >
      

      <LeftMenuContainer isOpen={isOpen}>
        <div className='menu-logo'>
          <img src={logo} alt="Logo Azienda"/>
        </div>

        <Divide>
          <hr />
        </Divide>

        <div className='menu-item'>
          <h4 className='menu-title' onClick={handleMenu}>Controllo Gestione {seeMenu ? (<DownArrowItem1 />) : (<RightArrowItem />)}</h4>
          {seeMenu && (<ul>

            
            <li className={render === 'Conto Economico' ? 'item menu-selected' : 'item'}  onClick={() => {handleClick('Conto Economico'); subCat()}}><div className='subcategories'><Icon /> Conto Economico</div> {seeSubCat ? (<MinusIcon />) : (<PlusIcon />)}</li>

            {seeSubCat && <li className={render === 'Fatture SDI' ? 'item menu-selected' : 'item'} id="subcat"  onClick={() => {handleClick('Fatture SDI'); handleToggle()}}><div className='subcategories'><Fatture /> Fatture SDI</div></li>}

            <li className={render === 'StatoPatrimoniale' ? 'item menu-selected' : 'item'} onClick={() => {handleClick('StatoPatrimoniale'); handleToggle()}}><div className='subcategories'><PieChart /> Stato Patrimoniale</div></li>

            <li className={render === 'CashFlow' ? 'item menu-selected' : 'item'}  onClick={() => {handleClick('CashFlow'); sottoCat()}}><div className='subcategories'><CashFlowIcon /> Cash Flow</div> {seeSotto ? (<MinusIcon />) : (<PlusIcon />)}</li>

            {seeSotto && <li className={render === 'IVA' ? 'item menu-selected' : 'item'} id="subcat"  onClick={() => {handleClick('IVA'); handleToggle()}}><div className='subcategories'><IvaIcon/> IVA</div></li>}

            <li className={render === 'Indicatori' ? 'item menu-selected' : 'item'}  onClick={() => {handleClick('Indicatori'); handleToggle()}}><div className='subcategories'><Indicatori1/> Indicatori</div></li>

            <li className={render === 'Rating' ? 'item menu-selected' : 'item'}  onClick={() => {handleClick('Rating'); handleToggle()}}><div className='subcategories'><RatingIcon /> Rating</div></li>

            <li className={render === 'Budget' ? 'item menu-selected' : 'item'} onClick={() => {handleClick('Budget'); handleSubBudget()}}><div className='subcategories'><BudgetIcon /> Budget </div>{subBudget ? (<MinusIcon />) : (<PlusIcon />)}</li>

            {subBudget && <li className={render === 'Andamento_budget' ? 'item menu-selected' : 'item'} id="subcat"  onClick={() => {handleClick('Andamento_budget'); handleToggle()}}><div className='subcategories'><IvaIcon/> Andamento Budget</div></li>}

            <li className={render === 'Vendite' ? 'item menu-selected' : 'item'}  onClick={() => {handleClick('Vendite'); handleToggle()}}><div className='subcategories'><Archivio />Vendite</div></li>

            <li className={render === 'Supporto' ? 'item menu-selected' : 'item'}  onClick={() => {handleClick('Supporto'); handleToggle()}}><div className='subcategories'><Support />Supporto</div></li>

          </ul>)}
        </div>

        <div className='menu-button'>
          {isOpen ? (<MenuButton onClick={handleToggle}/>) : (<MenuButtonRight onClick={handleToggle}/>)}
        </div>


        <div className='copy'>
            <CopyrightComp />
        </div>
      </LeftMenuContainer>

      {isOpen && <div className='opacity'></div>}

      <RightMenuContainer isOpen={isOpen}>
          {!render && (<p className='benvenuto'>Benvenuto nell'App di Nautic Sales & Marketing, seleziona una voce all'interno del menu di sinistra</p>)}
          {render === 'Conto Economico' && <ContoEconomico username={username} password={password}/>}
          {render === 'Fatture SDI' && <FattureSDI />}
          {render === 'CashFlow' && <CashFlow username={username} password={password}/>}
          {render === 'IVA' && <Iva />}
          {render === 'StatoPatrimoniale' && <StatoPatrimoniale username={username} password={password}/>}
          {render === 'Indicatori' && <Indicatori username={username} password={password}/>}
          {render === 'Rating' && <Rating username={username} password={password}/>}
          {render === 'Vendite' && <Vendite username={username} password={password}/>}
          {render === 'Budget' && <Budget username={username} password={password}/>}
          {render === 'Andamento_budget' && <AndamentoBudget username={username} password={password}/>}

          {render === 'Supporto' && <Supporto username={username} password={password}/>}
      </RightMenuContainer>


    </main>
    
  );
}

export default Home;



const LeftMenuContainer = styled.div`
  width: ${({ isOpen }) => (isOpen ? '20%' : '0%')};
  
  .menu-item, .menu-logo, .copy{
    ${({isOpen}) => (!isOpen ? 'display: none': '')};
  }

  overflow: hidden;
  transition: width 0.3s ease;
  position: fixed;
  top: 50%;
  left: 0;
  height: 98vh;
  background-color: #213940;
  overflow: visible;
  transform: translateY(-50%);
  border-radius: 0px 10px 10px 0px;
  z-index: 9000;
  .menu-item ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

  @media(max-width: 780px){
    width: ${({ isOpen }) => (isOpen ? '80%' : '0')};
  }

  
`
const MenuButton = styled(MdKeyboardArrowLeft)`
  font-size: 50px;
  margin-top: 2vh;
  cursor:pointer;
  background-color: #213940;
  transform: translate(50%, 0%);
  border-radius: 10px;
  z-index: 2000;

`
const MenuButtonRight = styled(MdKeyboardArrowRight)`
  font-size: 50px;
  margin-top: 2vh;
  cursor:pointer;
  background-color: #213940;
  transform: translate(80%, 0%);
  z-index: 2000;
  border-radius: 10px;
`

const RightMenuContainer = styled.div`
  width: 100%;
  height: 100vh;
  opacity: ${({ isOpen }) => (isOpen ? '.5' : '1')};
  
  .benvenuto{
    color: lightgrey;
    position: absolute;
    top: 50%;
    font-size: 18px;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
  };

`

const Divide = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3vh;
  
  hr{
    width: 80%;
  }
`

const Icon = styled(BsFileBarGraph)`
  margin-right: 5px;
`

const PieChart = styled(IoPieChart)`
  margin-right: 5px;
`;

const CashFlowIcon = styled(BsCashCoin)`
  margin-right: 5px;
`

const IvaIcon = styled(FaPercent)`
  margin-right: 5px;
`

const RatingIcon = styled(LuAlignHorizontalJustifyStart )`margin-right: 5px;`;

const BudgetIcon = styled(FaWallet)`margin-right: 5px`;

const Archivio = styled(FaCoins)`margin-right: 5px`;

const Support = styled(BiSupport)`margin-right: 5px`;

const Indicatori1 = styled(BsGraphUpArrow)`margin-right: 5px`;

const RightArrowItem = styled(MdKeyboardArrowRight)`
  
  font-size: 30px;
`;

const DownArrowItem1 = styled(MdKeyboardArrowDown)`
  
  font-size: 30px;

`;

const PlusIcon = styled(FaPlus)`
  font-size: 20px;
  margin-right: 10px;
`;

const MinusIcon = styled(FaMinus)`
  font-size: 20px;
  margin-right: 10px;
`

const Fatture = styled(LuFileSpreadsheet)`
  margin-right: 5px;
`