import React, { useEffect, useState } from 'react'

import styled from "styled-components";

//icon
import { IoMdClose } from "react-icons/io";
import Grafico from './Grafico';

import axios from "axios";

function GraphPopup({close, anni}) {

    function handleClose(){
        close();
    }

    //chiamata dati
    const [datiGrafico, setDatiGrafico] = useState(null);
    const fetchData = async () => {
        try {
            const data = {
                anni: anni
            };
    
            const response = await axios.post('https://www.app.nauticsm.it/grafico-vendite', data);
            console.log("fbewubuiweiu")
            setDatiGrafico(response.data);

        } catch (error) {
            console.error('Errore durante la richiesta:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])
  return (
    <Popup>
        <InnerPopup>
            <CloseIcon onClick={handleClose}/>
            <ContainerGrafico>
                <h2>Grafico</h2>
                {datiGrafico && (<Grafico dati={datiGrafico}/>)}
            </ContainerGrafico>
            
        </InnerPopup>
    </Popup>
  )
}

export default GraphPopup;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`


const ContainerGrafico = styled.div`
  margin-bottom: 50px;
  margin-right: 80px;
  margin-left: 80px;

  h2{
    margin-top: 50px;
    margin-bottom: 30px;
    
  }
  

`
const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 0; right: 0`
const InnerPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  padding: 0 50px;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;

  .table-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
  }
  table{
    width: fit-content;
  }

  table td, table th{
    padding: 5px 0px;
    padding-right: 25px;
    font-size: 18px;
  }

  table thead{
    border-bottom: 2px solid black;
    text-align: left;
    
  }
`