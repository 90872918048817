import React, { useState } from 'react'
import TopMenu from './InnerComponents/TopMenu';
import styled from "styled-components";
import axios from "axios";
import { redirect } from 'react-router-dom';

function Supporto() {
    const sezioni = [
        "Conto Economico",
        "Fatture SDI",
        "Stato Patrimoniale",
        "CashFlow",
        "IVA",
        "Indicatori",
        "Rating",
        "Budget",
        "Andamento Budget",
        "Vendite",
        "Altro"
    ];

    const [datiSupporto, setDatiSupporto] = useState({
        sezione: '',
        priorita: '',
        descrizione: '',
        allegato: null
    })

    const [seeConfirm, setSeeConfirm] = useState(false);

    function handleChange(e){
        const { name, value } = e.target;
        setDatiSupporto({
            ...datiSupporto,
            [name]: value,
        });
    };

    // Funzione per gestire l'upload del file
    function handleFileChange(e){
        setDatiSupporto({
            ...datiSupporto,
            allegato: e.target.files[0],
        });
    };

    // Funzione per gestire la submission del form
    async function handleSubmit(e) {
        e.preventDefault();
        console.log(datiSupporto);
    
        // Creiamo un oggetto FormData
        const formData = new FormData();
        formData.append('sezione', datiSupporto.sezione);
        formData.append('priorita', datiSupporto.priorita);
        formData.append('descrizione', datiSupporto.descrizione);
        formData.append('allegato', datiSupporto.allegato); // Aggiungiamo il file
    
        try {
            // Inviamo la richiesta POST con il formData
            const response = await axios.post("https://www.app.nauticsm.it/supporto", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Impostiamo il tipo di contenuto
                },
            });
    
            console.log(response.data);
    
            // Resettiamo il form dopo il successo
            setDatiSupporto({
                sezione: '',
                priorita: '',
                descrizione: '',
                allegato: null,
            });

            setSeeConfirm(true);

            setInterval(() => {
                setSeeConfirm(false);
                redirect("/");
            }, 3000)

        } catch (error) {
            console.error('Errore durante l\'invio del supporto:', error);
        }
    };
  return (
    <div>
        <TopMenu name="Supporto"/>
        <Container>
            {!seeConfirm ? (
                <form>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div>
                            <label>Selezionare la sezione<span>*</span></label><br/>
                            <select name="sezione" value={datiSupporto.sezione} onChange={handleChange} required>
                                <option value="" disabled>- Seleziona -</option>
                                {sezioni.map((item) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{marginLeft: '20px'}}>
                            <label>Priorità del problema<span>*</span></label><br/>
                            <select name="priorita" value={datiSupporto.priorita} onChange={handleChange} required>
                                <option value="" disabled>- Seleziona -</option>
                                <option value="bassa">Bassa</option>
                                <option value="media">Media</option>
                                <option value="alta">Alta</option>
                            </select>
                        </div>
                    </div>
                    <br/>
                    <label>Descrivere il problema riscontrato<span>*</span></label><br/>
                    <textarea name="descrizione" value={datiSupporto.descrizione} onChange={handleChange} required placeholder='Fornire una descrizione del problema'></textarea>

                    <br/><br/>
                    <label>Allegare eventuale foto:</label><br/>
                    <input type="file" accept="image/*" onChange={handleFileChange}/>

                    <br/><br/>
                    <button type="submit" onClick={handleSubmit}>Invia</button>
                </form>
            ) : (
                <div className='confirm'>
                    <h3 style={{fontSize: '30px'}}>Il tuo messaggio è stato ricevuto correttamente &#10004;</h3>
                </div>
            )}
        </Container>
    </div>
  )
}

export default Supporto;

const Container = styled.div`
    width: 100%;
    height: 90vh;

    span{
        color: red;
        font-size: 16px;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    select{
        cursor: pointer;
    }

    input, select, textarea{
        background-color: rgba(224, 224, 224, .6);
        border-radius: 5px;
        font-size: 18px;
        color: black;
    }

    label{
        font-size: 20px;
        font-weight: 700;
        color: black;
    }

    textarea{
        width: 100%;
        min-height: 100px;
        padding: 5px 10px 5px 5px;
    }

    input[type="file"], select{
        width: 100%;
    }

    button{
        width: 100%;
        font-size: 20px;
        cursor: pointer;
        border-radius: 5px;
        outline: none;
        border: none;
        padding: 10px 0px;
        background-color: #213940;
        color: white;
        transition: all .3s linear;
    }

    button:hover{
        background-color: rgba(33, 57, 64, .8);
    }
`