import React, {useState} from 'react';

import styled from "styled-components";

import { IoMdClose } from "react-icons/io";
import Correct from './Correct';

function Preset({close, categorie, businessUnit, ricarica}) {

    function handleClose(){
        close();
    }

     //checked
  const [checked, setChecked] = useState({});

  function handleChecked(id, unit){
    setChecked(prevOggetto => {
      console.log(prevOggetto);
      if (prevOggetto[id]) {
        
        const { [id]: _, ...nuovoOggetto } = prevOggetto;
        return nuovoOggetto;
      } else {
        
        return {
          ...prevOggetto,
          [id]: unit
        };
      }
    });
  }

  //dati da inviare
  const [dataUnit, setDataUnit] = useState({});

  function handleUnit(unit, e){
    
    setDataUnit(prevOggetto => ({
      ...prevOggetto,
      [unit]: e.target.value
    }));

    console.log(dataUnit);
    
  }

  const [categoria, setCategoria] = useState('seleziona');

  function handleCategoria(e){
    const {value} = e.target;

    setCategoria(value);
  }

  async function handleClick(){
    
    const datiDaInviare = {
      categoria: categoria,
      unit: dataUnit
    };

    setTick(!tick);

    setTimeout(() => {
      setTick(false);
      close();
    }, 2000);

    

    try {
      
      const response = await fetch('https://www.app.nauticsm.it/preset-unit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datiDaInviare),
      });

      if (response.ok) {
        // Esegui la tua funzione dopo il successo del POST
        ricarica();
      } else {
        console.error('Errore nella richiesta:', response.statusText);
      }
      

    } catch (error) {
      console.error('Errore nella richiesta:', error);
    }
  }
  const [tick, setTick] = useState(false);

  return (
    <Opacity>
        <Popup>
            <CloseIcon onClick={handleClose}/>
            {tick ? (
                <Correct />
            ) : (
                <>  
                  <div style={{padding: '20px 80px 20px 25px'}}>
                  <div className='title'>
                        <h2>Aggiungi Preset Business Unit</h2>
                        
                    </div>
                    <div className='business-option'>
                        <label for="categoria">Seleziona categoria:</label>
                        <select name="categoria" onChange={handleCategoria} value={categoria}>
                            <option value="seleziona" disabled selected>- Seleziona -</option>
                            {categorie
                              .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                              .map((item) => (
                                  <option value={item} key={item}>{item}</option>
                              ))
                            }
                        </select>
                        
                    </div>
                    {businessUnit.map((item) => (
                                <div key={item.id} className='business-option'>

                                <label class="container">
                                    <input type="checkbox" onChange={() => handleChecked(item.id, item.unit)} name={item.unit}/>
                                    <span class="checkmark"></span>
                                    <p>{item.unit}</p>
                                    
                                </label>
                                </div>
                    ))}
                    <div className='percent'>

                        {Object.keys(checked).map(key => (
                        <div className='input-percent'>
                            <label>
                            <input onChange={(e) => handleUnit(checked[key], e)} type="number" step="5" min="0" max="100"/>
                            <p>% {checked[key]}</p>
                            </label><br/>
                        </div>
                        ))}           

                    </div>
                  </div>
                    
                  <div className='send-button'>
                      <button id="aggiungi" onClick={handleClick}>Salva</button>
                  </div>
                </>
            )}
        </Popup>
    </Opacity>
  )
}

export default Preset;


const Popup = styled.div`
 position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  min-height: 30vh;
  border-radius: 10px;
  overflow-y: auto;
  background-color: white;
  z-index: 9999;
  max-height: 80vh;

  .business-option{
    margin-top: 20px;
  }
  .business-option label{
    margin-right:20px;
    cursor: pointer;
    font-size: 20px;
  }
  .input-percent label{
    font-size: 20px;
  }
  .input-percent input{
    font-size: 18px;
    outline: none;
    padding: 5px 8px;
    border: 1px solid black;

  }

  .input-percent input:focus{
    box-shadow: 0px 0px 7px rgba(0,0,0,.7)
  }

  .business-option input{
    margin-right: 5px;
  }

  .business-option select{
    cursor: pointer;
  }

  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

  p{
    margin-left: 40px;
  }

`

const Opacity = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`


const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`