import React, {useEffect, useState, useRef} from 'react'

//style
import "./innerStyles/ContoEconomico.css";
import "./innerStyles/Table.css";

//other
import styled from "styled-components";
import axios from "axios";
import { Slide } from 'react-reveal';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import debounce from 'lodash/debounce';

//icon
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdKeyboardArrowUp } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { FaCompressAlt } from "react-icons/fa";
import { FaExpandAlt } from "react-icons/fa";
import { MdFilterAltOff } from "react-icons/md";


//components
import Table from './Table.js';
import TopMenu from './TopMenu.js';
import Correct from './Correct.js';
import CategoriaConto from './CategoriaConto.js';
import Registro from "./Registro.js";



function ContoEconomico({username, password}) {

  const [showPopup, setShowPopup] = useState(false);
  const [displayTick, setDisplayTick] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());

  const [checked, setChecked] = useState([]);

  function handleChecked(e){
    const name = e.target.name;

    if (checked.includes(name)) {
      
      setChecked(checked.filter(item => item !== name));
    } else {
      
      setChecked([...checked, name]);
    }
  }

  const [percentualiDivisioni, setPercentualiDivisioni] = useState({});

  function handlePercentuali(item, e){
    const value = e.target.value;
    setPercentualiDivisioni(prevState => {
      // Controlla se la chiave item esiste già nell'oggetto
      if (prevState[item]) {
        // Se la chiave esiste già, aggiungi il valore al suo valore attuale
        return {
          ...prevState,
          [item]: value
        };
      } else {
        // Se la chiave non esiste, aggiungi una nuova chiave con il valore
        return {
          ...prevState,
          [item]: value
        };
      }
    });
    
  }

  function handlePopup(){
    setShowPopup(!showPopup);
    setDisplayTick(false);
  }

  const [showAdd, setShowAdd] = useState(false);
  function handlePopupAdd(){
    
    setShowAdd(!showAdd);
    setDisplayTick(false);
  }

  const [aggiungi, setAggiungi] = useState(false);

  function handleSeeAggiungi(){
    setAggiungi(!aggiungi);
  }


 

  //categorie
  const [categorie, setCategorie] = useState([]);
  const [registroImporti, setRegistroImporti] = useState(null);
  const [businessUnit, setBusinessUnit] = useState(null); 

  const fetchData = async () => {
    try {
      const response = await axios.get('https://www.app.nauticsm.it/categorie-conto');
      setCategorie(response.data.categorie);
      setRegistroImporti(response.data.registro);
      setBusinessUnit(response.data.businessunit);
      
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  useEffect( () => {
    fetchData();
  }, [])

  const [addData, setAddData] = useState({
    category: 'seleziona',
    importo: '',
    anno: 2024,
    divisioni: checked,
    percentuali: percentualiDivisioni
  });

  const handleAdd = (e) => {
    const { name, value } = e.target;
    setAddData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setAddData(prevAddData => ({
      ...prevAddData,
      divisioni: checked
    }));
  }, [checked]);

  useEffect(() => {
    setAddData(prevAddData => ({
      ...prevAddData,
      percentuali: percentualiDivisioni
    }));
  }, [percentualiDivisioni]);

  const [ricarica, setRicarica] = useState(false);
  const handleAddClick = () => {
    
    setDisplayTick(!displayTick);

    setTimeout(() => {
      setDisplayTick(prevDisplayTick => !prevDisplayTick);
      setShowPopup(prevShowPopup => false);
      setShowAdd(prevShowAdd => false);
      
    }, 1000);
  
    fetch('https://www.app.nauticsm.it/aggiungi-importo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(addData),
  })
  .then(() => {
    //
      console.log("then")
      setChecked([]);
      setPercentualiDivisioni({});
      ricaricaDati();
    
  })
  .catch(error => {
    // Gestione degli errori durante la richiesta
    console.error('Errore durante l\'invio dei dati al server:', error);
  });


      
  };

  

  
  //bottom menu
  const [bottom, setBottom] = useState(false);

  function handleBottom(){
    setBottom(!bottom);
  }

  
  
  function ricaricaDati(){
    setRicarica(true);
    fetchData();
    contoDati();
  }

  function reset(){
    setRicarica(false);
  }

  //see legend divisioni
  const [seeDivisioni, setSeeDivisioni] = useState(false);
  function handleLegend(){
    setSeeDivisioni(!seeDivisioni);
  }

  //selezione divisioni aziendali
  const [checkedDivisioni, setCheckedDivisioni] = useState(["Tutto"]);

  function handleChangeDivisioni(event){
    const value = event.target.value;
    
    if(value !== 'Tutto' && checkedDivisioni.includes("Tutto")){
      const initialValue = "Tutto";
      const filteredDivisioni = checkedDivisioni.filter(item => item !== initialValue);
      setCheckedDivisioni([...filteredDivisioni, value]);

    } else if(value !== 'Non Assegnate' && checkedDivisioni.includes("Non Assegnate")){
      const initialValue = "Non Assegnate";
      const filteredDivisioni = checkedDivisioni.filter(item => item !== initialValue);
      setCheckedDivisioni([...filteredDivisioni, value]);

    }else if(value === "Tutto"){

      setCheckedDivisioni([value]);

    } else if(value === 'Non Assegnate'){
      setCheckedDivisioni([value]);

    }else {
      if (checkedDivisioni.includes(value)) {
        setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      } else {
        setCheckedDivisioni([...checkedDivisioni, value]);
      }
    }
  };

  function handleRemoveDivisioni(item){
    const value = item;
    const initialValue = "Tutto";
    
    if(checkedDivisioni.length === 1){
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      setCheckedDivisioni([initialValue]);
    }else {
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
    }

  };

  

  //dati tabella
  const [anniSelezionati, setAnniSelezionati] = useState([]);
  const [oggetto, setOggetto] = useState(null);
  

  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

const isRequestInProgressRef = useRef(isRequestInProgress);
isRequestInProgressRef.current = isRequestInProgress;

async function contoDati() {
  // Evita di fare una richiesta se ce n'è già una in corso
  if (isRequestInProgressRef.current) {
    return;
  }

  console.log("CHIAMATA");
  setIsRequestInProgress(true);

  try {
    const dataToSend = {
      divisioni: checkedDivisioni,
      giorno: selectedDate
    };

    const response = await axios.post('https://www.app.nauticsm.it/conto-economico', dataToSend);
    setOggetto(response.data);
    let anniIniziali = response.data?.anni ?? [];
    setAnniSelezionati(anniIniziali);
  } catch (error) {
    console.error('Errore durante la richiesta POST:', error);
  } finally {
    setIsRequestInProgress(false);
  }
}

// Controllo con debounce
const contoDatiDebounced = debounce(() => {
  contoDati();
}, 300);

useEffect(() => {
  contoDatiDebounced();
}, [selectedDate, checkedDivisioni]);


  //date picker

  const inputProps = {
    style: { cursor: 'pointer' }, 
    placeholder: moment().format('DD/MM/YYYY')
  };

  const renderInput = (props, openCalendar) => {
    const handleClick = () => {
      setSelectedDate(moment()); 
      openCalendar();
    };

    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <input {...props} />
        <p style={{marginLeft: '10px', cursor: 'pointer', fontSize: '18px', color: '#0000EE', textDecoration: 'underline'}} onClick={handleClick}>Oggi</p>
      </div>
    );
  };

  const isValidDate = (current) => {
    return current.isSameOrBefore(moment(), 'day'); 
  };


  //filter
  const [seeFilter, setSeeFilter] = useState(false);

  function handleFilter(){
    setSeeFilter(!seeFilter);
    setSeeDivisioni(false);
  }


  //selezione anni
  const [anniSelezionatiTable, setAnniSelezionatiTable] = useState(null);

  useEffect(() => {
    setAnniSelezionatiTable(anniSelezionati);
  }, [anniSelezionati]);

  function changeAnni(e){
    
    const valoreCheckbox = parseInt(e.target.value, 10);
    const isChecked = e.target.checked;

    setAnniSelezionatiTable(prevAnni => {
      let nuovoArray;
      if (isChecked) {
        
        nuovoArray = [...prevAnni, valoreCheckbox];
      } else {
        
        nuovoArray = prevAnni.filter(valore => valore !== valoreCheckbox);
      }

      
      return nuovoArray.sort((a, b) => b - a);
    });

  }
  //see year
  const [seeYear, setSeeYear] = useState(false);
  function handleSeeYear(){
    setSeeYear(!seeYear);
  }

  //visualizzazione
  const [completo, setCompleto] = useState(1);

  function handleSelectionRadio(){
    if(completo === 1){
      setCompleto(2);
    }else {
      setCompleto(1);
    }
  }

  return (
    <>
      <div className={bottom ? 'opacity' : ''}>
        <TopMenu name="Conto Economico" username={username} />

        {businessUnit && (


          <UpperMenu>
            <Filter seeFilter={seeFilter}>
              <div style={{display: 'flex', alignItems: 'center'}} >
                <div className='icon-container tooltip' onClick={handleFilter}>
                  <span class="tooltiptext">Filtri</span>
                  {seeFilter ? (
                    <FilterOffIcon onClick={handleFilter}/>
                  ) : (
                    <FilterIcon onClick={handleFilter}/>
                  )}
                </div>
                <div className='icon-container tooltip' style={{margin: '0px 10px'}} onClick={handleSelectionRadio}>
                  <span class="tooltiptext">Visualizzazione</span>
                  {completo === 2 ? (
                    <ExpandIcon onClick={handleSelectionRadio}/>
                  ) : (
                    <CompressIcon onClick={handleSelectionRadio}/>
                  )}
                </div>
                
              </div>

              <div className='filter-container'>
                
                <Divisioni seeDivisioni={seeDivisioni}>
            
                  <div style={{position: 'relative', width: 'fit-content'}}>
                    <button className="divisioni-title" onClick={handleLegend}>Divisioni Aziendali <DownArrow /></button>
                  
                    <div className='divisioni-aziendali'>
                      <label><input style={{marginTop: "15px"}} onChange={handleChangeDivisioni} checked={checkedDivisioni.includes("Tutto")}  value="Tutto" type="checkbox"/> Tutto</label><br/>
                      {businessUnit.map((item, index) => (
                        <><label ><input checked={checkedDivisioni.includes(item)} onChange={handleChangeDivisioni} value={item} type="checkbox" /> {item}</label><br/></>
                      ))}
                      
                      <label><input checked={checkedDivisioni.includes("Non Assegnate")} onChange={handleChangeDivisioni} style={{marginBottom: '15px'}} value="Non Assegnate" type="checkbox"/> Non Assegnate</label>
                        <div className='spuntate'>
                        {checkedDivisioni.map((item) => ( 
                          <p className='check'>{item} <Delete onClick={() => handleRemoveDivisioni(item)}/></p>
                        ))}
                      </div>
                    </div>
                    
                  </div>
                  <div className='date-selection'>
                    <Datetime
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      inputProps={inputProps}
                      timeFormat={false}
                      renderInput={renderInput}
                      isValidDate={isValidDate}
                      
                    />
                  </div>
                  <div className='select-anni'>
          
                    <div className='year-selection' onClick={handleSeeYear}>
                      <p>Seleziona anni <DownArrow /></p>
                    </div>
                    <DropDown seeYear={seeYear}>
                      {anniSelezionati.length > 0 && (
                        <div className='dropdown-content'>
                          {anniSelezionati.slice().map((anno, annoIndex) => (
                            <><label><input type="checkbox" value={anno} name="anni" checked={anniSelezionatiTable.includes(anno)} onChange={changeAnni}/> {anno}</label></>
                          ))}
                        </div>
                      )}
                    </DropDown>
                  </div>
                </Divisioni>
                
              </div>
            </Filter>
          </UpperMenu>
          
        )}

        

        {showPopup && (<CategoriaConto close={handlePopup} categorie={categorie} ricaricaDati={ricaricaDati}/>)}

        {/* {registro && (<Registro close={handleRegistro} registro={registroImporti}/>)} */}

        {showAdd && (<React.Fragment>
          {displayTick ? (<Popup><PopupContent><Correct close={handlePopupAdd}/></PopupContent></Popup>) : (
            <Popup>

            <InnerPopup>
              <div className='popup-title'>
                <h2>Aggiungi Valore</h2>
                <CloseIcon onClick={handlePopupAdd}/>
              </div>
              <div className='popup-form'>
                <label for="categories">Seleziona una categoria:</label>

                <select name="category" id="cat" onChange={handleAdd} >
                  <option disabled selected value="seleziona">- Seleziona -</option>
                  {categorie
                    .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                    .map((item) => (
                        <option value={item} key={item}>{item}</option>
                    ))
                  }
                  <option value="Imposte Esercizio">Imposte Esercizio (IRES-IRAP)</option>
                </select>

                <br /><br />

                <div style={{display: 'flex', alignItems: 'center'}}>
                  <label for="name">Inserisci importo <span className='advise'>(usare . per i decimali es: 100.3)</span>:</label>
                  <input name="importo" onChange={handleAdd} value={addData.importo} type="text" placeholder="1000"/>
                </div>

                

                <br /><br />

                <div style={{display: 'flex', alignItems: 'center'}}>
                  <label for="name">Inserisci anno:</label>
                  <input name="anno" onChange={handleAdd} value={addData.anno} type="number" placeholder="2024"/>
                </div>
                
                

                <br /><br />
                <label>Seleziona divisioni:</label>
                <BusinessUnit>
                  
                  {businessUnit && (<div className='business-options'>
                      {businessUnit.map((item) => (
                      <div  className='business-option'>

                        <label class="container" style={{fontWeight: '500'}}>
                          <input type="checkbox" name={item} onChange={handleChecked}/>
                          <span class="checkmark"></span>
                          <p>{item}</p>
                          
                        </label>
                      </div>
                      ))}
                  </div>)}

                  <div className='percent-container'>

                    {checked.map((item) => (
                      <div className='input-percent-container'>
                        <label style={{fontWeight: '500', whiteSpace: 'nowrap'}}>
                          <input  type="number" step="5" min="0" max="100" onChange={(e) => handlePercentuali(item, e)}/>
                          <p>% {item}</p>
                        </label><br/>
                      </div>
                    ))}           

                  </div>
                </BusinessUnit>

              </div>

              <div className='send-button'>
                <button id="aggiungi" onClick={handleAddClick}>Aggiungi</button>
              </div>
            </InnerPopup>

            </Popup>
          )}
        </React.Fragment>)}

        <ContainerAggiungi>
          
            
              <div >

                <Slide right effect="slideInRight" duration={700} when={aggiungi} >
                  <button onClick={handlePopup}>Categoria</button>
                </Slide>
                <Slide right effect="slideInRight" delay={200} duration={700} when={aggiungi} >
                  <button onClick={handlePopupAdd}>Importo</button>
                </Slide>

              </div>
            
          
          <div className='aggiungi' onClick={handleSeeAggiungi}>
            <PlusIcon aggiungi={aggiungi}/>
          </div>
        </ContainerAggiungi>

        
        {oggetto && (<Table ricarica={ricarica} reset={reset} anniSelezionati={anniSelezionatiTable} oggetto={oggetto} completo={completo}/>)}
        

      </div>
      

      
      <BottomMenuContainer bottom={bottom}>
            <MenuButton bottom={bottom} onClick={handleBottom}/>
            <Registro ricaricaDati={ricaricaDati} businessUnit={businessUnit} registro={registroImporti}/>
      </BottomMenuContainer>
      
      
      
    </>
  )
}

export default ContoEconomico;

const UpperMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
  
`

const Filter = styled.div`
  width: 100%;
  padding-right: 2%;
  margin-top: 30px;

  .icon-container{
    border: 1px solid rgba(0,0,0,.5);
    border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
    cursor: pointer;
    color: rgba(0,0,0,.5);
    transition: all .3s linear;
  }

  .icon-container:hover{
    border: 1px solid black;
    color: black;
  }

  .filter-container{
    max-height: ${props => (props.seeFilter ? '400px' : '0px')};
    padding:  ${props => (props.seeFilter ? '20px 0px' : '0px')};
    height: fit-content;
    border: ${props => (props.seeFilter ? '1px solid rgba(0,0,0,.5)' : 'none')};
    overflow: ${props => (props.seeFilter ? 'visible' : 'hidden')};;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    border-radius: 0px 10px 10px 10px;

    transition: max-height 0.3s ease, padding 0.3s ease;
    
  }
`


const Delete = styled(IoMdClose)`
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 2px;
`
const FilterOffIcon = styled(MdFilterAltOff)`
  font-size: 24px;
  cursor: pointer;  
`
const Divisioni = styled.div`
  padding-left: 2%;
  padding-right: 2%;

  
  display: flex;
  align-items: center;
  justify-content: space-between;

  .date-selection{
    input{
      outline: none;
      border: 1px solid rgba(0,0,0,.5);
      cursor: pointer;
      font-size: 18px;
      padding: 5px 10px;
      color: rgba(0,0,0,.5);
      transition: all .3s linear;
    }

    input:hover{
      color: black;
      border: 1px solid black;

    }
  }
  

  .spuntate .check{
    background-color: lightgrey;
    margin-right: 8px;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  .divisioni-title{
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0,0,0,.5);
    padding: 5px 20px;
    color: rgba(0,0,0,.5);
    background-color: white;
    transition: all .3s linear;

  }

  .divisioni-title:hover{
    color: black;
    border: 1px solid black;
  }

  .spuntate{
    display: flex;
    align-items: center;
    margin-top: ${({seeDivisioni}) => (seeDivisioni ? '10px' : '0px')};
    margin-bottom: 10px;
    max-width: 300px;
    flex-wrap: wrap;
    height: fit-content;

    p{
      margin: 3px 0px;
    }
  }
  
  .divisioni-aziendali{
    
    position: absolute;
    
    background-color: white;
    z-index: 9998;
    max-height: ${({seeDivisioni}) => (seeDivisioni ? '400px' : '0')};
    height: fit-content;
    box-shadow: 0px 0px 3px rgba(0,0,0,.4);
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    width: 250px;
    margin-top: 3px;
    font-size: 18px;
    padding: 0px 10px;
    
  }

  label, input{
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }


  
`


const BottomMenuContainer = styled.div`
  height: ${({ bottom }) => (bottom ? '80%' : '0%')};
  width: 95%;
  overflow: hidden;
  transition: height 0.3s ease;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
  left: 50%;
  overflow: visible;
  background-color: #36454F;
  

  transform: translateX(-50%);
  border-radius: 10px 10px 0px 0px;
  z-index: 9000;

  .menu-item ul li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
  }

`

const MenuButton = styled(MdKeyboardArrowUp )`
  font-size: 50px;
  cursor:pointer;
  background-color: #36454F;
  backdrop-filter: blur(4px); 
  border-radius: 10px;
  z-index: 2000;
  position: fixed;
  right: 50%;
  top: 0;
  color: white;
  transform: ${({bottom}) => (bottom ? 'translate(-50%, -50%) rotate(180deg)' : 'translate(-50%, -70%) rotate(0deg)')}
  
  
`

const ContainerAggiungi = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;

  div{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  button{
    font-size: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
  }
  button:nth-child(1){
    background-color: red;
    color: white;
  }
  button:nth-child(2){
    margin: 20px 0px;
    background-color: green;
    color: white;
  }


  
`

const Popup = styled.div`
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;
 

`

const PopupContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
`;

const InnerPopup = styled.div`
  position: fixed;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;

  padding: 0 50px 0px 25px;
  
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
  }


  .popup-form{
    
    margin-top: 20px;
  }

  .popup-form input{
    border: 1px solid black;
    font-size: 18px;
    padding: 3px 8px;
  }

  .popup-form .percent-container input{
    font-size: 18px;
    padding-left: 5px;
  }

  .popup-form label{
    margin-right: 20px;
    font-size: 18px;
  }

  .popup-form select{
    font-size: 18px;
    border: 1px solid black;
    cursor: pointer;
  }

  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

  
`

const BusinessUnit = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
    padding-left: 20px;
    display: inline;
  }


  .input-percent-container label{
    display: flex;
  }

  .business-options, .percent-container{
    width: fit-content;
    min-width: 50%;
  }

  .input-percent-container{
    display: flex;
    align-items: center;

    label{
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
  }

`

const PlusIcon = styled(FaPlus)`
  font-size: 54px;
  color: white;
  padding: 10px;
  transition: all .2s linear;
  ${({ aggiungi }) => (aggiungi && 'transform: rotateZ(45deg);')};
`

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 0; right: 0`

const FilterIcon = styled(FaFilter)`
  font-size: 20px;
  cursor: pointer;  
`

const CompressIcon = styled(FaCompressAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const ExpandIcon = styled(FaExpandAlt)`
  font-size: 20px;
  cursor: pointer;  
`


const DownArrow = styled(IoIosArrowDown)`
  font-size: 18px;
  margin-left: 5px;
  margin-top: 3px;
`



const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  
  z-index: 9998;
  background-color: white;
  border-radius: 5px;
  margin-top: 3px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
  z-index: 1;
  max-height: ${({ seeYear }) => (seeYear ? '500px' : '0px')};
  overflow: hidden;
  padding: 0px 10px;
  border: none;
  
  .dropdown-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px;

    label{
      padding: 5px 0px;
      font-size: 20px;
    }
  }

  transition: all .5s linear;
`;