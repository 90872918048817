import React, {useEffect, useState} from 'react'

import styled from "styled-components";
import axios from "axios";

import { useLocation } from 'react-router-dom';
import TopMenu from './components/InnerComponents/TopMenu';

function Profilo() {
    const location = useLocation();
    const [usernameProfilo, setUsernameProfilo] = useState(null);

  useEffect(() => {
    const datiRicevuti = location.state;
    setUsernameProfilo(datiRicevuti.username);
    console.log('Dati ricevuti:', datiRicevuti);
  }, [location.state]);


  //nuova password
  const [nuovaPassword, setNuovaPassword] = useState({
    username: '',
    vecchiaPassword: '',
    nuovaPassword: ''
  })

  useEffect(() => {
    setNuovaPassword(prevState => ({
      ...prevState,
      username: usernameProfilo
    }));
  }, [usernameProfilo]);

  function handleReimposta(e){
    const {name, value} = e.target;

    setNuovaPassword(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  const [successo, setSuccesso] = useState(null);
  const [seeSuccesso, setSeeSuccesso] = useState(null);

  function sendReimposta(){
    axios.post('https://www.app.nauticsm.it/nuova-password', nuovaPassword)
        .then(response => {
            console.log('Dati inviati con successo:', response.data);
            setNuovaPassword({
                username: usernameProfilo,
                vecchiaPassword: '',
                nuovaPassword: ''
            })
            setSuccesso(1);
            setSeeSuccesso(true);
            setTimeout(() => {
                setSeeSuccesso(false);
            }, 3000);
        })
        .catch(error => {
            console.error('Errore durante l\'invio dei dati:', error);
            setSuccesso(0);
            setNuovaPassword({
                username: usernameProfilo,
                vecchiaPassword: '',
                nuovaPassword: ''
            })
            setSeeSuccesso(true);
            setTimeout(() => {
                setSeeSuccesso(false);
            }, 3000)

        });
  }

  
  //nuovo utente
  const [nuovoUtente, setNuovoUtente] = useState({
    newUsername: '',
    newPassword: ''
  })

  function handleNuovoUtente(e){
    const {name, value} = e.target;

    setNuovoUtente(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  function sendNuovoUtente(){
    axios.post('https://www.app.nauticsm.it/nuovo-utenteprofilo', nuovoUtente)
        .then(response => {
            console.log('Dati inviati con successo:', response.data);
            setNuovoUtente({
                newUsername: '',
                newPassword: ''
            })
        })
        .catch(error => {
            console.error('Errore durante l\'invio dei dati:', error);
        });
  }


  //utente root display
  const [rootDisplay, setRootDisplay] = useState(false);
  const [accessData, setAccessData] = useState(null);

  function changeDisplay(){
    setRootDisplay(!rootDisplay);
  }

  const fetchDataAccess = async () => {
    try {
      const response = await axios.get('https://www.app.nauticsm.it/data-accessi');
      console.log(response.data);
      setAccessData(response.data);
      
    } catch (error) {
      console.error('Errore durante la richiesta:', error);
    }
  };

  useEffect(() => {
    if(rootDisplay){
        fetchDataAccess();
    }
  }, [rootDisplay])

  return (
    <>
        <TopMenu name="Profilo" />

        <Profile>
            <div className='no-root'>
                <div className='inner-root'>
                    <div className='title-profile'>
                        <h1>Profilo</h1>
                    </div>
                    <div className='info-profile'>
                        <h3>Username:</h3>
                        <p>{usernameProfilo}</p>
                    </div>
                    <div className='password-profile'>
                        <h3>Reimposta password: </h3>
                        <br/>
                        <label>Vecchia password: <input type="text" name="vecchiaPassword" value={nuovaPassword.vecchiaPassword} onChange={handleReimposta} placeholder='Vecchia password'/></label>
                        <br/><br/>
                        <label>Nuova password: <input type="text" name="nuovaPassword" value={nuovaPassword.nuovaPassword} onChange={handleReimposta} placeholder='Nuova password'/></label>
                        
                    </div>
                    <div className='button-profile'>
                        <button onClick={sendReimposta}>Reimposta</button>
                    </div>
                    {seeSuccesso && (
                        <div className='successo'>
                            {successo === 1 ? (
                                <p className='suc'>Operazione avvenuta con successo!</p>
                            ) : (
                                <p className='fal'>Operazione fallita!</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className='root'>

                <div className='inner-root'>
                    {usernameProfilo !== 'root' ? (
                        <p className='text-noroot'>Funzioni riservate all'utente <span style={{textDecoration: 'underline'}}>root</span></p>
                    ) : (
                        <>
                            {!rootDisplay ? (
                                <>
                                    <div className='title-profile'>
                                        <h1>Nuovo Profilo</h1>
                                    </div>
                                    <div className='password-profile'>
                                        <h3>Inserisci dati nuovo profilo: </h3>
                                        <br/>
                                        <label>Nome Utente: <input type="text" onChange={handleNuovoUtente} name="newUsername" placeholder='Username'/></label>
                                        <br/><br/>
                                        <label>Password: <input type="text" onChange={handleNuovoUtente} name="newPassword" placeholder='Password'/></label>
                                        
                                    </div>
                                    <div className='button-profile'>
                                        <button onClick={sendNuovoUtente}>Aggiungi</button>
                                        
                                    </div>
                                    <div className='registro'>
                                        <p onClick={changeDisplay}>Registro Accessi</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {accessData && (
                                        <div className='table-container-root'>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Utente</th>
                                                        <th>Data e Ora</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {accessData.map((item) => (
                                                        <tr>
                                                            <td>{item.utente}</td>
                                                            <td>{item.data_accesso}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <div className='registro'>
                                        <p onClick={changeDisplay}>Nuovo Utente</p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>

            </div>
        </Profile>
    </>
  )
}

export default Profilo;

const Profile = styled.div`
    height: 90vh;
    width: 100%;
    display: flex;

    .text-noroot{
        color: lightgrey;
        font-size: 22px;
    }

    .no-root, .root{
        width: 50%;
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .no-root{
        padding-left: 2%;
    }

    overflow: hidden;


    .info-profile{
        margin-top: 30px;
        
    }

    .password-profile{
        margin-top: 30px;
    }

    .password-profile input{
        outline: none;
        border: 2px solid #213940;
        
        margin-left: 10px;

        &:focus{
            box-shadow: 0px 0px 10px rgba(33, 57, 64, .8);
        }
    }

    label, input{
        font-size: 18px;
    }

    input{
        padding: 3px 0px 3px 5px;
    }

    button{
        background-color: #213940;
        color: white;
        font-size: 18px;
        outline: none;
        border: none;
        padding: 7px 15px;
        cursor: pointer;
        transition: all .3s linear;
        &:hover{
            background-color: rgba(33, 57, 64, .7)
        }
    }

    .button-profile{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        
        button{
            border-radius: 5px;
        }
    }
    .registro{
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .registro p{
        cursor: pointer;
        font-size: 20px;
        text-decoration: underline;
    }

    .successo{
        margin-top: 10px;
        font-size: 18px;
        text-align: center;
    }

    .suc{
        color: green;
        background-color: lightgreen;
        padding: 3px 0px;
    }

    .fal{
        color: #FF0000;
        background-color: rgba(255,0,0,.5);
        padding: 3px 0px;
    }

    .inner-root tr td{
        font-size: 20px;
        text-align: left;
    }

    .inner-root tr th{
        font-size: 24px;
        text-align: left;
    }

    .inner-root table thead th{
        padding: 0px;
        padding-right: 100px;
    }


    .table-container-root {
        max-height: 50vh; 
        overflow-y: auto; 
    }

    .table-container-root table thead {
        position: sticky;
        top: 0;
        border-bottom: 2px solid black;
        z-index: 9998;
        background-color: white;
    }
    
    
`