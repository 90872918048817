import React, {useEffect, useState} from 'react'
import axios from "axios";

//icon
import { IoIosArrowDown } from "react-icons/io";
import { FaCompressAlt } from "react-icons/fa";
import { FaExpandAlt } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { MdFilterAltOff } from "react-icons/md";

import styled from "styled-components";

function TableStato({dati, anni}) {

  const lastAttivita = [0, 1000, 1001, 2000, 2001, 3000, 4001, 5000, 5001, 5002, 6000, 6001, 7000, 7001, 8001, 9000, 9001, 10000, 10002, 10001, 8000, 4000];
  const totaliAttivita = [10002, 10001, 8000, 4000];
  const lastPassivita = [0, 1000, 1001, 1002, 2000, 2001, 3000, 3001, 3002, 4000, 4001, 5000, 5001, 6000, 6001, 6002];
  const totaliPassivita = [6001, 6002, 3002];

  const noImportoAttivita = [0,1001, 2001, 4001, 5001, 5002, 6001, 8001, 9001];
  const noImportoPassivita = [0, 1001, 1002, 2001, 4000, 4001, 5001];

  
  const [data, setData] = useState(dati);

  useEffect(() => {
    setData(dati);
  }, [dati]);

  
  const [completo, setCompleto] = useState(1);

    function radioSelection(){
      if(completo === 1){
        setCompleto(2);
      } else {
        setCompleto(1);
      }
    }


    //selezione anni
  const [anniSelezionati, setAnniSelezionati] = useState(anni);

    function changeAnni(e){
      
      const valoreCheckbox = parseInt(e.target.value, 10);
      const isChecked = e.target.checked;

      setAnniSelezionati(prevAnni => {
        let nuovoArray;
        if (isChecked) {
          
          nuovoArray = [...prevAnni, valoreCheckbox];
        } else {
          
          nuovoArray = prevAnni.filter(valore => valore !== valoreCheckbox);
        }

        
        return nuovoArray.sort((a, b) => b - a);
      });

    }

    //see year
    const [seeYear, setSeeYear] = useState(false);
    function handleSeeYear(){
      setSeeYear(!seeYear);
    }

    const [seeFilter, setSeeFilter] = useState(false);

    function handleFilter(){
      setSeeFilter(!seeFilter)
    }

    return (
        <div style={{width: '100%'}}>

          <UpperMenu seeFilter={seeFilter}>
            <div className='table-view-btn'>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='icon-container tooltip' onClick={handleFilter}>
                  <span class="tooltiptext">Filtri</span>
                  {seeFilter ? (
                    <FilterOffIcon onClick={handleFilter}/>
                  ) : (
                    <FilterIcon onClick={handleFilter}/>
                  )}
                </div>
                <div style={{marginLeft: '10px'}} className='icon-container tooltip' onClick={radioSelection}>
                  <span class="tooltiptext">Visualizzazione</span>
                  {completo === 2 ? (
                    <ExpandIcon onClick={radioSelection} />
                  ) : (
                    <CompressIcon onClick={radioSelection} />
                  )}
                </div>
                  
              </div>           
            </div>
            <div className='filter-container'>
              <div className='select-anni' style={{width: 'fit-content'}}>
                <div className='year-selection' onClick={handleSeeYear}>
                  <p>Seleziona anni <DownArrow /></p>
                </div>
                <DropDown seeYear={seeYear}>
                  {data && (
                    <div className='dropdown-content'>
                      
                      {data.anni.map((anno, annoIndex) => (
                        <label><input type="checkbox" value={anno} name="anni" checked={anniSelezionati.includes(anno)} onChange={changeAnni}/> {anno}</label>
                      ))}
                    </div>
                  )}
                </DropDown>
              </div>
            </div>
          </UpperMenu>   

          <div style={{display: 'flex', justifyContent: 'center'}}>
          {data ? (
            <div className='scroll'>
              <table className='scroll-table'>
                <thead >
                  <tr className='intestazione'>
                    <th className='block first-column'>Categoria</th>
                    {anniSelezionati.map((item) => (
                      <React.Fragment key={item}>
                        <th className='columnNew'>{item}</th>
                        <th className='columnNew'>% {item}</th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {completo === 1 ? (
                    <> 
                      
                      <tr>
                        
                        <td className="totali" style={{ textAlign: 'center', fontSize: 24, fontWeight: 900 }} colSpan={1000}>Attività</td>
                      </tr>
                      {data.attivita.map((item) => (
                        <tr key={item.id}>
                          {!noImportoAttivita.includes(item.id) ? (
                            <td className={
                              lastAttivita.includes(item.id) && totaliAttivita.includes(item.id) 
                              ? 'block first-column totali' 
                              : totaliAttivita.includes(item.id) 
                              ? 'block first-column' 
                              : lastAttivita.includes(item.id)
                              ? 'block first-column last'
                              : 'block first-column'
                            }>{item.categoria}</td>
                          ) : (
                            <td className='block first-column' style={{fontStyle: 'italic'}}>{item.categoria}</td>
                          )}
      
                          {anniSelezionati.map((anno) => (
                            <React.Fragment key={anno}>
                              {!noImportoAttivita.includes(item.id) ? (
                                <>
                                  <td className={
                                    lastAttivita.includes(item.id) && totaliAttivita.includes(item.id) 
                                    ? 'column totali' 
                                    : totaliAttivita.includes(item.id) 
                                    ? 'column' 
                                    : lastAttivita.includes(item.id)
                                    ? 'column last'
                                    : 'column'
                                  }>
                                    {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    })}
                                  </td>
                                  <td className={
                                    lastAttivita.includes(item.id) && totaliAttivita.includes(item.id) 
                                    ? 'column totali' 
                                    : totaliAttivita.includes(item.id) 
                                    ? 'column' 
                                    : lastAttivita.includes(item.id)
                                    ? 'column last'
                                    : 'column'
                                  }>
                                    {item[`percentuale_${anno}`] != null ? item[`percentuale_${anno}`].toFixed(2)+'%' : ''}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className='column'></td>
                                  <td className='column'></td>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}

                      <tr>
                        <td colSpan={1000}></td>
                      </tr>
                      <tr>
                        
                        <td className="totali" style={{ textAlign: 'center', fontSize: 24, fontWeight: 900 }} colSpan={1000}>Passività</td>
                      </tr>
      
                      {data.passivita.map((item) => (
                        <tr key={item.id}>
                          {!noImportoPassivita.includes(item.id) ? (
                            <td className={
                              lastPassivita.includes(item.id) && totaliPassivita.includes(item.id) 
                              ? 'block first-column totali' 
                              : totaliPassivita.includes(item.id) 
                              ? 'block first-column' 
                              : lastPassivita.includes(item.id)
                              ? 'block first-column last'
                              : 'block first-column'
                           }>{item.categoria}</td>
                          ) :(
                            <td className='block first-column' style={{fontStyle: 'italic'}}>{item.categoria}</td>
                          )}

      
                          {anniSelezionati.map((anno) => (
                            <React.Fragment key={anno}>
                              {!noImportoPassivita.includes(item.id) ? (
                                <>
                                  <td className={
                                    lastPassivita.includes(item.id) && totaliPassivita.includes(item.id) 
                                    ? 'column totali' 
                                    : totaliPassivita.includes(item.id) 
                                    ? 'column' 
                                    : lastPassivita.includes(item.id)
                                    ? 'column last'
                                    : 'column'
                                  }>
                                    {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    })}
                                  </td>
                                  <td className={
                                    lastPassivita.includes(item.id) && totaliPassivita.includes(item.id) 
                                    ? 'column totali' 
                                    : totaliPassivita.includes(item.id) 
                                    ? 'column' 
                                    : lastPassivita.includes(item.id)
                                    ? 'column last'
                                    : 'column'
                                  }>
                                    {item[`percentuale_${anno}`] != null ? item[`percentuale_${anno}`].toFixed(2)+'%' : ''}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className='column'></td>
                                  <td className='column'></td>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {data.attivita.map((item) => (
                        <>
                          {lastAttivita.includes(item.id) && (
                            <React.Fragment key={item.id}>
                              <tr>
                                <td className='block first-column'>{item.categoria}</td>
                                {anniSelezionati.map((anno) => (
                                  <React.Fragment key={anno}>
                                    <td className='column'>
                                      {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                        style: 'currency',
                                        currency: 'EUR'
                                      })}
                                    </td>
                                    <td className='column'>{item[`percentuale_${anno}`] != null ? item[`percentuale_${anno}`].toFixed(2)+'%' : ''}</td>
                                  </React.Fragment>
                                ))}
                              </tr>
                            </React.Fragment>
                          )}
                        </>
                      ))}
      
                      <tr>
                        <td className='spazio-ridotto'></td>
                      </tr>
      
                      {data.passivita.map((item) => (
                        <>
                          {lastPassivita.includes(item.id) && (
                            <React.Fragment key={item.id}>
                              <tr>
                                <td className='block first-column'>{item.categoria}</td>
                                {anniSelezionati.map((anno) => (
                                  <React.Fragment key={anno}>
                                    <td className='column'>
                                      {Number(item[`valore_${anno}`]).toLocaleString('it-IT', {
                                        style: 'currency',
                                        currency: 'EUR'
                                      })}
                                    </td>
                                    <td className='column'>{item[`percentuale_${anno}`] != null ? item[`percentuale_${anno}`].toFixed(2)+'%' : ''}</td>
                                  </React.Fragment>
                                ))}
                              </tr>
                            </React.Fragment>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Caricamento...</p>
          )}
          </div>
        </div>
      );
      
  
}

export default TableStato;

const UpperMenu = styled.div`
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 30px;

  .icon-container{
    border: 1px solid rgba(0,0,0,.5);
    border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
    cursor: pointer;
    color: rgba(0,0,0,.5);
    transition: all .3s linear;
  }

  .icon-container:hover{
    border: 1px solid black;
    color: black;
  }

  .filter-container{
    
    max-height: ${props => (props.seeFilter ? '400px' : '0px')};
    padding:  ${props => (props.seeFilter ? '20px 0px' : '0px')};
    padding-left: 2%;
    padding-right: 2%;
    height: fit-content;
    border: ${props => (props.seeFilter ? '1px solid rgba(0,0,0,.5)' : 'none')};
    overflow: ${props => (props.seeFilter ? 'visible' : 'hidden')};;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    border-radius: 0px 10px 10px 10px;

    transition: max-height 0.3s ease, padding 0.3s ease;
    
  }
`

const FilterIcon = styled(FaFilter)`
  font-size: 20px;
  cursor: pointer;  
`

const CompressIcon = styled(FaCompressAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const ExpandIcon = styled(FaExpandAlt)`
  font-size: 20px;
  cursor: pointer;  
`
const FilterOffIcon = styled(MdFilterAltOff)`
  font-size: 24px;
  cursor: pointer;  
`

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;

  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  z-index: 1;
  margin-top: 5px;

  max-height: ${({ seeYear }) => (seeYear ? '500px' : '0px')};
  overflow: hidden;
  padding: 0px 10px;
  border: none;
  
  .dropdown-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px;

    label{
      padding: 5px 0px;
      font-size: 20px;
    }
  }

  transition: all .5s linear;
`;


const DownArrow = styled(IoIosArrowDown)`
  font-size: 18px;
  margin-left: 5px;
`

const Choice = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2%;
  .switch-view{
    width: 80px;
    background-color: rgba(0,0,0,.2);
    border-radius: 20px;
    height: 25px;
    margin: 0px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: ${({completo}) => ((completo === 1) ? 'flex-start' : 'flex-end')};
  }

  .switch-btn{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #213940;
    cursor: pointer;
    margin: 0px 3px;
  }


`

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NumPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul{
    list-style: none;
    padding: 0;

  }

  ul li{
    display: inline;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    margin: 0px 5px;
    border-radius: 10px;
    aspect-ratio: 1/1;
    width: fit-content;
    height: fit-content;

    &:hover{
      background-color: #213940;
      transition: all .3s linear;
      color: white;
    }

    .active{
      background-color: #213940;
      color: white;
    }
  }
`