import React, {useState} from 'react'

import styled from "styled-components"

import axios from "axios";

//icon
import { TiTick } from "react-icons/ti";



function CategoriaStato({macro, close, ricarica, categorie}) {

    const [correct, setCorrect] = useState(false);

    //categoria
    const [choice, setChoice] = useState("seleziona");
    function handleChoice(e){
        setChoice(e.target.value);
    }

    //aggiunta categoria
    const [nuovaCategoria, setNuovaCategoria] = useState({
        macro: 'seleziona',
        nuovoNome: '',
        tipo: 'aggiungi'
    });
    
    function handleNuovaCategoria(e) {
        const value = e.target.value;
        const name = e.target.name;
    
        setNuovaCategoria(prevNuovaCategoria => ({
            ...prevNuovaCategoria,
            [name]: value
        }));
    }

    async function handleClickNuova() {
        setCorrect(true);
    
        try {
            const response = await fetch('https://www.app.nauticsm.it/categoria-stato', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(nuovaCategoria),
            });
    
            if (!response.ok) {
                throw new Error(`Errore HTTP! Status: ${response.status}`);
            }
            response.json() // Puoi anche rimuovere questa parte se il corpo della risposta non è importante
              .then(data => {
                setTimeout(() => {
                    close();
                    ricarica();
                }, 1000)
              })
              .catch(error => {
                console.error('Errore durante la gestione della risposta:', error);
              });
        } catch (error) {
            console.error('Errore durante l\'invio dei dati al server:', error);
        }
    }
     

    //elimina categoria
    const [eliminaCategoria, setEliminaCategoria] = useState({
        macro: 'seleziona',
        tipo: 'elimina'
    });
    
    function handleEliminaCategoria(e) {
        const value = e.target.value;
        const name = e.target.name;
    
        setEliminaCategoria(prevNuovaCategoria => ({
            ...prevNuovaCategoria,
            [name]: value
        }));
    }

    async function handleClickElimina() {
        setCorrect(true);
    
        try {
            const response = await fetch('https://www.app.nauticsm.it/categoria-stato', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eliminaCategoria),
            });
    
            if (!response.ok) {
                throw new Error(`Errore HTTP! Status: ${response.status}`);
            }
            response.json() // Puoi anche rimuovere questa parte se il corpo della risposta non è importante
              .then(data => {
                setTimeout(() => {
                    close();
                    ricarica();
                }, 1000)
              })
              .catch(error => {
                console.error('Errore durante la gestione della risposta:', error);
              });
        } catch (error) {
            console.error('Errore durante l\'invio dei dati al server:', error);
        }
    }

    //modifica categoria
    const [modificaCategoria, setModificaCategoria] = useState({
        macro: 'seleziona',
        nuovoNome: '',
        tipo: 'modifica'
    });
    
    function handleModificaCategoria(e) {
        const value = e.target.value;
        const name = e.target.name;
    
        setModificaCategoria(prevNuovaCategoria => ({
            ...prevNuovaCategoria,
            [name]: value
        }));
    }

    async function handleClickModifica() {
        setCorrect(true);
    
        try {
            const response = await fetch('https://www.app.nauticsm.it/categoria-stato', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(modificaCategoria),
            });
    
            if (!response.ok) {
                throw new Error(`Errore HTTP! Status: ${response.status}`);
            }
            response.json() // Puoi anche rimuovere questa parte se il corpo della risposta non è importante
              .then(data => {
                setTimeout(() => {
                    close();
                    ricarica();
                }, 1000)
              })
              .catch(error => {
                console.error('Errore durante la gestione della risposta:', error);
              });
        } catch (error) {
            console.error('Errore durante l\'invio dei dati al server:', error);
        }
    }

  return (
    <>
        <Choice>
            <select name="tipo" onChange={handleChoice}>
                <option disabled selected value="seleziona">- Seleziona -</option>
                <option value="Aggiungi">Aggiungi</option>
                <option value="Elimina">Elimina</option>
                <option value="Modifica">Modifica</option>
            </select>
        </Choice>
        {correct ? (<TickContainer>
            <InnerContainer><Tick /></InnerContainer>            
        </TickContainer>) :(
            <>
                {choice === 'seleziona' && (
            
                    <Initial>Seleziona una voce dal menu in alto a sx</Initial>
                    
                )}
                {choice === 'Aggiungi' && (
                    <React.Fragment>
                        <div className='popup-title'>
                            <h3>Aggiungi Categoria</h3>
                        </div>
                        <div className='popup-form'>
                            <label>Seleziona macrocategoria:</label>
                            <select name="macro" onChange={handleNuovaCategoria}>
                                <option disabled selected value="seleziona">- Seleziona -</option>
                                {macro
                                    .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                    .map((item) => (
                                        <option value={item} key={item}>{item}</option>
                                    ))
                                }
                            </select>
                            <br /><br />
                            <label>Inserisci nome categoria:</label>
                            <input type="text" name="nuovoNome" placeholder='Nome categoria' onChange={handleNuovaCategoria}/>
                        </div>
                        <div className='send-button'>
                            <button id="aggiungi" onClick={handleClickNuova}>Aggiungi</button>
                        </div>
                    </React.Fragment>
                )}
                {choice === 'Elimina' && (
                    <React.Fragment>
                        <div className='popup-title'>
                            <h3>Elimina Categoria</h3>
                        </div>
                        <div className='popup-form'>
                            <label>Seleziona categoria:</label>
                            <select name="macro" onChange={handleEliminaCategoria}>
                                <option disabled selected value="seleziona">- Seleziona -</option>
                                {categorie
                                    .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                    .map((item) => (
                                        <option value={item} key={item}>{item}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='send-button'>
                            <button id="elimina" onClick={handleClickElimina}>Elimina</button>
                        </div>
                    </React.Fragment>
                )}
                {choice === 'Modifica' && (
                    <React.Fragment>
                        <div className='popup-title'>
                            <h3>Modifica Categoria</h3>
                        </div>
                        <div className='popup-form'>
                            <label>Seleziona categoria:</label>
                            <select name="macro" onChange={handleModificaCategoria}>
                                <option disabled selected value="seleziona">- Seleziona -</option>
                                {categorie
                                    .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                    .map((item) => (
                                        <option value={item} key={item}>{item}</option>
                                    ))
                                }
                            </select>
                            <br /><br />
                            <label>Inserisci nuovo nome:</label>
                            <input type="text" name="nuovoNome" placeholder='Stipendio' onChange={handleModificaCategoria}/>
                        </div>
                        <div className='send-button'>
                            <button id="modifica" onClick={handleClickModifica}>Modifica</button>
                        </div>
                    </React.Fragment>
                )}
            </>
        )}  
        
    </>
  )
}

export default CategoriaStato;


const Choice = styled.div`
  padding-left: 2%;
  padding-top: 20px;
  margin-bottom: 10px;
  select{
    border: 1px solid black;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
  }
`

const Initial = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
  text-align: center;
`

const Tick = styled(TiTick)`
    font-size: 100px;
    color: white;
`

const TickContainer = styled.div`
    
    width: 100%;
    min-height: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled.div`
    
    background-color: green;
    border-radius: 50%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    
`