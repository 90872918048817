import React, {useState, useEffect} from 'react'

import styled from "styled-components";
import axios from "axios";

//components
import TopMenu from '../InnerComponents/TopMenu';
import RatingTable from './RatingTable';

//icon
import { IoMdClose } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FaFilter } from "react-icons/fa";
import { FaCompressAlt } from "react-icons/fa";
import { FaExpandAlt } from "react-icons/fa";
import { FaInfo } from "react-icons/fa";
import { MdFilterAltOff } from "react-icons/md";


function Rating({username, password}) {

    

    //caricamento dati
    const [data, setData] = useState(null);
    const [anni, setAnni] = useState(null);
    const [scoring, setScoring] = useState(null);
    const [anniSelezionati, setAnniSelezionati] = useState(null);
    const [checkedDivisioni, setCheckedDivisioni] = useState(["Tutto"]);
    const [unitList, setUnitList] = useState(null);

    async function fetchData(){

        const data = {
            divisioni: checkedDivisioni
        }

        const response = await axios.post('https://www.app.nauticsm.it/rating', data);
        
        setData(response.data.rating);
        setAnni(response.data.anni);
        setScoring(response.data.scoring);
        setUnitList(response.data.unit);
        setAnniSelezionati(response.data.anni);
    };



    

    //legend

    const [seeLegend, setSeeLegend] = useState(false);

    function handleLegend(){
        setSeeLegend(!seeLegend);
        setSeeFilter(false);
    }

    const [radio, setRadio] = useState(1);
    function handleSelectionRadio(){
        if(radio === 1){
            setRadio(2);
        }else{
            setRadio(1);
        }
    }

    //anni selezionati
    

    function handleAnni(e){
        const valoreCheckbox = parseInt(e.target.value, 10);
        const isChecked = e.target.checked;

        setAnniSelezionati(prevAnni => {
        let nuovoArray;
        if (isChecked) {
            
            nuovoArray = [...prevAnni, valoreCheckbox];
        } else {
            
            nuovoArray = prevAnni.filter(valore => valore !== valoreCheckbox);
        }

        
        return nuovoArray.sort((a, b) => b - a);
        });
    }


    const [seeDivisioni, setSeeDivisioni] = useState(false);
    function handleLegendDivisioni(){
        setSeeDivisioni(!seeDivisioni);
        
    }

  function handleChangeDivisioni(event){
    const value = event.target.value;
    
    if(value !== 'Tutto' && checkedDivisioni.includes("Tutto")){
      const initialValue = "Tutto";
      const filteredDivisioni = checkedDivisioni.filter(item => item !== initialValue);
      setCheckedDivisioni([...filteredDivisioni, value]);

    } else if(value === "Tutto"){
      setCheckedDivisioni([value]);
    } else {
      if (checkedDivisioni.includes(value)) {
        setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      } else {
        setCheckedDivisioni([...checkedDivisioni, value]);
      }
    }
  };

  function handleRemoveDivisioni(item){
    const value = item;
    const initialValue = "Tutto";
    console.log(checkedDivisioni.length);
    if(checkedDivisioni.length === 1){
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      setCheckedDivisioni([initialValue]);
    }else {
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
    }

  };

    useEffect(() => {
        fetchData();
    },[checkedDivisioni]);

    //see year
  const [seeYear, setSeeYear] = useState(false);
  function handleSeeYear(){
    setSeeYear(!seeYear);
  }

  //see filter
  const [seeFilter, setSeeFilter] = useState(false);

  function handleFilter(){
    setSeeFilter(!seeFilter);
    setSeeLegend(false);
    setSeeDivisioni(false);
  }

  return (
    <div>
        <TopMenu name="Rating" username={username}/>
        {unitList && (
            <UpperMenu seeFilter={seeFilter} seeLegend={seeLegend}>
                <div style={{display: 'flex', alignItems: 'center'}}> 
                    <div className='icon-container tooltip' onClick={handleFilter}>
                        <span class="tooltiptext">Filtri</span>
                        {seeFilter ? (
                            <FilterOffIcon onClick={handleFilter}/>
                        ) : (
                            <FilterIcon onClick={handleFilter}/>
                        )}
                    </div>
                    <div className='icon-container tooltip' style={{margin: '0px 10px'}} onClick={handleSelectionRadio}>
                        <span class="tooltiptext">Visualizzazione</span>
                        {radio === 2 ? (
                            <ExpandIcon onClick={handleSelectionRadio}/>
                        ) : (
                            <CompressIcon onClick={handleSelectionRadio}/>
                        )}
                    </div>
                    <div className='icon-container tooltip' onClick={handleLegend}>
                        <span class="tooltiptext">Legenda</span>
                        <InfoIcon onClick={handleLegend}/>
                    </div>
                </div>
                <div className='filter-container'>
                    <Divisioni seeDivisioni={seeDivisioni}>
                        <button className="divisioni-title" onClick={handleLegendDivisioni}>Divisioni Aziendali <DownArrow /></button>
                        <div className='divisioni-aziendali'>
                            <label><input style={{marginTop: "15px"}} onChange={handleChangeDivisioni} checked={checkedDivisioni.includes("Tutto")}  value="Tutto" type="checkbox"/> Tutto</label><br/>
                            {unitList.map((item, index) => (
                                <><label ><input checked={checkedDivisioni.includes(item)} onChange={handleChangeDivisioni} style={{ marginBottom: (index === unitList.length - 1) ? '15px' : '0' }} value={item} type="checkbox" /> {item}</label><br/></>
                            ))}
                            <div className='spuntate'>
                                {checkedDivisioni.map((item) => (
                                    <p className='check'>{item} <Delete onClick={() => handleRemoveDivisioni(item)}/></p>
                                ))}
                            </div>
                        </div>
                        
                    </Divisioni>
                    <div className='select-anni'>
                        <div className='year-selection' onClick={handleSeeYear}>
                            <p>Seleziona anni <DownArrow /></p>
                        </div>

                        <DropDown seeYear={seeYear}>
                            {anni && (
                                <div className='dropdown-content'>
                                    {anni.map((item) => (
                                        <label><input type="checkbox" value={item} checked={anniSelezionati.includes(item)} onChange={handleAnni} style={{marginRight: '5px'}}/>{item}</label>
                                    ))}
                                </div>
                            )}
                        </DropDown>
                    </div>
                </div>
                
                    <Legend seeLegend={seeLegend}>
                        <div className='inner-legend'>
                            <ul>
                                <li>Rischio molto basso</li>
                                <li>Rischio basso </li>
                                <li>Rischio medio basso</li>
                            </ul>
                            <ul>
                                <li><div className='low'>AAA</div></li>
                                <li><div className='low'>AA</div></li>
                                <li><div className='low'>A</div></li>
                            </ul>
                        </div>
                        <div className='inner-legend'>
                            <ul>
                                <li>Rischio medio</li>
                                <li>Rischio medio alto </li>
                                
                            </ul>
                            <ul>
                                <li> <div className='medium'>BBB</div></li>
                                <li><div className='medium'>BB</div></li>
                            </ul>
                        </div>
                        <div className='inner-legend'>
                            <ul>
                                <li>Rischio alto </li>
                                <li>Default </li>
                            </ul>
                            <ul>
                                <li><div className='high'>B</div></li>
                                <li><div className='high'>CCC</div></li>
                            </ul>
                        </div>
                    </Legend>
                
            </UpperMenu>
        )}
        

        <Center>
            <RatingTable dati={data} anni={anniSelezionati} scoring={scoring} radio={radio} anniCompleti={anni}/>
        </Center>
    </div>
  )
}

export default Rating;

const FilterIcon = styled(FaFilter)`
  font-size: 20px;
  cursor: pointer;  
`

const CompressIcon = styled(FaCompressAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const ExpandIcon = styled(FaExpandAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const InfoIcon = styled(FaInfo)`
  font-size: 20px;
  cursor: pointer;  
`


const UpperMenu = styled.div`
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 30px;
    
    .filter-container{
        max-height: ${props => (props.seeFilter ? '400px' : '0px')};
        padding:  ${props => (props.seeFilter ? '20px 0px' : '0px')};
        padding-left: 2%;
        padding-right: 2%;
        height: fit-content;
        border: ${props => (props.seeFilter ? '1px solid rgba(0,0,0,.5)' : 'none')};
        overflow: ${props => (props.seeFilter ? 'visible' : 'hidden')};;
        box-shadow: 0px 0px 10px rgba(0,0,0,.2);
        border-radius: 0px 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: max-height 0.3s ease, padding 0.3s ease;
        
    }

  .icon-container{
    border: 1px solid rgba(0,0,0,.5);
    border-bottom: ${props => (props.seeFilter || props.seeLegend ? 'none' : '1px solid rgba(0,0,0,.5)')};
    width: fit-content;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: ${props => (props.seeFilter || props.seeLegend ? '5px 5px 0px 0px' : '5px')};
    cursor: pointer;
    color: rgba(0,0,0,.5);
    transition: all .3s linear;
  }

  .icon-container:hover{
    border: 1px solid black;
    color: black;
  }
`

const DropDown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;

  border-radius: 10px;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;

  max-height: ${({ seeYear }) => (seeYear ? '500px' : '0px')};
  overflow: hidden;
  padding: 0px 10px;
  border: none;
  
  .dropdown-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px;

    label{
      padding: 5px 0px;
      font-size: 20px;
    }
  }

  transition: all .5s linear;
`;




const Delete = styled(IoMdClose)`
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 2px;
`

const DownArrow = styled(IoIosArrowDown)`
  font-size: 18px;
  margin-left: 5px;
`


const Divisioni = styled.div`
    width: fit-content;
    
  .spuntate{
    display: flex;
    align-items: center;
    margin-top: ${({seeDivisioni}) => (seeDivisioni ? '10px' : '0px')};
    margin-bottom: 10px;
    max-width: 300px;
    flex-wrap: wrap;
    height: fit-content;

    p{
      margin: 3px 0px;
    }
  }

  .spuntate .check{
    background-color: lightgrey;
    margin-right: 8px;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .divisioni-title{
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0,0,0,.5);
    padding: 5px 20px;
    color: rgba(0,0,0,.5);
    background-color: white;
    transition: all .3s linear;

  }

  .divisioni-title:hover{
    color: black;
    border: 1px solid black;
  }

  .divisioni-aziendali{
    position: absolute;
    background-color: white;
    z-index: 9998;
    max-height: ${({seeDivisioni}) => (seeDivisioni ? '400px' : '0')};
    height: fit-content;
    box-shadow: 0px 0px 3px rgba(0,0,0,.4);
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    width: fit-content;
    margin-top: 3px;
    font-size: 18px;
    padding: 0px 50px 0px 10px;
  }

  label, input{
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }


  
`


const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const Choice = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;

    .legend-title{
        font-size: 20px;
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        align-items: center;
        transition: all .3s linear;
    }
    
    .legend-title:hover{
        color: rgba(0,0,0,.6);
        
    }

    div label{
        margin: 0px 5px;
        
    }

    div label:nth-child(2), div label:nth-child(3){
        cursor: pointer;
    }

    div input{
        cursor: pointer;
    }

    .right-rating{
        display: flex;
        align-items: center;

    }

    .anni-rating{
        margin-right: 20px;

        p{
            font-size: 18px;
            margin-right: 10px;
        }

        display: flex;
        align-items: center;

        label{
            margin: 0px 8px;
            font-size: 18px;
            cursor: pointer;
        }

        input{
            margin-right: 3px;
            font-size: 18px;
            cursor: pointer;
        }
    }

    .switch-view{
        width: 80px;
        background-color: rgba(0,0,0,.2);
        border-radius: 20px;
        height: 25px;
        margin: 0px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: ${({radio}) => ((radio === 1) ? 'flex-start' : 'flex-end')};
    }

    .switch-btn{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #213940;
        cursor: pointer;
        margin: 0px 3px;
    }

    .radio-btn{
        display: flex;
        align-items: center;
        justufy-content: flex-start;
    }
    
`


const Legend = styled.div`
    
    height: auto;
    max-height: ${({seeLegend}) => (seeLegend ? '200px' : '0')};
    transition: max-height 0.5s ease-in-out;
    width: fit-content;
    border-radius: 0px 10px 10px 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,.2);
    display: flex;
    border: ${({seeLegend}) => (seeLegend ? '1px solid rgba(0,0,0,.2)' : 'none')}; 
    

    overflow: hidden;

    .inner-legend ul{
        list-style: none;
        padding-left: 0;
        
    }

    .inner-legend:nth-child(2){
        margin: 10px 30px;
    }

    .inner-legend{
        display: flex;
        margin: 10px 0px;
    }

    .inner-legend:nth-child(1){
        margin-left: 20px;
    }

    .inner-legend:nth-child(3){
        margin-right: 20px;
    }

    .inner-legend ul:nth-child(2){
        margin-left: 10px;
    }

    .inner-legend ul li {
        padding: 5px 0px;
        font-size: 18px;
    }

    .inner-legend ul:nth-child(2) li div{
        width: 60px;
        display: flex;
        justify-content: center;
        border-radius: 5px;
    }

    .low{
        background-color: lightgreen;

    }

    .medium{
        background-color: orange;
    }

    .high{
        background-color: red;
    }
`

const FilterOffIcon = styled(MdFilterAltOff)`
  font-size: 24px;
  cursor: pointer;  
`