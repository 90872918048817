import React, {useEffect, useState} from 'react'

import axios from "axios"

//component
import TopMenu from '../InnerComponents/TopMenu'

import styled from "styled-components"

function AndamentoBudget({username}) {

    const [data, setData] = useState(null);

    async function getData(){

        const response = await axios.get("https://www.app.nauticsm.it/andamento-budget");

        setData(response.data);
        console.log(response.data);
    }

    useEffect(() => {
        getData();
    }, [])

  return (
    <Container>
        <TopMenu name="Andamento Budget" username={username}/>

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh'}}>
            {data ? (
                <div className='scroll'>
                    <table className='scroll-table'>
                        <thead>
                            <tr className='intestazione'>
                                <th className='block first-column'>Categoria</th>
                                <th className='columnNew'>Anno precedente</th>
                                <th className='columnNew'>Annuale Budget</th>
                                <th className='columnNew'>Progresso</th>
                                <th className='columnNew'>Progresso Budget</th>
                                <th className='columnNew'>C.E. attuale</th>
                                <th className='columnNew'>Vs Budget Anno</th>
                                <th className='columnNew'>Vs Budget in Progresso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr>
                                    {item.id === 1 || item.id === 3 ? (
                                        <td className='block first-column'>{item.categoria}</td>
                                    ) : (
                                        <td className='block first-column'>{item.categoria.replace('Totale', '').trim()}</td>
                                        
                                    )}
                                    
                                    <td className='column'>{Number(item.valore_precedente).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                    })}</td>

                                    <td className='column'>{Number(item.annuale_budget).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                    })}</td>

                                    {item.percentuale_budget !== null ? (
                                        <td className='column'>{item.percentuale_budget.toFixed(2)}%</td>
                                    ) : (
                                        <td className='column'>/</td>
                                    )}

                                    {item.progresso_budget !== null ? (
                                        <td className='column'>{Number(item.progresso_budget).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>
                                    ) : (
                                        <td className='column'>/</td>
                                    )}

                                    <td className='column'>{Number(item.attuale).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>

                                    <td className='column'>{Number(item.vs_budget).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>

                                    {item.vs_budgetprogresso !== null ? (
                                        <td className='column'>{Number(item.vs_budgetprogresso).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>
                                    ) : (
                                        <td className='column'>/</td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (<p>Caricamento...</p>)}
        </div>
    </Container>
  )
}

export default AndamentoBudget;

const Container = styled.div`

    table th{
        padding: 0px 20px;
    }

`