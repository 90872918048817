import React, {useState} from 'react';

//style
import "./innerStyles/BusinessPopup.css";

import { IoMdClose } from "react-icons/io";
//other
import styled from "styled-components";

//component
import Correct from "./Correct.js";

function BusinessPopup({closePopup, tipo, business, id, ricarica }) {

  
  //checked
  const [checked, setChecked] = useState({});

  function handleChecked(id, unit){
    setChecked(prevOggetto => {
      console.log(prevOggetto);
      if (prevOggetto[id]) {
        
        const { [id]: _, ...nuovoOggetto } = prevOggetto;
        return nuovoOggetto;
      } else {
        
        return {
          ...prevOggetto,
          [id]: unit
        };
      }
    });
  }

  
  //dati da inviare
  const [dataUnit, setDataUnit] = useState({});

  function handleUnit(unit, e){
    
    setDataUnit(prevOggetto => ({
      ...prevOggetto,
      [unit]: e.target.value
    }));

    
  }

  

  //send data
  async function handleClick(){
    
    const datiDaInviare = {
      id: id,
      unit: dataUnit,
      tipo: tipo
    };

    setTick(!tick);

    setTimeout(() => {
      setTick(false);
      closePopup();
    }, 2000);

    try {
      const response = await fetch('https://www.app.nauticsm.it/business-unit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datiDaInviare),
      });
    
      if (response.ok) {
        
        ricarica();
      } else {
        console.error('Errore nella richiesta:', response.statusText);
      }
    
    } catch (error) {
      console.error('Errore nella richiesta:', error);
    }
  }

  function handleClose(){
    closePopup();
  }

  const [tick, setTick] = useState(false);

  

  return (
    <Opacity>
        <Popup>
            {tick ? (
              <Correct />
            ) : (
              <React.Fragment>
                <div style={{padding: '20px 80px 20px 25px'}}>
                  <div className='title-unit'>
                  <h2>Seleziona Business Unit</h2>
                  <CloseIcon onClick={handleClose}/>
                  </div>
                  <div className='business-options'>
                      {business.map((item) => (
                      <div key={item.id} className='business-option'>

                        <label class="container">
                          <input type="checkbox" onChange={() => handleChecked(item.id, item.unit)} name={item.unit}/>
                          <span class="checkmark"></span>
                          <p>{item.unit}</p>
                          
                        </label>
                      </div>
                      ))}
                  </div>

                  <div className='percent'>

                    {Object.keys(checked).map(key => (
                      <div className='input-percent'>
                        <label>
                          <input onChange={(e) => handleUnit(checked[key], e)} style={{padding: '5px 10px'}} type="number" step="5" min="0" max="100"/>
                          <p>% {checked[key]}</p>
                        </label><br/>
                      </div>
                    ))}           

                  </div>
                </div>
                

                <div className='addBtn'>
                  <button id="aggiungi" onClick={handleClick}>Assegna</button>
                </div>
              </React.Fragment>
            )}
        </Popup>

    </Opacity>
  )
}

export default BusinessPopup;

const Popup = styled.div`
 position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  min-height: 30vh;
  
  background-color: white;
  z-index: 9999;
  border-radius: 10px;

  .business-options{
    margin-top: 5vh
  }
  .business-option label{
    padding: 0 20px;
    cursor: pointer;
  }

  .business-option input{
    margin-right: 5px;
  }

  .addBtn{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .addBtn button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

  .input-percent label, .input-percent p{
    font-size: 18px;
  }

  .input-percent input{
    font-size: 18px;
    border: 1px solid black;
  }

`

const Opacity = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`


const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`