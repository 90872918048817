import React, {useState} from 'react'

import styled from "styled-components";

//icon
import { IoMdClose } from "react-icons/io";

import CategoriaStato from './CategoriaStato';
import ImportoStato from './ImportoStato';

function StatoPopup({close, tipo, macro, ricarica, categorie, anni}) {

    //close
    function handleClose(){
        close();
    }

    function handleRicarica(){
      
      ricarica();
    }

  return (
    <Popup>
        <InnerPopup>
            <CloseIcon onClick={handleClose}/>
            {tipo === "categoria" && (
                <CategoriaStato macro={macro} close={close} ricarica={handleRicarica} categorie={categorie}/>
            )}
            {tipo === 'importo' && (
              <ImportoStato close={close} ricarica={handleRicarica} categorie={categorie} anni={anni}/>
            )}
        </InnerPopup>
    </Popup>
  )
}

export default StatoPopup;


const Popup = styled.div`
  position: fixed;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`

const InnerPopup = styled.div`
  position: fixed;
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  border-radius: 10px;
  width: fit-content;
  
  min-width: 30%;
  min-height: 30vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 50px 0px 25px;

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
  }



  .popup-form{
    
    margin-top: 20px;
  }

  .popup-form input{
    border: 1px solid black;
    font-size: 18px;
    padding: 3px 8px;
  }

  .popup-form label{
    margin-right: 20px;
    font-size: 18px;
  }

  .popup-form select{
    font-size: 18px;
    border: 1px solid black;
    cursor: pointer;
  }

  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

`

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`
