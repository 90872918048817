import React, {useState} from 'react'
import styled from "styled-components";


//icon
import { IoMdClose } from "react-icons/io";

function VenditePopup({currentMonth, currentYear, fatture, close}) {

    function handleClose(){
        close();
    }

    // ricerca input
    const [inputValue, setInputValue] = useState(null);
    function handleInputSearch(e){
      const {value} = e.target;

      setInputValue(value);
    }
  return (
    <Popup>
        <InnerPopup>
            <CloseIcon onClick={handleClose}/>
            <div className='importi-title'>
                <h3>Fatture {currentMonth} del {currentYear}</h3>
                <input type="text" placeholder='Cerca per cliente...' onChange={handleInputSearch}/>
            </div>
            <div className="table-container" style={{ maxHeight: '400px', overflowY: 'auto'}}>
                <table>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>N.</th>
                            <th>Cliente</th>
                            <th>Importo (IVA escl.)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fatture.map((item) => (
                            <>
                              {inputValue && inputValue.length > 0 ? (
                                <>
                                  {item.companyName.toLowerCase().includes(inputValue.toLowerCase()) && (
                                    <tr>
                                      <td>{new Date(item.docDate).toLocaleDateString('it-IT')}</td>
                                      <td>{item.docNumber}</td>
                                      <td>{item.companyName}</td>
                                      {item.docTypeDescShort !== 'NCV' ? (
                                          <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
                                              style: 'currency',
                                              currency: 'EUR',
                                          })}</td>
                                      ) : (
                                          <td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
                                              style: 'currency',
                                              currency: 'EUR',
                                          })}</td>
                                      )}
                                  </tr>
                                  )}
                                </>
                              ) : (
                                <tr>
                                  <td>{new Date(item.docDate).toLocaleDateString('it-IT')}</td>
                                  <td>{item.docNumber}</td>
                                  <td>{item.companyName}</td>
                                  {item.docTypeDescShort !== 'NCV' ? (
                                      <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
                                          style: 'currency',
                                          currency: 'EUR',
                                      })}</td>
                                  ) : (
                                      <td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
                                          style: 'currency',
                                          currency: 'EUR',
                                      })}</td>
                                  )}
                              </tr>
                              )}
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </InnerPopup>
    </Popup>
  )
}

export default VenditePopup;

const Popup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`
const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`
const InnerPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  padding: 0 50px;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;

  .table-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    
  }
  table{
    width: fit-content;
    min-width: 700px;
  }

  table td, table th{
    padding: 5px 0px;
    padding-right: 25px;
    font-size: 18px;
  }

  table thead{
    border-bottom: 2px solid black;
    text-align: left;
    
  }

  .importi-title{
    width: 100%;
    
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .importi-title h3{
  
    font-size: 24px;
    margin-right: 30px;
  }

  .importi-title input{
    margin-right: 10px;
    border: 1px solid black;
    font-size: 18px;
    border-radius: 10px;
    padding: 5px 0px 5px 5px;
  }
`