import React, { lazy, Suspense, useState, useEffect} from 'react'

import TopMenu from './TopMenu'

//style
import "./innerStyles/FattureSDI.css";
import styled from "styled-components";

//other
import axios from "axios";
import debounce from 'lodash/debounce';
import BusinessPopup from './BusinessPopup';
import BusinessUnit from "./BusinessUnit";
import PresetAziende from './PresetAziende';
import Preset from './Preset';
import { Slide } from 'react-reveal';

//icon
import { IoMdClose } from "react-icons/io";
import { FaTrashCan } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FaExpandAlt } from "react-icons/fa";
import { FaCompressAlt } from "react-icons/fa";
import { PiProhibitBold } from "react-icons/pi";


function FattureSDI() {

  const [fatture, setFatture] = useState(null);
  const [acquisti, setAcquisti] = useState(null);
  const [listaPreset, setListaPreset] = useState(null);

  //categorie assegnate
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryAcquisti, setCategoryAcquisti] = useState(null);

  const [listaCategorie, setListaCategorie] = useState(null);

  //note
  const [noteVendite, setNoteVendite] = useState([]);
  const [noteAcquisti, setNoteAcquisti] = useState([]);

  //business unit
  const [businessUnit, setBusinessUnit] = useState(null);
  const [showBusinessPopup, setShowBusinessPopup] = useState(false);

  //anni
  const [anni, setAnni] = useState([]);

  //messaggio in corso
  const [visualizzaMessaggio, setVisualizzaMessaggio] = useState(false);
  const [contenutoMessaggio, setContenutoMessaggio] = useState(null);
  const [idInteressato, setIdInteressato] = useState(null);
  

  //fatture

  async function fetchData() {
    try {
      
      const response = await axios.get('https://www.app.nauticsm.it/fatture-sdi');
    
      setFatture(response.data.fatture);
      setSelectedCategory(response.data.categorie); 
      setListaCategorie(response.data.listaCategorie); 
      setListaPreset(response.data.preset);
      setNoteVendite(response.data.categorie);  
      setBusinessUnit(response.data.business);
      setAnni(response.data.anni);     
      
    } catch (error) {
      console.error('Errore durante il recupero dell\'oggetto:', error);
    }
  }

  //acquisti
  async function fetchDataAcquisti() {
    try {
      
      const response = await axios.get('https://www.app.nauticsm.it/fatture-acquisti');
      
      setAcquisti(response.data.acquisti);
      setCategoryAcquisti(response.data.categorie);
      setNoteAcquisti(response.data.categorie);

      
      
    } catch (error) {
      console.error('Errore durante il recupero dell\'oggetto:', error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchDataAcquisti();
  }, []);



  const [activeButton, setActiveButton] = useState(1);
  


  function handleButtonClick(buttonNumber){
    setActiveButton(buttonNumber)
    
  }

  //search
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  //search categoria
  const [searchCategory, setSearchCategory] = useState('');

  const handleSearchCategory = (e) => {
    setSearchCategory(e.target.value);
  };

  useEffect(() => {
    console.log(searchCategory);
  }, [searchCategory])

  //vendite
  const handleSelectionDebounced = debounce(async (e) => {
    const id = e.target.id;
    const categoria = e.target.value;

    setVisualizzaMessaggio(true);
    setContenutoMessaggio('Aggiunta categoria in corso...');
    setIdInteressato(id);

    const dati = {
      id: id,
      categoria: categoria,
      operazione: 'aggiunta-categoria'
    }
   
    axios
    .post('https://www.app.nauticsm.it/fatture-categorie', dati)
    .then(async (response) => {
      
      
      await fetchData();
      await fetchDataAcquisti();
      setVisualizzaMessaggio(false);
      setIdInteressato(null);
      
    })
    .catch(async (error) => {
    
      console.error(error);
    })
    
  }, 300);
  
  const handleSelection = (e) => {
    handleSelectionDebounced(e);
  };



  //acquisti

  const handleSelectionDeb = debounce((e) => {
    const id = e.target.id;
    const categoria = e.target.value;

    const dati = {
      id: id,
      categoria: categoria,
      operazione: 'aggiunta-categoria'
    }
    
    axios
    .post('https://www.app.nauticsm.it/acquisti-categorie', dati)
    .then(async(response) => {
      
      console.log(response.data);
      fetchData();
      fetchDataAcquisti();
    })
    .catch((error) => {
      console.error(error);
    });

  }, 300);  // Aumenta il timeout se necessario

  const handleSelectionAcquisti = (e) => {
    handleSelectionDeb(e);
  };

  //note fatture vendita
  const handleSelectionNoteDB = debounce((e) => {
    const id = e.target.id;
    const note = e.target.value;

    setVisualizzaMessaggio(true);
    setContenutoMessaggio("Aggiunta nota in corso...")
    setIdInteressato(id);

    const dati = {
      id: id,
      note: note,
      operazione: 'aggiunta-nota'
    }
      
    // Esegui la chiamata Axios subito dopo l'aggiornamento di selectedCategory
    axios
    .post('https://www.app.nauticsm.it/fatture-categorie', dati)
    .then(async (response) => {
    
      console.log(response.data);
      await fetchData();
      await fetchDataAcquisti();
      setVisualizzaMessaggio(false);
      setIdInteressato(null);
      
    })
    .catch((error) => {
      console.error(error);
    });
  
      
  }, 300);
  
  const handleSelectionNote = (e) => {
    handleSelectionNoteDB(e);
  };

  //note fatture acquisto
  const handleSelectionNoteAcquisto = debounce((e) => {
    const id = e.target.id;
    const note = e.target.value;

    const dati = {
      id: id,
      note: note,
      operazione: 'aggiunta-nota'
    }
  
    axios
    .post('https://www.app.nauticsm.it/acquisti-categorie', dati)
    .then(async (response) => {
      
      console.log(response.data);
      fetchData();
      fetchDataAcquisti();
    })
    .catch((error) => {
      console.error(error);
    });
  
  }, 300);
  
  const handleSelectionAcquistiNote = (e) => {
    handleSelectionNoteAcquisto(e);
  };



  //radio button
  const [radioSelection, setRadioSelection] = useState('tutte');

  function handleRadioSelection(e){
    setRadioSelection(e.target.id);
    
  }
  useEffect(() => {

  }, [radioSelection])


  const [currentId, setCurrentId] = useState(null);
  const [tipo, setTipo] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(false);
  //business popup assegnazione
  function handleBusiness(id, tipo){
    setShowBusinessPopup(true);
    setSelectedBusiness(false)
    setCurrentId(id);
    setTipo(tipo);
  }

  //vista
  function handleBusinessUnit(id, tipo){
    setSelectedBusiness(true);
    setShowBusinessPopup(false);
    setCurrentId(id);
    setTipo(tipo);
  }

  function closeBusinessUnit(){
    setSelectedBusiness(false);
    setShowBusinessPopup(false);
  }


  //pagine
  const [numPagina, setNumPagina] = useState("24");

  //pagine
  function handlePage(e){
    const stringaAnno = e.target.id.toString();
    setNumPagina(stringaAnno.slice(2));
  }

  //cancella categoria vendite
  const handleCancelDebounced = debounce((item) => {
    
    const id = item.id;

    setVisualizzaMessaggio(true);
    setContenutoMessaggio("Eliminazione categoria in corso...");
    setIdInteressato(id);
    
    const dati = {
      id: id,
      operazione: 'cancella-categoria'
    }

    // Invia la richiesta POST con l'array aggiornato
    axios
      .post('https://www.app.nauticsm.it/fatture-categorie', dati)
      .then(async (response) => {
        
        console.log("ESECUZIONE");
        await fetchData();
        await fetchDataAcquisti();
        setVisualizzaMessaggio(false);
        setIdInteressato(null);
      })
      .catch((error) => {
        console.error(error);
      });
    

 
  }, 300);
  
  const handleCancel = (item) => {
    handleCancelDebounced(item);
  };


  //cancella categoria acquisto
  const handleCancelAcquistiDeb = debounce(async (item) => {
    const id = item.id;

    const dati = {
      id: id,
      operazione: 'cancella-categoria'
    }
    axios
    .post('https://www.app.nauticsm.it/acquisti-categorie', dati)
    .then(async (response) => {
      
      console.log(response.data);
      fetchData();
      fetchDataAcquisti();
    })
    .catch((error) => {
      console.error(error);
    });

        
  }, 500);  // Aumenta il timeout se necessario

  const handleCancelAcquisti = (item) => {
    handleCancelAcquistiDeb(item);
  };


  //cancella note fatture vendita
  const handleCancelNoteDebounce = debounce((item) => {
    const id = item.id;
    
    setVisualizzaMessaggio(true);
    setContenutoMessaggio("Eliminazione nota in corso...");
    setIdInteressato(id);

    const dati = {
      id: id,
      operazione: 'cancella-note'
    }
    
    // Invia la richiesta POST con l'array aggiornato
    axios
    .post('https://www.app.nauticsm.it/fatture-categorie', dati)
    .then(async (response) => {
      
      
      await fetchData();
      await fetchDataAcquisti();
       setVisualizzaMessaggio(false);
      setIdInteressato(null);
    })
    .catch((error) => {
      console.error(error);
    });
    
        
      
  }, 300);
  
  const handleCancelNote = (item) => {
    handleCancelNoteDebounce(item);
  };

  //cancella note fatture acquisto
  const handleCancelNoteAcquistiDebounce = debounce((item) => {
    const id = item.id;
    const dati = {
      id: id,
      operazione: 'cancella-note'
    }
    
    axios
    .post('https://www.app.nauticsm.it/acquisti-categorie', dati)
    .then(async (response) => {
      fetchData();
      fetchDataAcquisti();
    })
    .catch((error) => {
      console.error(error);
    });
  

  }, 300);
  
  const handleCancelNoteAcquisti = (item) => {
    handleCancelNoteAcquistiDebounce(item);
  };




  const displayCategory = (item, cercaCategoria) => {
    if(radioSelection === 'categorizzate'){
      
      if(cercaCategoria){
        if(selectedCategory.some((category) => category.id === item.id && category.categoria !== null && item.docDescription.slice(-2) === numPagina &&  category.categoria.toLowerCase().includes(cercaCategoria.toLowerCase()))){

          return (<tr className='tr-body' key={item.id}>
            <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
            <td>{item.docNumber}</td>
            <td>{item.bupName}</td>
            <td>{item.companyName}</td>
            {item.docTypeDescShort !== 'NCV' ? (
              <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
              })}</td>
            ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>)}
            <td className='td-input'>
            {noteVendite.some((note) => note.id === item.id && note.note !== null) ? ( <div className='categoria-selezionata'>
                <p id={item.id}>{noteVendite.find((note) => note.id === item.id).note}</p>
                <Trash onClick={() => handleCancelNote(item)}/>
              </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionNote} placeholder="Aggiungi nota" type='text' />)}
              
            </td>
            <td>
            {selectedCategory.some((category) => category.id === item.id && category.categoria !== null) ? (
              <div className='categoria-selezionata'>
                <p>{selectedCategory.find((category) => category.id === item.id).categoria}</p>

                {listaPreset.some((azienda) => 
                  azienda.azienda === item.companyName &&
                   
                  !(azienda.eccezioni && azienda.eccezioni.split(',').map(e => e.trim()).includes(item.docNumber))
                ) ? (
                  <ProhibitedIcon style={{ cursor: 'not-allowed' }}/>
                ) : (
                  <Cancel style={{ cursor: idInteressato === item.id ? 'not-allowed' : 'pointer' }} onClick={() => handleCancel(item)}/>
                )}

              </div>
            ) : (
              <select name="categoria" id={item.id} onChange={handleSelection}>
                <option disabled selected value="Seleziona">Seleziona una categoria</option>
                {listaCategorie.map((opzione) => (
                  <option key={opzione} value={opzione}>
                    {opzione}
                  </option>
                ))}
              </select>
            )}
            </td>
            <td>
            {selectedCategory.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  selectedCategory.some((category) => category.id === item.id && category.percentuale_businessunit.split(',').map(str => parseFloat(str.trim())).reduce((acc, num) => acc + num, 0) == 100 ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "vendite")}>Assegna</p>
            )}
            
          </td>
  
          </tr>);
        }
      } else {
        if(selectedCategory.some((category) => category.id === item.id && category.categoria !== null && item.docDescription.slice(-2) === numPagina)){

          return (<tr className='tr-body' key={item.id}>
            <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
            <td>{item.docNumber}</td>
            <td>{item.bupName}</td>
            <td>{item.companyName}</td>
            {item.docTypeDescShort !== 'NCV' ? (
              <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
              })}</td>
            ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>)}
            <td className='td-input'>
            {noteVendite.some((note) => note.id === item.id && note.note !== null) ? ( <div className='categoria-selezionata'>
                <p id={item.id}>{noteVendite.find((note) => note.id === item.id).note}</p>
                <Trash onClick={() => handleCancelNote(item)}/>
              </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionNote} placeholder="Aggiungi nota" type='text' />)}
              
            </td>
            <td>
            {selectedCategory.some((category) => category.id === item.id && category.categoria !== null) ? (
              <div className='categoria-selezionata'>
                <p>{selectedCategory.find((category) => category.id === item.id).categoria}</p>

                {listaPreset.some((azienda) => 
                  azienda.azienda === item.companyName &&
                   
                  !(azienda.eccezioni && azienda.eccezioni.split(',').map(e => e.trim()).includes(item.docNumber))
                ) ? (
                  <ProhibitedIcon style={{ cursor: 'not-allowed' }}/>
                ) : (
                  <Cancel style={{ cursor: idInteressato === item.id ? 'not-allowed' : 'pointer' }} onClick={() => handleCancel(item)}/>
                )}
                
              </div>
            ) : (
              <select name="categoria" id={item.id} onChange={handleSelection}>
                <option disabled selected value="Seleziona">Seleziona una categoria</option>
                {listaCategorie.map((opzione) => (
                  <option key={opzione} value={opzione}>
                    {opzione}
                  </option>
                ))}
              </select>
            )}
            </td>
            <td>
            {selectedCategory.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  selectedCategory.some((category) => category.id === item.id && category.percentuale_businessunit.split(',').map(str => parseFloat(str.trim())).reduce((acc, num) => acc + num, 0) == 100 ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "vendite")}>Assegna</p>
            )}
            
          </td>
  
          </tr>);
        }
      }

    } else if (radioSelection === 'non-categorizzate'){
      if(!(selectedCategory.some((category) => category.id === item.id)) && item.docDescription.slice(-2) === numPagina){
        return (<tr className='tr-body' key={item.id}>
          <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
          <td>{item.docNumber}</td>
          <td>{item.bupName}</td>
          <td>{item.companyName}</td>
          {item.docTypeDescShort !== 'NCV' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
          <td className='td-input'>
          {noteVendite.some((note) => note.id === item.id && note.note !== null) ? ( <div className='categoria-selezionata'>
              <p id={item.id}>{noteVendite.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNote(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionNote} placeholder="Aggiungi nota" type='text' />)}
          </td>
          <td>
          {selectedCategory.some((category) => category.id === item.id && category.categoria !== null) ? (
            <p>{selectedCategory.find((category) => category.id === item.id).categoria}</p>
          ) : (
            <select name="categoria" id={item.id} onChange={handleSelection}>
              <option disabled selected value="Seleziona">Seleziona una categoria</option>
              {listaCategorie.map((opzione) => (
                <option key={opzione} value={opzione}>
                  {opzione}
                </option>
              ))}
            </select>
          )}
          </td>
          <td>
          {selectedCategory.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  selectedCategory.some((category) => category.id === item.id && category.percentuale_businessunit.split(',').map(str => parseFloat(str.trim())).reduce((acc, num) => acc + num, 0) == 100 ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "vendite")}>Assegna</p>
            )}
          
        </td>
        </tr>);
      }
    } else if (radioSelection === 'non-assegnate'){
      if( !(selectedCategory.some((category) => category.id === item.id)) && item.docDescription.slice(-2) === numPagina){
        return (<tr className='tr-body' key={item.id}>
          <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
          <td>{item.docNumber}</td>
          <td>{item.bupName}</td>
          <td>{item.companyName}</td>
          {item.docTypeDescShort !== 'NCV' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
          <td className='td-input'>
          {noteVendite.some((note) => note.id === item.id && note.note !== null) ? ( <div className='categoria-selezionata'>
              <p id={item.id}>{noteVendite.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNote(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionNote} placeholder="Aggiungi nota" type='text' />)}
          </td>
          <td>
          {selectedCategory.some((category) => category.id === item.id && category.categoria !== null) ? (
            <p>{selectedCategory.find((category) => category.id === item.id).categoria}</p>
          ) : (
            <select name="categoria" id={item.id} onChange={handleSelection}>
              <option disabled selected value="Seleziona">Seleziona una categoria</option>
              {listaCategorie.map((opzione) => (
                <option key={opzione} value={opzione}>
                  {opzione}
                </option>
              ))}
            </select>
          )}
          </td>
          <td>
          {selectedCategory.some((category) => category.id === item.id && category.businessunit !== null) ? (
            <p className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi</p>
          ): (
            <p className='unit' onClick={() => handleBusiness(item.id, "vendite")}>Assegna</p>
          )}
          
        </td>
        </tr>);
      } else if(selectedCategory.some((category) => category.id === item.id && category.businessunit === null) && item.docDescription.slice(-2) === numPagina){
        return (<tr className='tr-body' key={item.id}>
          <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
          <td>{item.docNumber}</td>
          <td>{item.bupName}</td>
          <td>{item.companyName}</td>
          {item.docTypeDescShort !== 'NCV' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
          <td className='td-input'>
          {noteVendite.some((note) => note.id === item.id && note.note !== null) ? ( <div className='categoria-selezionata'>
              <p id={item.id}>{noteVendite.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNote(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionNote} placeholder="Aggiungi nota" type='text' />)}
          </td>
          <td>
          {selectedCategory.some((category) => category.id === item.id && category.categoria !== null) ? (
            <p>{selectedCategory.find((category) => category.id === item.id).categoria}</p>
          ) : (
            <select name="categoria" id={item.id} onChange={handleSelection}>
              <option disabled selected value="Seleziona">Seleziona una categoria</option>
              {listaCategorie.map((opzione) => (
                <option key={opzione} value={opzione}>
                  {opzione}
                </option>
              ))}
            </select>
          )}
          </td>
          <td>
          {selectedCategory.some((category) => category.id === item.id && category.businessunit !== null) ? (
            <p className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi</p>
          ): (
            <p className='unit' onClick={() => handleBusiness(item.id, "vendite")}>Assegna</p>
          )}
          
        </td>
        </tr>);
      }
    }else {
      if(item.docDescription.slice(-2) === numPagina){

        return (<tr className='tr-body' key={item.id}>
          <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
          <td>{item.docNumber}</td>
          <td>{item.bupName}</td>
          <td>{item.companyName}</td>
          {item.docTypeDescShort !== 'NCV' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
          <td className='td-input'>
          {noteVendite.some((note) => note.id === item.id && note.note !== null) ? (
            <div className='categoria-selezionata'>
              <p id={item.id}>{noteVendite.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNote(item)}/>
            </div>
          ) : (
            <input className='td-input' id={item.id} onBlur={handleSelectionNote} placeholder="Aggiungi nota" type='text' />
          )}
          </td>
          <td> 
          {selectedCategory.some((category) => category.id === item.id && category.categoria !== null) ? (
              <div className='categoria-selezionata'>
                <p>{selectedCategory.find((category) => category.id === item.id).categoria}</p>
                {listaPreset.some((azienda) => 
                  azienda.azienda === item.companyName &&
                   
                  !(azienda.eccezioni && azienda.eccezioni.split(',').map(e => e.trim()).includes(item.docNumber))
                ) ? (
                  <ProhibitedIcon style={{ cursor: 'not-allowed' }}/>
                ) : (
                  <Cancel style={{ cursor: idInteressato === item.id ? 'not-allowed' : 'pointer' }} onClick={() => handleCancel(item)}/>
                )}
              </div>
            ) : (
              <select name="categoria" id={item.id} onChange={handleSelection}>
                <option disabled selected value="Seleziona">Seleziona una categoria</option>
                {listaCategorie.map((opzione) => (
                  <option key={opzione} value={opzione}>
                    {opzione}
                  </option>
                ))}
              </select>
          )}
          </td>
          <td>
            {selectedCategory.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  selectedCategory.some((category) => 
                    category.id === item.id && 
                    category.percentuale_businessunit &&
                    category.percentuale_businessunit.split(',')
                      .map(str => parseFloat(str.trim()))
                      .reduce((acc, num) => acc + num, 0) === 100
                  ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "vendite")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "vendite")}>Assegna</p>
            )}
          
          </td>
        </tr>);


      }
      
    }
    
  }


//acquisti
  function displayAcquisti(item, cercaCategoria){
    if(radioSelection === "categorizzate"){
      if(cercaCategoria){
        if((categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null && item.docDescription.slice(-2) === numPagina && category.categoria.toLowerCase().includes(cercaCategoria.toLowerCase()) ))){
          return(<tr className='tr-body' key={item.id}>
              <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
              <td>{item.docNumber}</td>
              <td>{item.companyName}</td>
              <td>{item.bupName}</td>
              {item.docTypeDescShort !== 'NCA' ? (
              <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
              })}</td>
            ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>)}
              <td className='td-input'>
              {noteAcquisti.some((note) => note.id === item.id && note.note !== null) ? (
              
              <div className='categoria-selezionata'>
                <p id={item.id}>{noteAcquisti.find((note) => note.id === item.id).note}</p>
                <Trash onClick={() => handleCancelNoteAcquisti(item)}/>
              </div>
              ) : (<input className='td-input' id={item.id} onBlur={handleSelectionAcquistiNote} placeholder="Aggiungi nota" type='text' />)}
              </td>
              <td>
              {categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null) ? (
              
              <div className='categoria-selezionata'>
                  <p>{categoryAcquisti.find((category) => category.id === item.id).categoria}</p>
                  
                  {listaPreset.some((azienda) => 
                    azienda.azienda === item.companyName &&
                      
                    !(azienda.eccezioni && azienda.eccezioni.split(',').map(e => e.trim()).includes(item.docNumber))
                  ) ? (
                    <ProhibitedIcon style={{ cursor: 'not-allowed' }}/>
                  ) : (
                    <Cancel  style={{ cursor: idInteressato === item.id ? 'not-allowed' : 'pointer' }} onClick={() => handleCancelAcquisti(item)}/>
                  )}
                  
              </div>
            ) : (
              <select name="categoria" id={item.id} onChange={handleSelectionAcquisti}>
                <option disabled selected value="Seleziona">Seleziona una categoria</option>
                {listaCategorie.map((opzione) => (
                  <option key={opzione} value={opzione}>
                    {opzione}
                  </option>
                ))}
              </select>
          )}
              </td>
              <td>
            {categoryAcquisti.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  categoryAcquisti.some((category) => category.id === item.id && category.percentuale_businessunit.split(',').map(str => parseFloat(str.trim())).reduce((acc, num) => acc + num, 0) == 100 ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "acquisti")}>Assegna</p>
            )}
            
          </td>
            </tr>
          )
        }     
      } else {
        if((categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null && item.docDescription.slice(-2) === numPagina))){
          return(<tr className='tr-body' key={item.id}>
              <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
              <td>{item.docNumber}</td>
              <td>{item.companyName}</td>
              <td>{item.bupName}</td>
              {item.docTypeDescShort !== 'NCA' ? (
              <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
                style: 'currency',
                currency: 'EUR',
              })}</td>
            ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>)}
              <td className='td-input'>
              {noteAcquisti.some((note) => note.id === item.id && note.note !== null) ? (
              
              <div className='categoria-selezionata'>
                <p id={item.id}>{noteAcquisti.find((note) => note.id === item.id).note}</p>
                <Trash onClick={() => handleCancelNoteAcquisti(item)}/>
              </div>
              ) : (<input className='td-input' id={item.id} onBlur={handleSelectionAcquistiNote} placeholder="Aggiungi nota" type='text' />)}
              </td>
              <td>
              {categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null) ? (
              
              <div className='categoria-selezionata'>
                  <p>{categoryAcquisti.find((category) => category.id === item.id).categoria}</p>
                  {listaPreset.some((azienda) => 
                    azienda.azienda === item.companyName &&
                      
                    !(azienda.eccezioni && azienda.eccezioni.split(',').map(e => e.trim()).includes(item.docNumber))
                  ) ? (
                    <ProhibitedIcon style={{ cursor: 'not-allowed' }}/>
                  ) : (
                    <Cancel  style={{ cursor: idInteressato === item.id ? 'not-allowed' : 'pointer' }} onClick={() => handleCancelAcquisti(item)}/>
                  )}
              </div>
            ) : (
              <select name="categoria" id={item.id} onChange={handleSelectionAcquisti}>
                <option disabled selected value="Seleziona">Seleziona una categoria</option>
                {listaCategorie.map((opzione) => (
                  <option key={opzione} value={opzione}>
                    {opzione}
                  </option>
                ))}
              </select>
          )}
              </td>
              <td>
              {categoryAcquisti.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  categoryAcquisti.some((category) => category.id === item.id && category.percentuale_businessunit.split(',').map(str => parseFloat(str.trim())).reduce((acc, num) => acc + num, 0) == 100 ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "acquisti")}>Assegna</p>
            )}
            
          </td>
            </tr>
          )
        }     
      }
                    
    } else if(radioSelection === "non-categorizzate"){
      if(!(categoryAcquisti.some((category) => category.id === item.id)) && item.docDescription.slice(-2) === numPagina){
        return(
          <tr className='tr-body' key={item.id}>
            <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
            <td>{item.docNumber}</td>
            <td>{item.companyName}</td>
            <td>{item.bupName}</td>
            {item.docTypeDescShort !== 'NCA' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
            <td className='td-input'>
            {noteAcquisti.some((note) => note.id === item.id && note.note !== null) ? (<div className='categoria-selezionata'>
              <p id={item.id}>{noteAcquisti.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNoteAcquisti(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionAcquistiNote} placeholder="Aggiungi nota" type='text' />)}
            </td>
            <td>
            {categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null) ? (
            <p>{categoryAcquisti.find((category) => category.id === item.id).categoria}</p>
          ) : (
            <select name="categoria" id={item.id} onChange={handleSelectionAcquisti}>
              <option disabled selected value="Seleziona">Seleziona una categoria</option>
              {listaCategorie.map((opzione) => (
                <option key={opzione} value={opzione}>
                  {opzione}
                </option>
              ))}
            </select>
        )}
            </td>
            <td>
            {categoryAcquisti.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  categoryAcquisti.some((category) => category.id === item.id && category.percentuale_businessunit.split(',').map(str => parseFloat(str.trim())).reduce((acc, num) => acc + num, 0) == 100 ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi</p>
                  )
                }
              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "acquisti")}>Assegna</p>
            )}
          
        </td>
          </tr>
        )
      }

    }else if(radioSelection === "non-assegnate"){
      if(!(categoryAcquisti.some((category) => category.id === item.id)) && item.docDescription.slice(-2) === numPagina){
        return(
          <tr className='tr-body' key={item.id}>
            <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
            <td>{item.docNumber}</td>
            <td>{item.companyName}</td>
            <td>{item.bupName}</td>
            {item.docTypeDescShort !== 'NCA' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
            <td className='td-input'>
            {noteAcquisti.some((note) => note.id === item.id && note.note !== null) ? (<div className='categoria-selezionata'>
              <p id={item.id}>{noteAcquisti.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNoteAcquisti(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionAcquistiNote} placeholder="Aggiungi nota" type='text' />)}
            </td>
            <td>
            {categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null) ? (
            <p>{categoryAcquisti.find((category) => category.id === item.id).categoria}</p>
          ) : (
            <select name="categoria" id={item.id} onChange={handleSelectionAcquisti}>
              <option disabled selected value="Seleziona">Seleziona una categoria</option>
              {listaCategorie.map((opzione) => (
                <option key={opzione} value={opzione}>
                  {opzione}
                </option>
              ))}
            </select>
        )}
            </td>
            <td>
          {categoryAcquisti.some((category) => category.id === item.id && category.businessunit !== null) ? (
            <p className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi</p>
          ): (
            <p className='unit' onClick={() => handleBusiness(item.id, "acquisti")}>Assegna</p>
          )}
          
        </td>
          </tr>
        )
      }else if(categoryAcquisti.some((category) => category.id === item.id && category.businessunit === null) && item.docDescription.slice(-2) === numPagina){
        return(
          <tr className='tr-body' key={item.id}>
            <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
            <td>{item.docNumber}</td>
            <td>{item.companyName}</td>
            <td>{item.bupName}</td>
            {item.docTypeDescShort !== 'NCA' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
            <td className='td-input'>
            {noteAcquisti.some((note) => note.id === item.id && note.note !== null) ? (<div className='categoria-selezionata'>
              <p id={item.id}>{noteAcquisti.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNoteAcquisti(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionAcquistiNote} placeholder="Aggiungi nota" type='text' />)}
            </td>
            <td>
            {categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null) ? (
            <p>{categoryAcquisti.find((category) => category.id === item.id).categoria}</p>
          ) : (
            <select name="categoria" id={item.id} onChange={handleSelectionAcquisti}>
              <option disabled selected value="Seleziona">Seleziona una categoria</option>
              {listaCategorie.map((opzione) => (
                <option key={opzione} value={opzione}>
                  {opzione}
                </option>
              ))}
            </select>
        )}
            </td>
            <td>
          {categoryAcquisti.some((category) => category.id === item.id && category.businessunit !== null) ? (
            <p className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi</p>
          ): (
            <p className='unit' onClick={() => handleBusiness(item.id, "acquisti")}>Assegna</p>
          )}
          
        </td>
          </tr>
        )
      }

    } else{
      if(item.docDescription.slice(-2) === numPagina){
        return(
          <tr className='tr-body' key={item.id}>
          <td>{item.docDescription.slice(item.docDescription.indexOf("del") + 3).trim()}</td>
          <td>{item.docNumber}</td>
          <td>{item.companyName}</td>
          <td>{item.bupName}</td>
          {item.docTypeDescShort !== 'NCA' ? (
            <td>{Number(item.taxableAmount).toLocaleString('it-IT', {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          ) : (<td>-{Number(item.taxableAmount).toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}</td>)}
          <td className='td-input'>
          {noteAcquisti.some((note) => note.id === item.id && note.note !== null) ? (<div className='categoria-selezionata'>
              <p id={item.id}>{noteAcquisti.find((note) => note.id === item.id).note}</p>
              <Trash onClick={() => handleCancelNoteAcquisti(item)}/>
            </div>) : (<input className='td-input' id={item.id} onBlur={handleSelectionAcquistiNote} placeholder="Aggiungi nota" type='text' />)}
          </td>
          <td>
          {categoryAcquisti.some((category) => category.id === item.id && category.categoria !== null) ? (
              <div className='categoria-selezionata'>
                  <p>{categoryAcquisti.find((category) => category.id === item.id).categoria}</p>
                  {listaPreset.some((azienda) => 
                    azienda.azienda === item.companyName &&
                      
                    !(azienda.eccezioni && azienda.eccezioni.split(',').map(e => e.trim()).includes(item.docNumber))
                  ) ? (
                    <ProhibitedIcon style={{ cursor: 'not-allowed' }}/>
                  ) : (
                    <Cancel  style={{ cursor: idInteressato === item.id ? 'not-allowed' : 'pointer' }} onClick={() => handleCancelAcquisti(item)}/>
                  )}
              </div>
            ) : (
              <select name="categoria" id={item.id} onChange={handleSelectionAcquisti}>
                <option disabled selected value="Seleziona">Seleziona una categoria</option>
                {listaCategorie.map((opzione) => (
                  <option key={opzione} value={opzione}>
                    {opzione}
                  </option>
                ))}
              </select>
          )}
          </td>
          <td>
          {categoryAcquisti.some((category) => category.id === item.id && category.businessunit !== null) ? (
              <>
                {
                  categoryAcquisti.some((category) => 
                    category.id === item.id && 
                    category.percentuale_businessunit &&
                    category.percentuale_businessunit.split(',')
                      .map(str => parseFloat(str.trim()))
                      .reduce((acc, num) => acc + num, 0) === 100
                  ) ? (
                    <p style={{display: 'flex', alignItems: 'center'}} className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi <TickIcon /></p>
                  ) : (
                    <p className='unit' onClick={() => handleBusinessUnit(item.id, "acquisti")}>Vedi</p>
                  )
                }

              </>
              
            ): (
              <p className='unit' onClick={() => handleBusiness(item.id, "acquisti")}>Assegna</p>
            )}
            
          </td>
        </tr>
        )
      }
      
    }
  }

  //aggiungi

  const [aggiungi, setAggiungi] = useState(false);
  function handleSeeAggiungi(){
    setAggiungi(!aggiungi)
  }

  const [preset, setPreset] = useState('');
  function handlePreset(tipo){
    setPreset(tipo);
  }


  function ricaricaDati(){
    fetchData();
    fetchDataAcquisti();
  }

  async function cancelPreset(id){
    const dati={
      id: id
    }
    const response = await axios.post("https://www.app.nauticsm.it/cancella-preset", dati);

    fetchData();

  }

  const [selectedException, setSelectedException] = useState(null);
  const [viewMoreException, setViewMoreException] = useState(null);
  const [newException, setNewException] = useState(null);
  function addException(id){
    setSelectedException(id);
  }
  function expandException(id){
    setViewMoreException(id);
  }
  function newValue(e){
    const {value} = e.target;

    setNewException(value);
  }

  async function saveValue(){
    const dati = {
      exception: newException,
      id: selectedException
    }

    const response = await axios.post("https://www.app.nauticsm.it/eccezione-preset", dati);

    fetchData();
    fetchDataAcquisti();
    setSelectedException(null);
    setNewException(null);
  }

  return (
    <main>
      <TopMenu name="Fatture SDI"/>
      
      <Messaggio visualizzaMessaggio={visualizzaMessaggio}>
        <div className='content'>
          <p>{contenutoMessaggio}</p>
        </div>
      </Messaggio>
      
      <div className='pulsanti-scelta'>
        <div className='button-selection'>
          <button className={activeButton === 1 && 'active'} onClick={() => handleButtonClick(1)}>Vendite</button>
          <button className={activeButton === 2 && 'active'} onClick={() => handleButtonClick(2)}>Acquisti</button>
          <button className={activeButton === 3 && 'active'} onClick={() => handleButtonClick(3)}>Preset</button>
        </div>
        {activeButton !== 3 && (
          <div className="search">
            <input type="text" placeholder="Cerca per azienda" value={searchTerm} onChange={handleSearch}/>
            {radioSelection === 'categorizzate' && (<input type="text" id="cerca-categoria" value={searchCategory} placeholder="Cerca per categoria" onChange={handleSearchCategory}/>)}
          </div>
        )}
      </div>

      {activeButton !== 3 && (
        <div className='selection'>
          <Radio radioSelection={radioSelection}>
            
            <p name="category" onClick={handleRadioSelection} id="tutte">Tutte</p>
            <p name="category" onClick={handleRadioSelection} id="categorizzate">Categorizzate</p>
            <p name="category" onClick={handleRadioSelection} id="non-categorizzate">Non Categorizzate</p>
            <p name="category" onClick={handleRadioSelection} id="non-assegnate">Non Assegnate</p>
          </Radio>
          
          <div className='pagine'>
            <NumPage>
              <ul>
                {anni.map((item) => (
                  <li className={"20"+numPagina == item && ('active')} onClick={handlePage} id={item}>{item}</li>
                ))}
              </ul>
            </NumPage>
          </div>
        </div>
      )}

      <Suspense fallback={<div>Loading....</div>}>
      {activeButton === 1 && (<div>{fatture ? (
        <div className='container-tabella'>
          <table className='tabella'>
            <thead>
              <tr>
                <th>Data</th>
                <th>N.</th>
                <th>Venditore</th>
                <th>Cliente</th>
                <th>Importo (Iva Escl.)</th>
                <th>Note</th>
                <th>Piano dei Conti</th>
                <th>Divisione</th>
              </tr>
            </thead>
            <tbody>
              {listaCategorie && (
                <>
                  {searchTerm ? (                    
                  fatture.filter((item) =>
                    item.companyName.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .map((item) => (
                      displayCategory(item, searchCategory)
                  ))

                  
                ): (
                  fatture.map((item) => (

                    displayCategory(item, searchCategory)
                  ))
                )}
                </>
              )}

            

            </tbody>
          </table>
        </div>
      ) : (
        <p>Caricamento</p>
      )}</div>) }
      
      {activeButton === 2 && (
        <div>
          {acquisti ? (
            <div className='container-tabella'>
              <table className='tabella'>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>N.</th>
                    <th>Venditore</th>
                    <th>Cliente</th>
                    <th>Importo (Iva Escl.)</th>
                    <th>Note</th>
                    <th>Piano dei Conti</th>
                    <th>Divisione</th>
                  </tr>
                </thead>
                <tbody>
                  {listaCategorie && (
                    <>
                      {searchTerm ? (
                        acquisti
                          .filter((item) =>
                            item.companyName.toLowerCase().includes(searchTerm.toLowerCase())
                          )
                          .map((item) => displayAcquisti(item, searchCategory))
                      ) : (
                        acquisti.map((item) => displayAcquisti(item, searchCategory))
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Caricamento</p>
          )}
        </div>
      )}

      {activeButton === 3 && (
        <div>
          {listaPreset ? (
            <div className='container-tabella' style={{marginTop: '5vh'}}>
              <table className='tabella'>
                <thead >
                  <tr style={{fontSize: '24px'}}>
                    <th>Azienda</th>
                    <th>Categoria</th>
                    <th>Business Unit</th>
                    <th>Percentuale</th>
                    <th></th>
                    <th ></th>
                  </tr>
                </thead>
                <tbody>
                {listaPreset.map((item) => (
                  <tr style={{fontSize: '18px'}} className='tr-body' key={item.id}> 
                    <td>{item.azienda}</td>
                    <td>{item.categoria}</td>
                    <td>{item.unit}</td>
                    <td>
                      {item.percentuale_unit !== null ? (<>{item.percentuale_unit
                        .split(',') // Divide la stringa sui separatori di virgola
                        .map((val) => val.trim() + '%') // Aggiunge il simbolo % a ciascun valore
                        .join(', ')} {/* Riunisce i valori con una virgola e uno spazio */}</>) : (<>/</>)}
                    </td>
                    <td style={{width: 'fit-content'}}>{selectedException === item.id ? (<div style={{display: 'flex', justifyContent: 'center', width: 'fit-content', alignItems: 'center'}}><input style={{outline: 'none', border: '2px solid black', fontSize: '18px', padding: '5px 10px'}} type="text" onChange={newValue} placeholder='Inserire N. fattura'/> <TickIcon style={{fontSize: '28px'}} onClick={saveValue}/></div>) : 
                    
                    (<div style={{display: 'flex', justifyContent: 'center', width: 'fit-content', alignItems: 'center'}}>{viewMoreException === item.id ? (<>{item.eccezioni} <Compress title="Comprimi" onClick={() => expandException(null)}/></>) : (<><Exception title="Aggiungi Eccezione" style={{fontSize: '24px'}} onClick={() => addException(item.id)} /> <Expand title="Espandi" onClick={() => expandException(item.id)}/></>)}</div>)}
                    
                    </td>

                    <td style={{width: 'fit-content'}}><Trash title="Elimina" onClick={() => cancelPreset(item.id)}/></td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>Caricamento</p>
          )}
        </div>
      )}

      
      
      </Suspense>

      {showBusinessPopup && (<BusinessPopup closePopup={closeBusinessUnit} tipo={tipo} business={businessUnit} id={currentId} ricarica={ricaricaDati}/>)}

      {selectedBusiness && (
        <React.Fragment>
          {tipo !== 'acquisti' ? (<BusinessUnit closePopup={closeBusinessUnit} businessUnit={businessUnit} tipo={tipo} business={selectedCategory} id={currentId} ricarica={ricaricaDati}/>) : (<BusinessUnit closePopup={closeBusinessUnit} businessUnit={businessUnit} tipo={tipo} business={categoryAcquisti} id={currentId} ricarica={ricaricaDati}/>)}
        </React.Fragment>
      )}

      {preset === 'business' && (
        <Preset close={() => handlePreset('')} categorie={listaCategorie} businessUnit={businessUnit} ricarica={ricaricaDati}/>
      )}

      {preset === 'categorie' && (
        <PresetAziende close={() => handlePreset('')} businessUnit={businessUnit} ricarica={ricaricaDati}/>
      )}

      <ContainerAggiungi aggiungi={aggiungi}>
        <div>
          <Slide right effect="slideInRight" duration={700} when={aggiungi} >
          <button
            onClick={() => handlePreset('business')} style={{ display: aggiungi ? 'block' : 'none' }} disabled={!aggiungi}>
            Preset Business Unit
          </button>
            
          </Slide>
          <Slide right effect="slideInRight" duration={700} delay={200} when={aggiungi}>
          <button onClick={() => handlePreset('categorie')}
            style={{ display: aggiungi ? 'block' : 'none' }} disabled={!aggiungi}>
            Preset Categorie
          </button>
          </Slide>
        </div>
        <div className='aggiungi' onClick={handleSeeAggiungi}>
          <PlusIcon aggiungi={aggiungi}/>
        </div>
      </ContainerAggiungi>
          
    </main>
  
  );
}

export default FattureSDI;


const TickIcon = styled(TiTick)`
  font-size: 22px;
  color: green;
  margin-left: 5px;
  cursor: pointer;
`

const ContainerAggiungi = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;

  div{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  button{
    font-size: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
    display: ${({ aggiungi }) => (!aggiungi && 'none')};
  }
  button:nth-child(1){
    
    background-color: black;
    color: white;
  }

  button:nth-child(2){
    margin: 20px 0;
    background-color: lightblue;
    
  }

  
`

const PlusIcon = styled(FaPlus)`
  font-size: 54px;
  color: white;
  padding: 10px;
  transition: all .2s linear;
  ${({ aggiungi }) => (aggiungi && 'transform: rotateZ(45deg);')};
`

const NumPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul{
    list-style: none;
    padding: 0;

  }

  ul li{
    display: inline;
    font-size: 16px;
    cursor: pointer;
    padding: 5px 10px;
    margin: 0px 5px;
    border-radius: 5px;
    aspect-ratio: 1/1;
    width: fit-content;
    height: fit-content;
    box-shadow: 0px 0px 3px rgba(0,0,0,.3);

    &:hover{
      background-color: #213940;
      transition: all .3s linear;
      color: white;
    }

    .active{
      background-color: #213940;
      color: white;
    }
  }
`

const Cancel = styled(IoMdClose)`
  font-size: 18px;
  cursor: pointer;
  color: red;
`

const ProhibitedIcon = styled(PiProhibitBold)`
  font-size: 18px;
  cursor: pointer;
  color: red;
  margin-left: 5px;
`

const Trash = styled(FaTrashCan)`
  font-size: 16px;
  cursor: pointer;
  color: red;
`

const Exception = styled(MdFormatListBulletedAdd)`
  color: orange;
  font-size: 20px;
  cursor: pointer;
`

const Expand = styled(FaExpandAlt)`
  color: black;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
`

const Compress = styled(FaCompressAlt)`
  color: black;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
`

const Radio = styled.div`
  margin-left: 5%;
  display: flex;

  p{
    padding: 0px 8px;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 3px solid rgba(33, 57, 64, .2);
  }
  label{
    padding: 0 5px;
    cursor: pointer;
  }
  input{
    cursor: pointer;
  }

  #${props => props.radioSelection} {
    border-bottom: 3px solid rgba(33, 57, 64, 1);
  }
`


const Messaggio = styled.div`
  position: fixed;
  ${({ visualizzaMessaggio }) => (visualizzaMessaggio ? 'top: 12vh' : 'top: -100px')};
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  z-index: 9999;

  .content{
    border: 2px solid #1eafd6;
    
    padding: 10px 20px;
    border-radius: 10px;
    background-color: rgba(30, 175, 214, .5);

    p{
      font-size: 20px;
    }
  }
`