import React, {useState} from 'react'

import Correct from '../InnerComponents/Correct';

//icon
import { TiTick } from "react-icons/ti";

import styled from "styled-components"

function ImportoStato({close, ricarica, categorie, anni}) {

    const [correct, setCorrect] = useState(false);

    const [importo, setImporto] = useState({
        cat: 'seleziona',
        importo: 0,
        anno: 2024
    });

    function handleImporto(e){
        const value = e.target.value;
        const name = e.target.name;

        setImporto(prevImporto => ({
            ...prevImporto,
            [name]: value
        }));
    }

    async function handleClickImporto() {
        setCorrect(true);
    
        try {
            const response = await fetch('https://www.app.nauticsm.it/importo-stato', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(importo),
            });
    
            if (!response.ok) {
                throw new Error(`Errore HTTP! Status: ${response.status}`);
            }
            response.json() // Puoi anche rimuovere questa parte se il corpo della risposta non è importante
              .then(data => {
                setTimeout(() => {
                    close();
                    ricarica();
                }, 1000)
              })
              .catch(error => {
                console.error('Errore durante la gestione della risposta:', error);
              });
        } catch (error) {
            console.error('Errore durante l\'invio dei dati al server:', error);
        }
    }

  return (
    <>
        {correct ? (<TickContainer>
            <InnerContainer><Tick /></InnerContainer>            
        </TickContainer>): (
            <React.Fragment>
                <div className='popup-title'>
                    <h3>Aggiungi Importo</h3>
                </div>
                <div className='popup-form'>
                    <label>Seleziona categoria:</label>
                    <select name="cat" onChange={handleImporto}>
                        <option disabled selected value="seleziona">- Seleziona -</option>
                        {categorie
                            .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                            .map((item) => (
                                <option value={item} key={item}>{item}</option>
                            ))
                        }
                    </select>
                    <br /><br />
                    <label>Inserisci importo:</label>
                    <input type="number" name="importo" placeholder='100.99' onChange={handleImporto}/>
                    <br /><br />
                    <label>Seleziona anno:</label>
                    <select name="anno" onChange={handleImporto}>
                        <option disabled selected value="seleziona">- Seleziona -</option>
                        {anni.map((item) => (
                            <option value={item}>{item}</option>
                        ))}
                    </select>
                    
                </div>
                <div className='send-button'>
                    <button id="aggiungi" onClick={handleClickImporto}>Aggiungi</button>
                </div>
            </React.Fragment>
        )}
    </>
  )
}

export default ImportoStato;

const Tick = styled(TiTick)`
    font-size: 100px;
    color: white;
`

const TickContainer = styled.div`
    
    width: 100%;
    min-height: inherit;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InnerContainer = styled.div`
    
    background-color: green;
    border-radius: 50%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    
`