import React, {useState, useEffect} from 'react';
import axios from "axios";
import styled from "styled-components";
import moment from 'moment-timezone';

//icon
import { FaUserCircle } from "react-icons/fa";
import { FaHome } from "react-icons/fa";

import "./innerStyles/ContoEconomico.css";
import { useNavigate } from 'react-router-dom';


function TopMenu({name, username}) {

  const anno = moment().year();
  //redirect
  const navigate = useNavigate();
  function handleRedirect(location){
    
    if(location === 'profile'){
      navigate('/profilo', { state: { username: username } });
    } else {
      navigate('/home');
    }
    
  }

  return (
    <>
      <div className='top-menu'>
        {anno && (<h1 style={{display: 'flex', alignItems: 'center'}}>{name} <span style={{fontSize: '20px', marginLeft: '10px'}}>({anno})</span></h1>)}
        {name !== 'Profilo' ? (
          <Profile>
            <UserIcon onClick={() => handleRedirect("profile")}/>
          </Profile>
        ) : (
          <Profile>
            <HomeIcon onClick={() => handleRedirect("home")}/>
          </Profile>
        )}
      </div>
      
    </>
  )
}

export default TopMenu;

const Profile = styled.div`
  margin-right: 20px;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
`

const UserInfo = styled.div`
  position: fixed;
  top: 60px;
  right: 0;

  
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0,0,0,.7);
  width: 280px;
  border-radius: 10px;
  height: ${({seeProfile}) => (seeProfile ? '130px' : '0')};
  transition: height .5s ease-in-out;
  overflow: hidden;
  margin-right: 3%;
  margin-top: -15px;
  .info{
    font-size: 20px;
    margin-left: 10px;
  }
  .info h4{
    margin-top: 10px;
  }

  .info p{
    font-size: 18px;
  }

  .info .primo{
    margin-top: 5px;
    font-weight: 700;
  }

  .esci{
    position: absolute;
    bottom: 7px;
    right: 7px;
    cursor: pointer;
    font-size: 18px;
  }
`

const UserIcon = styled(FaUserCircle)`
  color: white;
  font-size: 30px;
  cursor: pointer;
`

const HomeIcon = styled(FaHome)`
  color: white;
  font-size: 30px;
  cursor: pointer;
`