import React, {useState} from 'react'

import styled from "styled-components";

//component
import Correct from './Correct';

//icon
import { IoMdClose } from "react-icons/io";

function CategoriaConto({close, categorie, ricaricaDati}) {

    const [tipo, setTipo] = useState('seleziona');
    const [displayTick, setDisplayTick] = useState(false);

    function handleTypeChange(e){
        setTipo(e.target.value);
    }

    //chiusura
    function handlePopup(){
        close();
    }

    const [formData, setFormData] = useState({
        category: 'seleziona',
        name: '',
        type: 'seleziona',
      });
    
    const handleAddButtonClick = () => {

        setDisplayTick(!displayTick);

        setTimeout(() => {
            close();
            ricaricaDati();
        }, 1000);
        
        fetch('https://www.app.nauticsm.it/aggiungi-categoria', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
        
        })
        .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    //elimina
    const [eliminaCategoria, setEliminaCategoria] = useState({
        categoria: 'seleziona'
    });
    function handleElimina(e){
        const {value} = e.target;
        setEliminaCategoria(prevElimina => ({
            ...prevElimina,
            categoria: value
        }));
    }

    function handleEliminaClick(){
        setDisplayTick(!displayTick);

        setTimeout(() => {
            close();
            ricaricaDati();
        }, 1000);
        
        fetch('https://www.app.nauticsm.it/elimina-categoria', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(eliminaCategoria),
        })
        .then(response => response.json())
        .then(data => {
        
        })
        .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
        });
    }

    //modifica
    const [modificaCategoria, setModificaCategoria] = useState({
        categoria: 'seleziona',
        nuovoNome: '',
        tipo: 'seleziona'
    });
    const handleModifica = (e) => {
        const { name, value } = e.target;
        setModificaCategoria(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    function handleModificaClick(){
        setDisplayTick(!displayTick);

        setTimeout(() => {
            close();
            ricaricaDati();
        }, 1000);
        
        fetch('https://www.app.nauticsm.it/modifica-categoria', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify(modificaCategoria),
        })
        .then(response => response.json())
        .then(data => {
        
        })
        .catch(error => {
        console.error('Errore durante l\'invio dei dati al server:', error);
        });
    }

  return (
    <>
        {displayTick ? (<Popup><PopupContent><Correct close={handlePopup}/></PopupContent></Popup>) : (
        <Popup>

          <InnerPopup>
            <Choice>
                <select name="tipo" onChange={handleTypeChange}>
                    <option disabled selected value="seleziona">- Seleziona -</option>
                    <option value="Aggiungi">Aggiungi</option>
                    <option value="Elimina">Elimina</option>
                    <option value="Modifica">Modifica</option>
                </select>
            </Choice>
            <CloseIcon onClick={handlePopup}/>
            {tipo === 'seleziona' && (<Initial>Seleziona una voce dal menu in alto a sx</Initial>)}
            {tipo === 'Aggiungi' && (
                <React.Fragment>
                    <div className='popup-title'>
                        <h2>Aggiungi Categoria</h2>
                        
                    </div>
                    <div className='popup-form'>
                    <label for="categories">Seleziona una Macrocategoria:</label>
        
                    <select name="category" id="cat" onChange={handleInputChange} value={formData.category}>
                        <option disabled selected value="seleziona">- Seleziona -</option>
                        <optgroup label="Ricavi">
                        <option value="ricavi">Ricavi</option>
                        </optgroup>
                        <optgroup label="Acquisti">
                        <option value="totale rimanenze iniziali">Rimanenze Iniziali</option>
                        <option value="totale acquisti">Acquisti</option>
                        <option value="totale rimanenze finali">Rimanenze Finali</option>
                        </optgroup>
        
                        <optgroup label="Costi">
                        <option value="totale costi produttivi">Costi produttivi</option>
                        <option value="totale costo del personale">Costi del personale</option>
                        <option value="totale costi commerciali">Costi commerciali</option>
                        <option value="totale costi amministrativi">Costi amministrativi</option>
                        </optgroup>
                        <optgroup label="Ammortamenti">
                        <option value="totale ammortamenti">Ammortamenti</option>
                        </optgroup>
                        <optgroup label="Oneri">
                        <option value="totale oneri tributari">Oneri tributari</option>
                        <option value="totale oneri finanziari">Oneri finanziari</option>
                        </optgroup>
                        <optgroup label="Proventi Finanziari">
                        <option value="totale proventi finanziari">Proventi finanziari</option>
                        </optgroup>
                        <optgroup label="Extra">
                        <option value="totale ricavi extra gestionali">Ricavi Extra Gestionali</option>
                        <option value="totale costi extra gestionali">Costi Extra Gestionali</option>
                        </optgroup>
                    </select>
        
                    <br /><br />
        
                    <label for="name">Inserisci un nome:</label>
                    <input name="name" onChange={handleInputChange} value={formData.name} type="text" placeholder="Stipendio"/>
        
                    <br /><br />
        
                    <label for="type">Selezionare il tipo:</label>
                    <select name="type" id="tipo" onChange={handleInputChange} value={formData.type}>
                        <option disabled selected value="seleziona">- Seleziona -</option>
                        <option value="F">Fisso</option>
                        <option value="V">Variabile</option>
                        <option value="IP">IP</option>
                        <option value="O">O</option>
                        <option value="Z">Z</option>
                    </select>
                    </div>
        
                    
        
                    <div className='send-button'>
                    <button id="aggiungi" onClick={handleAddButtonClick}>Aggiungi</button>
                    </div>
                </React.Fragment>
            )}

            {tipo === 'Elimina' && (
                <React.Fragment>
                    <div className='popup-title'>
                        <h2>Elimina Categoria</h2>
                    
                    </div>
                    <div className='popup-form'>
                        <label>Seleziona una Categoria:</label>
                        <select name="categoria-elimina" onChange={handleElimina} value={eliminaCategoria.categoria}>
                            <option value="seleziona" selected disabled>- Seleziona -</option>
                            {categorie
                                .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                .map((item) => (
                                    <option value={item} key={item}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='send-button'>
                        <button id="elimina" onClick={handleEliminaClick}>Elimina</button>
                    </div>
                </React.Fragment>
            )}

            {tipo === 'Modifica' && (
                <React.Fragment>
                    <div className='popup-title'>
                        <h2>Modifica Categoria</h2>
                    
                    </div>
                    <div className='popup-form'>
                        <label>Seleziona una Categoria:</label>
                        <select name="categoria" value={modificaCategoria.categoria} onChange={handleModifica}>
                            <option value="seleziona" selected disabled>- Seleziona -</option>
                            {categorie
                                .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                .map((item) => (
                                    <option value={item} key={item}>{item}</option>
                                ))
                            }
                        </select>
                        <br/><br/>
                        <label>Inserisci nuovo nome:</label>
                        <input type="text" name="nuovoNome" placeholder='Inserire nuovo nome' value={modificaCategoria.nuovoNome} onChange={handleModifica} />
                        <br /><br />
                        <label>Seleziona il tipo:</label>
                        <select name="tipo" value={modificaCategoria.tipo} onChange={handleModifica}>
                            <option value="seleziona" selected disabled>- Seleziona -</option>
                            <option value="F">Fisso</option>
                            <option value="V">Variabile</option>
                            <option value="O">O</option>
                            <option value="Z">Z</option>
                            <option value="IP">IP</option>
                        </select>
                    </div>
                    <div className='send-button'>
                        <button id="modifica" onClick={handleModificaClick}>Modifica</button>
                    </div>
                </React.Fragment>
            )}
          </InnerPopup>
  
        </Popup>)}
    </>
  )
}

export default CategoriaConto;

const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`

const Popup = styled.div`
    width: 100%;
    height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
    background-color: rgba(0,0,0,.7);
 
  z-index: 2000;

`
const PopupContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
`;

const InnerPopup = styled.div`
  position: fixed;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  background-color: white;
  width: fit-content;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 50px 0px 25px;
  min-width: 30%;

  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
  }



  .popup-form{
    
    margin-top: 20px;
  }

  .popup-form input{
    border: 1px solid black;
    font-size: 18px;
    padding: 3px 8px;
  }

  .popup-form label{
    margin-right: 20px;
    font-size: 18px;
  }

  .popup-form select{
    font-size: 18px;
    border: 1px solid black;
    cursor: pointer;
  }

  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

  
`

const Choice = styled.div`
  padding-left: 2%;
  padding-top: 20px;
  margin-bottom: 10px;
  select{
    border: 1px solid black;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
  }
`

const Initial = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: lightgrey;
`