import React, {useEffect, useState} from 'react'
import axios from "axios";

import Correct from './Correct.js';

import styled from "styled-components";

//icon
import { IoMdClose } from "react-icons/io";

function PresetAziende({close, businessUnit, ricarica}) {

  const [tick, setTick] = useState(false);
  const [dati, setDati] = useState(null);

    function handleClose(){
        close();
    }


    //checked
  const [checked, setChecked] = useState({});

  function handleChecked(id, unit) {
    setChecked(prevOggetto => {
      if (prevOggetto[id]) {
        const { [id]: _, ...nuovoOggetto } = prevOggetto;
        return nuovoOggetto;
      } else {
        return {
          ...prevOggetto,
          [id]: unit
        };
      }
    });
  }

  //dati da inviare
  const [dataUnit, setDataUnit] = useState({});

  function handleUnit(unit, e){
    
    setDataUnit(prevOggetto => ({
      ...prevOggetto,
      [unit]: e.target.value
    }));

    console.log(dataUnit);
    
  }

  //richiesta aziende
  async function richiestaAziende(){
      
      const response = await axios.get('https://www.app.nauticsm.it/preset-aziende');

      setDati(response.data);
  }

  useEffect(() => {
      richiestaAziende();
  }, []);


  const [company, setCompany] = useState(null);
  function handleSelectCompany(e){
      const {value} = e.target;

      setCompany(value);
  }

  const [category, setCategory] = useState(null);
  function handleCategory(e){
      const {value} = e.target;

      setCategory(value);
  }

  async function aggiuntaPreset() {
      
      setTick(true);
      
      const data = {
        azienda: company,
        category: category,
        unit: dataUnit
      }
  
      try {
        const response = await axios.post('https://www.app.nauticsm.it/aggiungi-preset', data);
        console.log('Risposta dal server:', response.data);
  
        
        ricarica();
      } catch (error) {
        console.error('Errore nella richiesta:', error);
        // Esempio di gestione degli errori, ad esempio, mostrare un messaggio di errore
        alert('Si è verificato un errore durante l\'aggiunta del preset.');
      }
  
      
      setTimeout(() => {
          setTick(false);
          close();
      }, 2000);
  }



  return (
    <Opacity>
        <Popup>
            <CloseIcon onClick={handleClose}/>
            {tick ? (
                <>
                    <Correct />
                </>
            ) : (
                <>  
                    <div style={{padding: '20px 80px 20px 25px'}}>
                        <div className='title'>
                            <h2>Aggungi Preset Categorie</h2>
                        </div>
                        {dati && (
                            <div className='business-option'>
                                <label>Seleziona azienda:</label>
                                <select name="azienda" onChange={handleSelectCompany} value={company}>
                                    <option value="seleziona" disabled selected>- Seleziona -</option>
                                    {dati.aziende.map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {dati && (
                            <div className='business-option'>
                                <label>Seleziona categoria:</label>
                                <select name="categoria" onChange={handleCategory} value={category}>
                                    <option value="seleziona" disabled selected>- Seleziona -</option>
                                    {dati.categorie
                                      .sort((a, b) => a.localeCompare(b)) // Ordina gli elementi in ordine alfabetico
                                      .map((item) => (
                                          <option value={item} key={item}>{item}</option>
                                      ))
                                    }
                                </select>
                            </div>
                        )}
                        {businessUnit.map((item) => (
                                <div key={item.id} className='business-option'>

                                <label class="container">
                                    <input type="checkbox" onChange={() => handleChecked(item.id, item.unit)} name={item.unit}/>
                                    <span class="checkmark"></span>
                                    <p>{item.unit}</p>
                                    
                                </label>
                                </div>
                        ))}
                        <div className='percent'>

                            {Object.keys(checked).map(key => (
                            <div className='input-percent'>
                                <label>
                                <input onChange={(e) => handleUnit(checked[key], e)} type="number" step="5" min="0" max="100"/>
                                <p>% {checked[key]}</p>
                                </label><br/>
                            </div>
                            ))}           

                        </div>
                        
                    </div>
                    <div className='send-button'>
                        <button id="aggiungi" onClick={aggiuntaPreset}>Salva</button>
                    </div>
                    
                    
                </>
            )}

        </Popup>
    </Opacity>
  )
}

export default PresetAziende;

const Popup = styled.div`
 position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  min-height: 30vh;
  border-radius: 10px;
  overflow-y: auto;
  background-color: white;
  z-index: 9999;
  max-height: 60vh;

  .business-option{
    margin-top: 40px;
  }
  .business-option label{
    margin-right:20px;
    cursor: pointer;
    font-size: 20px;
  }
  .input-percent label{
    font-size: 20px;
  }
  .input-percent input{
    font-size: 18px;
    outline: none;
    padding: 5px 8px;
    border: 1px solid black;

  }

  .input-percent input:focus{
    box-shadow: 0px 0px 7px rgba(0,0,0,.7)
  }

  .business-option input{
    margin-right: 5px;
  }

  .business-option select{
    cursor: pointer;
  }

  .send-button{
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
    margin-top: 20px;
  }

  .send-button button{
    box-shadow: 0px 0px 5px rgba(0,0,0,.3);
    cursor: pointer;
    font-size: 20px;
    border: none;
    
    outline: none;
    border-radius: 5px;
    padding: 8px 50px;
    
  }

  p{
    margin-left: 40px;
  }

  .choice-btn{
    margin-top: 30px;
    button{
        background-color: white;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px rgba(0,0,0,.5);
        padding: 5px 8px;
        margin: 0px 5px;
        transition: all .3s linear;

        &:hover{
            color: white;
            background-color: #213940;
        }
    }
  }

  .choice-btn .active-choice{
    color: white;
    background-color: #213940;
  }

`

const Opacity = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,.7);
  width: 100%;
  height: 100%;
  z-index: 2000;

`


const CloseIcon = styled(IoMdClose)`font-size: 36px; cursor:pointer; position: fixed; top: 5px; right: 5px;

    &:hover{
        background-color: #213940;
        border-radius: 5px;
        color: white;
        transition: all .3s linear;
    }
`