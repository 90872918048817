import React, {useEffect, useState} from 'react'

import styled from "styled-components";
import axios from "axios";

//icon
import { ImBin } from "react-icons/im";
import moment from 'moment';

function RegistroCashflow({dati, ricarica}) {



    //delete value
    async function handleDelete(id, categoria, mese, tipo, anno){
       
        const data = {
            id: id,
            categoria: categoria,
            mese: mese,
            tipo: tipo,
            anno: anno
        }

        try {
            const response = await axios.post('https://www.app.nauticsm.it/elimina-registrocashflow', data);
        
            ricarica();
            
          } catch (error) {
            console.error('Errore durante la richiesta dei dati:', error);
          }
    }

    //selezione anno 
    const [annoVisualizzazione, setAnnoVisualizzazione] = useState('corrente');
    function handleClickBtn(type){
        setAnnoVisualizzazione(type);
    }
  return (
    <Container>
        {dati && (
            <div style={{width: '95%', paddingLeft: '50px'}}>
                <ButtonGroup>
                    <button className={(annoVisualizzazione === 'corrente') ? 'active-view' : ''} onClick={() => handleClickBtn('corrente')}>Anno Corrente</button>
                    <button className={(annoVisualizzazione === 'successivo') ? 'active-view' : ''} onClick={() => handleClickBtn('successivo')}>Anno Successivo</button>
                </ButtonGroup>
                <div className='spazio'>
                    <div className='popup-title'>
                        {annoVisualizzazione === 'corrente' ? (
                            <h2>Registro Importi <span>{moment().format('YYYY')}</span></h2>
                        ) : (
                            <h2>Registro Importi <span>{parseInt(moment().format('YYYY'))+1}</span></h2>
                        )}
                    </div>
                    {dati.importi.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Importo</th>
                                    <th>Mese</th>
                                </tr>
                            </thead>
                            <tbody>
                                {annoVisualizzazione === 'corrente' ? (
                                    <>
                                        {dati.importi.map((item) => (
                                            <tr>
                                                <td>{item.categoria}</td>
                                                <td>{Number(item.importo).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                                <td>{item.mese}</td>
                                                <td><Bin onClick={() => handleDelete(item.id, item.categoria, item.mese, 'importo')}/></td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {dati.registro_succ
                                        .filter(item => item.tipo === 'singolo')
                                        .map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.categoria}</td>
                                                <td>{Number(item.importo).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                                <td>{item.mese}</td>
                                                <td><Bin onClick={() => handleDelete(item.id, item.categoria, item.mese, 'importo', 'succ')}/></td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p style={{marginTop: '10px'}}>Nessun dato da visualizzare!</p>
                    )}
                </div>

                <div className='spazio'>

                    <div className='popup-title'>
                        {annoVisualizzazione === 'corrente' ? (
                            <h2>Registro Importi Macrocategorie <span>{moment().format('YYYY')}</span></h2>
                        ) : (
                            <h2>Registro Importi Macrocategorie <span>{parseInt(moment().format('YYYY'))+1}</span></h2>
                        )}
                    </div>
                    {dati.importiMacro.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Importo</th>
                                    <th>Mese</th>
                                </tr>
                            </thead>
                            <tbody>
                                {annoVisualizzazione === 'corrente' ? (
                                    <>
                                        {dati.importiMacro.map((item) => (
                                            <tr>
                                                <td>{item.nome}</td>
                                                <td>{Number(item.importi).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                                <td>{item.mese}</td>
                                                <td><Bin onClick={() => handleDelete(item.id, item.nome, item.mese, 'importoMacro')}/></td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {dati.registro_succ
                                        .filter(item => item.tipo === 'macro')
                                        .map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.categoria}</td>
                                                <td>{Number(item.importo).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                                <td>{item.mese}</td>
                                                <td><Bin onClick={() => handleDelete(item.id, item.categoria, item.mese, 'importoMacro', 'succ')}/></td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p style={{marginTop: '10px'}}>Nessun dato da visualizzare!</p>
                    )}
                </div>

                

                <div className='spazio'>

                    <div className='popup-title'>
                        {annoVisualizzazione === 'corrente' ? (
                            <h2>Registro Importi Intervallo <span>{moment().format('YYYY')}</span></h2>
                        ) : (
                            <h2>Registro Importi Intervallo <span>{parseInt(moment().format('YYYY'))+1}</span></h2>
                        )}
                    </div>
                    {dati.intervalli.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Categoria</th>
                                    <th>Importo</th>
                                    <th>Mesi</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {annoVisualizzazione === 'corrente' ? (
                                    <>
                                        {dati.intervalli.map((item) => (
                                            <tr>
                                                <td>{item.categoria}</td>
                                                <td>{Number(item.importo).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                                <td>{item.mese} </td>
                                                <td><Bin onClick={() => handleDelete(item.id, item.categoria, item.mese, 'intervallo')}/></td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {dati.intervalliSuccessivi.map((item) => (
                                            <tr>
                                                <td>{item.categoria}</td>
                                                <td>{Number(item.importo).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR'
                                                })}</td>
                                                <td>{item.mesi} </td>
                                                <td><Bin onClick={() => handleDelete(item.id, item.categoria, item.mese, 'intervallo', 'succ')}/></td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                                
                            </tbody>
                        </table>
                    ) : (
                        <p style={{marginTop: '10px'}}>Nessun dato da visualizzare!</p>
                    )}
                </div>


                
            </div>
        )}
    </Container>
  )
}

export default RegistroCashflow;

const Bin = styled(ImBin)`cursor: pointer; font-size: 20px; color: rgba(255,0,0,.8);`

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;

    button{
        cursor: pointer;
        border: none;
        outline: none; 
        font-size: 20px;
        padding: 5px 10px;
        border-radius: 5px;
        opacity: .3;
    }

    button:nth-child(2){
        margin-left: 20px;

    }

    .active-view{
        background-color: white;
        color: black;  
        opacity: 1; 
    }
    
`

const Container = styled.div`
box-sizing: border-box;
  color: white;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 100%;
  margin-right: 5px;
  padding-bottom: 80px;
  .popup-title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2vh;
    align-items: center;
  }

  .succ{
    text-decoration: underline;
    cursor: pointer;
  }

  table{
    margin-top: 30px;
    width: 90%;
    font-size: 18px;
  }

  table th{
    border-bottom: 1px solid white; 
    text-align: start;
    padding-bottom: 7px;
  }
    
  .spazio{
    margin-bottom: 100px;
    width: 100%;
  }
  
` 