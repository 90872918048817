import React from 'react'

//styles
import "./styles/Login.css";

//icon
import { FaRegCopyright } from "react-icons/fa";

//other
import styled from 'styled-components';

function CopyrightComp() {
  return (

    <div className='copyright'>
        <Copyright />
        <p>Nautic Sales & Marketing</p>
    </div>

  );
}

export default CopyrightComp;

const Copyright = styled(FaRegCopyright)`
  

`