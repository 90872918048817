import React from 'react'

import TopMenu from "../InnerComponents/TopMenu";
import IndicatoriTable from "./IndicatoriTable.js";

import styled from "styled-components"

function Indicatori({username, password}) {
  return (
    <div>
        <TopMenu name="Indicatori" username={username}/>

        <ContainerTable>
          <IndicatoriTable />
        </ContainerTable>
    </div>
  )
}

export default Indicatori;

const ContainerTable = styled.div`
  height: 90vh;
`