import React, {useEffect, useState} from 'react';

import axios from "axios";
import styled from "styled-components"
import { Slide } from 'react-reveal';

//component
import TopMenu from '../InnerComponents/TopMenu'

//icon
import { FaEdit } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FaCompressAlt } from "react-icons/fa";
import { FaExpandAlt } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
import { BiCalendarEvent } from "react-icons/bi";
import { BiCalendarPlus } from "react-icons/bi";
import { MdFilterAltOff } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

import BudgetPopup from './BudgetPopup';

function Budget({username, password}) {

    //caricamento dati
    const [dati, setDati] = useState(null);
    const [anni, setAnni] = useState(null);
    const [legenda, setLegenda] = useState(null);
    const [categorie, setCategorie] = useState(null);
    const [businessUnit, setBusinessUnit] = useState(null);
    const [dataBep, setDataBep] = useState(null);

    const [checkedDivisioni, setCheckedDivisioni] = useState(["Tutto"]);
    const [seeBudgetYear, setSeeBudgetYear] = useState('corrente');

    async function budgetDati() {

        const data= {
            divisioni: checkedDivisioni, 
            tipo: seeBudgetYear
        }
    
        try {
            
            const response = await axios.post('https://www.app.nauticsm.it/budget', data);
            setDati(response.data.dati);
            setAnni(response.data.anni);
            setLegenda(response.data.legenda);
            setCategorie(response.data.categorie);
            setBusinessUnit(response.data.businessunit);
            setDataBep(response.data.data_bep);
        } catch (error) {
            console.error('Errore durante la richiesta POST:', error);
        }
    }

    

    //last array
    const lastArray = [1000, 1001, 2000, 2001, 4000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 2002, 4001, 4002, 10001, 10002, 10003, 12001, 14001, 14002, 14003, 14004, 14005, 14007, 14008, 14010];
    const totaliArray = [2002, 4001, 4002, 10001, 10002, 10003, 12001, 14001, 14002, 14003, 14004, 14005, 14007, 14008, 14010];

    //legenda
    const [seeLegenda, setSeeLegenda] = useState(false);
    function handleLegenda(){
        setSeeLegenda(!seeLegenda);
    }

    const [edit, setEdit] = useState(null);
    const [seeEdit, setSeeEdit] = useState(false);
    function handleSeeEdit(id){
        setSeeEdit(!seeEdit);
        setEdit(id);
    }

    const [newValue, setNewValue] = useState(null);
    function handleNewValue(e){
        const value = e.target.value;

        setNewValue(value);
    }

    async function sendData() {

    
        try {
            
            const data = {
                id: edit,
                valore: newValue
            }
            const response = await axios.post('https://www.app.nauticsm.it/valori-budget', data);
            
            setSeeEdit(false);
            budgetDati();
        } catch (error) {
            console.error('Errore durante la richiesta POST:', error);
        }
    }
    
    //aggiungi pulsante
    const [aggiungi, setAggiungi] = useState(false);

    function handleSeeAggiungi(){
        setAggiungi(!aggiungi);
    }

    //completo
    const [completo, setCompleto] = useState(1);
    function handleSelectionRadio(){
        if(completo === 1){
            setCompleto(2);
        } else {
            setCompleto(1);
        }
    }

    //variazioni
    const [seeVariazioni, setSeeVariazioni] = useState(false);
    const [tipoVariazioni, setTipoVariazioni] = useState(false);
    function handleVariazioni(tipo){
        setSeeVariazioni(!seeVariazioni);
        setTipoVariazioni(tipo);
    }

    //see legend divisioni
  const [seeDivisioni, setSeeDivisioni] = useState(false);
  function handleLegend(){
    setSeeDivisioni(!seeDivisioni);
  }

    //selezione divisioni aziendali
  function handleChangeDivisioni(event){
    const value = event.target.value;
    
    if(value !== 'Tutto' && checkedDivisioni.includes("Tutto")){
      const initialValue = "Tutto";
      const filteredDivisioni = checkedDivisioni.filter(item => item !== initialValue);
      setCheckedDivisioni([...filteredDivisioni, value]);

    } else if(value === "Tutto"){
      setCheckedDivisioni([value]);
    } else {
      if (checkedDivisioni.includes(value)) {
        setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      } else {
        setCheckedDivisioni([...checkedDivisioni, value]);
      }
    }
  };

  function handleRemoveDivisioni(item){
    const value = item;
    const initialValue = "Tutto";
    console.log(checkedDivisioni.length);
    if(checkedDivisioni.length === 1){
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
      setCheckedDivisioni([initialValue]);
    }else {
      setCheckedDivisioni(checkedDivisioni.filter(item => item !== value));
    }

  };

  useEffect(() =>{
    budgetDati();
  },[checkedDivisioni, seeBudgetYear])


  //visualizzazione budget
  
  function handleVisualizzazioneBudget(){
    if(seeBudgetYear === 'corrente'){
        setSeeBudgetYear('prossimo');
    }else{
        setSeeBudgetYear('corrente');
    }
    
  }

  //filter
  const [seeFilter, setSeeFilter] = useState(false);

  function handleFilter(){
    setSeeFilter(!seeFilter);
    setSeeDivisioni(false);
  }
  return (
    <div>
        <TopMenu name="Budget" username={username}/>
        
        <UpperMenu seeFilter={seeFilter}> 
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='icon-container tooltip' onClick={handleFilter}>
                    <span class="tooltiptext">Filtri</span>
                    {seeFilter ? (
                        <FilterOffIcon onClick={handleFilter}/>
                    ) : (
                        <FilterIcon onClick={handleFilter}/>
                    )}
                </div>
                <div className='icon-container tooltip' style={{margin: '0px 10px'}} onClick={handleSelectionRadio}>
                    <span class="tooltiptext">Visualizzazione</span>
                    {completo === 2 ? (
                        <ExpandIcon onClick={handleSelectionRadio}/>
                    ) : (
                        <CompressIcon onClick={handleSelectionRadio}/>
                    )}
                </div>
                <div className='icon-container tooltip' onClick={handleVisualizzazioneBudget}>
                    <span class="tooltiptext">Selezione Anno</span>
                    {seeBudgetYear === 'corrente' ? (
                        <CorrenteIcon onClick={handleVisualizzazioneBudget}/>
                    ) : (
                        <ProssimoIcon onClick={handleVisualizzazioneBudget}/>
                    )}                   
                </div>
            </div>
            <div className='filter-container'>
                {businessUnit && (
                    <Divisioni seeDivisioni={seeDivisioni}>
                        <button className="divisioni-title" onClick={handleLegend}>Divisioni Aziendali <DownArrow /></button>
                        <div className='divisioni-aziendali'>
                            <label><input style={{marginTop: "15px"}} onChange={handleChangeDivisioni} checked={checkedDivisioni.includes("Tutto")}  value="Tutto" type="checkbox"/> Tutto</label><br/>
                            {businessUnit.map((item, index) => (
                                <><label ><input checked={checkedDivisioni.includes(item)} onChange={handleChangeDivisioni} style={{ marginBottom: (index === businessUnit.length - 1) ? '15px' : '0' }} value={item} type="checkbox" /> {item}</label><br/></>
                            ))}
                            <div className='spuntate'>
                            {checkedDivisioni.map((item) => (
                                <p className='check'>{item} <Delete onClick={() => handleRemoveDivisioni(item)}/></p>
                            ))}
                            </div>
                        </div>
                        
                    </Divisioni>
                )}
                {legenda && (
                    <Legenda seeLegenda={seeLegenda} >
                        
                            <p className='title-budget' onClick={handleLegenda}>Legenda</p>
                            

                            <div className='elenco-legenda'>
                                {!seeEdit ? (
                                    <ul>
                                        {legenda.map((item) => (
                                            <li><EditIcon onClick={() => handleSeeEdit(item.id)}/> {item.legenda} (Valore prec: {item.valore})</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <React.Fragment>
                                        <p>Modifica</p>
                                        <ul>
                                            {legenda.map((item) => (
                                                <>
                                                    {item.id === edit && (
                                                        <li>{item.legenda} (Valore prec: {item.valore}) <input type="number" placeholder={item.valore} onChange={handleNewValue}/> <TickIcon onClick={sendData}/> </li>
                                                    )}
                                                </>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                )}
                            </div>
                        
                    </Legenda>
                )}
            </div>
            
        </UpperMenu>

        <Center>
        {dati ? (
            <div className='scroll'>
                <table className='scroll-table'>
                    <thead>
                        <tr className='intestazione'>
                            <th className='block first-column'>Ricavi e Costi</th>
                            {seeBudgetYear === 'corrente' ? (
                                <>
                                    <th className='columnNew'>{anni[1]}</th>
                                    <th className='columnNew'>% {anni[1]}</th>
                                </>
                            ) : (
                                <>
                                    <th className='columnNew'>{anni[0]}</th>
                                    <th className='columnNew'>% {anni[0]}</th>
                                </>
                            )}
                            <th className='columnNew'>Tipo</th>
                            <th className='columnNew'>Ripartizione Valori</th>
                            <th className='columnNew' style={{padding: '0px 20px'}}>Variazioni Manuali</th>
                            {seeBudgetYear === 'corrente' ? (
                                <>
                                    <th className='columnNew'>Previsione {anni[0]}</th>
                                <th className='columnNew'>% {anni[0]}</th>
                                </>
                            ) : (
                                <>
                                    <th className='columnNew'>Previsione {anni[0]+1}</th>
                                    <th className='columnNew'>% {anni[0]+1}</th>
                                </>
                            )}
                            <th className='columnNew' style={{padding: '0px 10px 0px 0px'}}>Differenze Anno Prec.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dati.map((item) => (
                            <>
                                {completo === 1 ? (
                                    <>
                                        <tr>
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'block first-column totali' : (lastArray.includes(item.id) ? 'block first-column last' : 'block first-column')}>{item.ricavi_costi}</td>
        
        
                                        {item.id !== 14007 && item.id !== 14010  ? (<td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{Number(item.anno_precedente).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>) : (
                                            <>
                                                {item.id === 14007 ? (
                                                    <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.anno_precedente.toFixed(2)}%</td>
                                                ) : (
                                                    <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.anno_precedente.toFixed(2)}</td>
                                                )}
                                            </>
                                        )}
        
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.percentuale_precedente.toFixed(2)}%</td>
        
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.tipo_precedente}</td>
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.ripartizione_valori}</td>
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{Number(item.variazioni_manuali).toLocaleString('it-IT', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}</td>
        
                                        {item.id !== 14007 && item.id !== 14010 ? (
                                            <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{Number(item.previsione_anno).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>
                                        ) : (
                                        
                                            <>
                                                {item.id === 14007 ? (
                                                    <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.previsione_anno.toFixed(2)}%</td>
                                                ) : (
                                                    <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.previsione_anno.toFixed(2)}</td>
                                                )}
                                            </>
                                        )}


        
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.percentuale_anno.toFixed(2)}%</td>
                                        <td className={(lastArray.includes(item.id) && totaliArray.includes(item.id)) ? 'column totali' : (lastArray.includes(item.id) ? 'column last' : 'column')}>{item.differenza.toFixed(2)}%</td>
                                        
                                    </tr>

                                    {item.id === 14005 && (
                                                                                    
                                        <tr>
                                            <td className='block first-column totali'>{dati.find(i => i.id === 2002).ricavi_costi}</td>

                                            <td className='column totali'>{Number(dati.find(i => i.id === 2002).anno_precedente).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>

                                            <td className='column totali'>{dati.find(i => i.id === 2002).percentuale_precedente.toFixed(2)}%</td>

                                            <td className='column totali'>{dati.find(i => i.id === 2002).tipo_precedente}</td>

                                            <td className='column totali'>{dati.find(i => i.id === 2002).ripartizione_valori}</td>

                                            <td className='column totali'>{Number(dati.find(i => i.id === 2002).variazioni_manuali).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>

                                            <td className='column totali'>{Number(dati.find(i => i.id === 2002).previsione_anno).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>
                                            
                                            <td className='column totali'>{dati.find(i => i.id === 2002).percentuale_anno.toFixed(2)}%</td>
                                            <td className='column totali'>{dati.find(i => i.id === 2002).differenza.toFixed(2)}%</td>
                                        </tr>
                                        
                                    )}
                                    </>
                                ) : (
                                    <>
                                        {totaliArray.includes(item.id) && (
                                            <>
                                            
                                                <tr>
                                                    <td className='block first-column'>{item.ricavi_costi}</td>
                    
                    
                                                    {item.id !== 14007 && item.id !== 14010 ? (<td className='column'>{Number(item.anno_precedente).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>) : (
                                                        <>
                                                            {item.id === 14007 ? (
                                                                <td className='column'>{item.anno_precedente.toFixed(2)}%</td>
                                                            ) : (
                                                                <td className='column'>{item.anno_precedente.toFixed(2)}</td>
                                                            )}
                                                        </>
                                                    )}
                    
                                                    <td className='column'>{item.percentuale_precedente.toFixed(2)}%</td>
                    
                                                    <td className='column'>{item.tipo_precedente}</td>
                                                    <td className='column'>{item.ripartizione_valori}</td>
                                                    <td className='column'>{Number(item.variazioni_manuali).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>
                    
                                                    {item.id !== 14007 && item.id !== 14010 ? (<td className='column'>{Number(item.previsione_anno).toLocaleString('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</td>) : (
                                                    
                                                        <>
                                                            {item.id === 14007 ? (

                                                                <td className='column'>{item.previsione_anno.toFixed(2)}%</td>
                                                            ) : (
                                                                <td className='column'>{item.previsione_anno.toFixed(2)}</td>
                                                            )}
                                                        </>
                                                        
                                                    )}
                    
                                                    <td className='column'>{item.percentuale_anno.toFixed(2)}%</td>
                                                    <td className='column'>{item.differenza.toFixed(2)}%</td>
                                                    
                                                </tr>
                                                {item.id === 14005 && (
                                                
                                                    <tr>
                                                        <td className='block first-column'>{dati.find(i => i.id === 2002).ricavi_costi}</td>

                                                        <td className='column'>{Number(dati.find(i => i.id === 2002).anno_precedente).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                        })}</td>

                                                        <td className='column'>{dati.find(i => i.id === 2002).percentuale_precedente.toFixed(2)}%</td>

                                                        <td className='column'>{dati.find(i => i.id === 2002).tipo_precedente}</td>

                                                        <td className='column'>{dati.find(i => i.id === 2002).ripartizione_valori}</td>

                                                        <td className='column'>{Number(dati.find(i => i.id === 2002).variazioni_manuali).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                        })}</td>

                                                        <td className='column'>{Number(dati.find(i => i.id === 2002).previsione_anno).toLocaleString('it-IT', {
                                                            style: 'currency',
                                                            currency: 'EUR'
                                                        })}</td>
                                                        
                                                        <td className='column'>{dati.find(i => i.id === 2002).percentuale_anno.toFixed(2)}%</td>
                                                        <td className='column'>{dati.find(i => i.id === 2002).differenza.toFixed(2)}%</td>
                                                    </tr>
                                                    
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ))}

                        {dataBep && (
                            <>
                                {completo === 1 ? (
                                    <>
                                        
                                        <tr>
                                            <td className='block first-column totali'>Data Bep Aziendale</td>

                                            <td className='column totali'>{dataBep[0].split('/').slice(0, 2).join('/')}</td>

                                            <td className='column totali'>/</td>

                                            <td className='column totali'>/</td>

                                            <td className='column totali'>0</td>

                                            <td className='column totali'>{Number(0).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>

                                            <td className='column totali'>{dataBep[1].split('/').slice(0, 2).join('/')}</td>
                                            
                                            <td className='column totali'>/</td>
                                            <td className='column totali'>/</td>
                                        </tr>
                                        
                                    </>
                                ) : (
                                    <tr>
                                            <td className='block first-column '>Data Bep Aziendale</td>

                                            <td className='column '>{dataBep[0].split('/').slice(0, 2).join('/')}</td>

                                            <td className='column '>/</td>

                                            <td className='column '>/</td>

                                            <td className='column '>0</td>

                                            <td className='column '>{Number(0).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR'
                                            })}</td>

                                            <td className='column '>{dataBep[1].split('/').slice(0, 2).join('/')}</td>
                                            
                                            <td className='column '>/</td>
                                            <td className='column '>/</td>
                                        </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        ) : (<p>Caricamento...</p>)}
        </Center>

        <ContainerAggiungi>
            <div>
                <Slide right effect="slideInRight" duration={700} when={aggiungi} >
                <button onClick={() => handleVariazioni('variazioni')}>Variazioni Manuali</button>
                </Slide>
                <Slide right effect="slideInRight" duration={700} delay={200} when={aggiungi} >
                <button onClick={() => handleVariazioni('azzerra')}>Azzerra Valori Manuali</button>
                </Slide>
            </div>
            
        
            
            <div className='aggiungi' onClick={handleSeeAggiungi}>
                <PlusIcon aggiungi={aggiungi}/>
            </div>
                
            
        </ContainerAggiungi>

        {seeVariazioni && (<BudgetPopup close={handleVariazioni} categorie={categorie} ricarica={budgetDati} tipo={tipoVariazioni}/>)}
    </div>
  )
}

export default Budget;

const DownArrow = styled(IoIosArrowDown)`
  font-size: 18px;
  margin-left: 5px;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const UpperMenu = styled.div`
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    margin-top: 30px;

    .filter-container{
    
        max-height: ${props => (props.seeFilter ? '400px' : '0px')};
        padding:  ${props => (props.seeFilter ? '20px 0px' : '0px')};
        padding-left: 2%;
        padding-right: 2%;
        height: fit-content;
        border: ${props => (props.seeFilter ? '1px solid rgba(0,0,0,.5)' : 'none')};
        overflow: ${props => (props.seeFilter ? 'visible' : 'hidden')};;
        box-shadow: 0px 0px 10px rgba(0,0,0,.2);
        border-radius: 0px 10px 10px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: max-height 0.3s ease, padding 0.3s ease;
        
    }

    .icon-container{
        border: 1px solid rgba(0,0,0,.5);
        border-bottom: ${props => (props.seeFilter ? 'none' : '1px solid rgba(0,0,0,.5)')};
        width: fit-content;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        border-radius: ${props => (props.seeFilter ? '5px 5px 0px 0px' : '5px')};
        cursor: pointer;
        color: rgba(0,0,0,.5);
        transition: all .3s linear;
    }

    .icon-container:hover{
        border: 1px solid black;
        color: black;
    }
`

const Delete = styled(IoMdClose)`
  font-size: 16px;
  cursor: pointer;
  margin-top: 1px;
  margin-left: 2px;
`

const Divisioni = styled.div`
  margin-left: 2%;


  .spuntate{
    display: flex;
    align-items: center;
    margin-top: ${({seeDivisioni}) => (seeDivisioni ? '10px' : '0px')};
    margin-bottom: 10px;
    max-width: 300px;
    flex-wrap: wrap;
    height: fit-content;

    p{
      margin: 3px 0px;
    }
  }

  .spuntate .check{
    background-color: lightgrey;
    margin-right: 8px;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .divisioni-title{
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0,0,0,.5);
    padding: 5px 20px;
    color: rgba(0,0,0,.5);
    background-color: white;
    transition: all .3s linear;

  }

  .divisioni-title:hover{
    color: black;
    border: 1px solid black;
  }

  .divisioni-aziendali{
    position: absolute;
    background-color: white;
    z-index: 9998;
    max-height: ${({seeDivisioni}) => (seeDivisioni ? '400px' : '0')};
    height: fit-content;
    box-shadow: 0px 0px 3px rgba(0,0,0,.4);
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    border-radius: 10px;
    width: fit-content;
    margin-top: 3px;
    font-size: 18px;
    padding: 0px 50px 0px 10px;
  }

  label, input{
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
  }


  
`

const Visualizzazione = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .radio-btn label:nth-child(2){
        padding: 0px 10px;
        cursor: pointer;
    }

    .radio-btn label, .radio-btn input{
        cursor: pointer;
    }

    .anno-budget{
        display: flex;
        align-items: center;

        font-size: 18px;
        margin-right: 2%;

        
    }

    .choice-corrente{
        margin: 0px 20px;
    }

    button{
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0,0,0,.3);
        border-radius: 5px;
        font-size: 18px;
        padding: 5px 8px;
        outline: none;
        border: none;
    }

    .choice-corrente, .choice-prossimo{
        cursor: pointer;
    }

    .active-view{
        background-color: #213940;
        color: white;
    }

    .switch-view{
        width: 80px;
        background-color: rgba(0,0,0,.2);
        border-radius: 20px;
        height: 25px;
        margin: 0px 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: ${({completo}) => ((completo === 1) ? 'flex-start' : 'flex-end')};
    }

    .switch-btn{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #213940;
        cursor: pointer;
        margin: 0px 3px;
    }

    .radio-btn{
        display: flex;
        align-items: center;
        justufy-content: flex-start;
    }

`
const ContainerAggiungi = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: column;
  

  div{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  button{
    font-size: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
  }
    button:nth-child(1){
    background-color: #1eafd6;
    color: white;

  }
  button:nth-child(2){
    background-color: red;
    color: white;
    margin: 20px 0px;
  }

  
`
const PlusIcon = styled(FaPlus)`
  font-size: 54px;
  color: white;
  padding: 10px;
  transition: all .2s linear;
  ${({ aggiungi }) => (aggiungi && 'transform: rotateZ(45deg);')};
`

const Legenda = styled.div`
    position: relative;
    .title-budget{
        font-size: 20px;
        cursor: pointer;
        transition: all .3s linear;
        color: rgba(0,0,0,.5);
        border: 1px solid rgba(0,0,0,.5);
        border-radius: 5px;
        padding: 5px 15px;

        &:hover{
            color: rgba(0,0,0,1);
            border: 1px solid black;
        }
    }
    .elenco-legenda{
        background-color: white;
        position: absolute;
        top: 35px;
        right: 0;
        margin-top: 3px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0,0,0,.2);
        width: fit-content;
        min-width: 400px;
        height: auto;
        max-height: ${({seeLegenda}) => (seeLegenda ? '200px' : '0')};
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
        
    }

    .elenco-legenda p{
        margin-left: 10px;
        margin-top: 10px;
        font-size: 18px;
        font-weight: 700;
    }

    .elenco-legenda ul{
        list-style: none;
        padding-left: 0;

    }
    

    .elenco-legenda li{
        padding: 5px 10px;
        font-size: 18px;
        display: flex;
        align-items: center;
    }

    .elenco-legenda input{
        
        font-size: 18px;
        margin: 0px 10px;
        padding: 3px 0px 3px 7px;
        width: 100px;
    }
    .elenco-legenda li:nth-child(1){
        padding-top: 15px;
    }

    .elenco-legenda li:last-child{
        padding-bottom: 15px;
    }
`

const EditIcon = styled(FaEdit)`
    margin-left: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: all .3s linear;
    margin-right: 5px;
    &:hover{
        color: rgba(0,0,0,.7);
    }
`

const TickIcon = styled(MdDone)`
    color: green;
    font-size: 34px;
    cursor: pointer;
`

const FilterIcon = styled(FaFilter)`
  font-size: 20px;
  cursor: pointer;  
`

const CompressIcon = styled(FaCompressAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const ExpandIcon = styled(FaExpandAlt)`
  font-size: 20px;
  cursor: pointer;  
`

const CorrenteIcon = styled(BiCalendarEvent)`
    cursor: pointer;
    font-size: 20px;
`

const ProssimoIcon = styled(BiCalendarPlus)`
    cursor: pointer;
    font-size: 20px;
`

const FilterOffIcon = styled(MdFilterAltOff)`
  font-size: 24px;
  cursor: pointer;  
`